<template>
    <div style="display:inline">
        <Select v-model="selectDate" style="width:80px">
            <Option v-for="item in dateList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
        &nbsp;
        <DatePicker :type="selectDate" v-model="startTime" placeholder="开始日期" style="width: 140px" @on-change="handleChangeStartime"></DatePicker>
        -
        <DatePicker :type="selectDate" v-model="endTime" placeholder="截止日期" style="width: 140px" @on-change="handleChangeEndtime"></DatePicker>
    </div>
</template>
<script>
    export default {
        name: 'dateRangeYM',
        model: {
            prop: 'time',
            event: 'change'
        },
        props: ['time'],
        data () {
            return {
                startTime: this.time[0],
                endTime: this.time[1],
                dateList: [
                    {
                        value: 'year',
                        label: '年'
                    },
                    {
                        value: 'month',
                        label: '月'
                    },
                ],
                selectDate: 'year'
            }
        },
        methods: {
            handleChangeStartime(value){
                this.startTime = value;
                this.$emit('change', [value, this.endTime]);
            },
            handleChangeEndtime(value){
                this.endTime = value;
                this.$emit('change', [this.startTime, value]);
            }
        }
    }
</script>
