// import Lang from './codeMessage'
let util = {

}

function formatNumber(n) {
    const str = n.toString();
    return str[1] ? str : `0${str}`;
}
util.formatTime = function(date) {
    if (!date.getFullYear) {
        date = new Date(date);
    }
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    const t1 = [year, month, day].map(formatNumber).join('-');
    const t2 = [hour, minute, second].map(formatNumber).join(':');

    return `${t1} ${t2}`;
}
util.formatDate = function(date) {
    if (typeof date !== 'object') {
        date = new Date(date);
    }
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    const t1 = [year, month, day].map(formatNumber).join('-')
    return `${t1}`;
}

util.formatDateFmt = function(date, fmt) {
    if (date == 'Invalid Date') { return '' }
    let o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'S': date.getMilliseconds() // 毫秒
    }

    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
    }
    return fmt
}

// util.getByCodeMsg = function (code) {
//     for (let key in Lang) {
//         if (key == code) {
//             return Lang[key]
//         }
//     }
// }

util.array = {
    remove(array, url) {
        let index = -1;
        for (let i = 0; i < array.length; i++) {
            if (url == array[i].fastUrl) {
                index = i;
                break;
            }
        }
        if (index > -1) {
            array.splice(index, 1);
        }
    },
    removeKey(array, key) {
        let index = -1;
        for (let i = 0; i < array.length; i++) {
            if (key == array[i].key) {
                index = i;
                break;
            }
        }
        if (index > -1) {
            array.splice(index, 1);
        }
    },

    removeUser(array, executorId) {
        let index = -1;
        for (let i = 0; i < array.length; i++) {
            if (executorId == array[i].executorId) {
                index = i;
                break;
            }
        }
        if (index > -1) {
            array.splice(index, 1);
        }
    },
    oneOf(value, validList) {

        for (let i = 0; i < validList.length; i++) {
            if (value == validList[i]) {
                return true;
            }
        }
        return false;
    }
}

// 設備平臺判斷
var ua = navigator.userAgent.toLowerCase()

util.platform = {
    isMobile: /(iphone|ipod|ipad|ios|android|nokia|blackberry|webos|webos|webmate|bada|lg|ucweb)/i.test(ua),
    isIos: /(iphone|ipod|ipad|ios)/i.test(ua),
    isAndroid: /(android)/i.test(ua),
    isWeiXin: /micromessenger/i.test(ua),
    isQQBrowser: /qqbrowser/i.test(ua)
}
util.findValue = function(obj, name, value) {
    if (Array.isArray(obj)) {
        let _key = ''
        obj.forEach(el => {
            if (el[name] == value) {
                _key = el
            }
        })
        return _key
    }
}

// cookie 操作
util.cookie = {
    set: function(key, val, day) {
        var date = new Date()
        if (day) {
            date.setTime(date.getTime() + day * 24 * 3600 * 1000); // 格式化日期
        }
        document.cookie = key + "=" + val + (day ? ";path=/;expires=" + date.toGMTString() : '') //設置cookie
    },
    get: function(key) {
        var getCookie = document.cookie.replace(/[ ]/g, ""), // 獲取cookie，並且將獲得的cookie格式化，去掉空格字符
            arrCookie = getCookie.split(";"), // 將獲得的cookie以"分號"為標識 將cookie保存到arrCookie的數組中
            match // 聲明變量
        for (var i = 0; i < arrCookie.length; i++) {
            arrCookie[i] = arrCookie[i].replace('=', ' ')
            var arr = arrCookie[i].split(" ");
            if (key == arr[0]) {
                match = arr[1];
                break;
            }
        }
        return match
    },
    delete: function(key) {
        var date = new Date()
        date.setTime(date.getTime() - 10000); // 過期處理
        document.cookie = key + "=v; expires =" + date.toGMTString();
    }
}
util.toCurrency = (num) => {
    let result = '';
    //货币数字转换
    let digits = num.toString().split('');
    let len = digits.length;
    //循环每三位数字
    for (let i = 0; i < len; i += 3) {
        result = digits[len - i - 3] + digits[len - i - 2] + digits[len - i - 1] + ',' + result;
    }
    //去掉最后一个逗号
    if (result[0] == ',') {
        result = result.slice(1);
    }
    return result;
}
util.downloadBlod = function(data) {
    if (!data) {
        return;
    }
    let fileNames = data.headers["content-disposition"]; //获取到Content-Disposition;filename
    let regFileNames = fileNames ? fileNames.match(/=(.*)$/)[1] : ""; //文件名称  截取=后面的文件名称
    let blob = new Blob([data.data]); //创建二进制文件
    let fileName = decodeURI(regFileNames) ;
    if ("download" in document.createElement("a")) {
        // 不是IE浏览器
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(url); // 释放掉blob对象
    } else {
        // IE 10+
        window.navigator.msSaveBlob(blob, fileName);
    }
};


export default util