<template>
	<div class="tool-box" v-if="isLogin" :class="{'active' : show}">
		<div class="list" v-if="show">
			<!-- <Poptip trigger="hover"
              placement="left">
        <div class="tool-item customer">
          <div class="icon"></div>
        </div>
        <div class="customer-box"
             slot="content">
          <p>在线客服</p>
          <a href="">123</a>
        </div>
      </Poptip> -->

			<!-- 	<Poptip trigger="hover" placement="left">
				<div class="tool-item phone">
					<div class="icon"></div>
				</div>
				<div class="phone-box" slot="content">
					<p>123@qq.com</p>
					<p>020-22008968</p>
				</div>
			</Poptip> -->

			<!-- <Poptip trigger="hover"
              placement="left-start">
        <div class="tool-item code">
          <div class="icon"></div>
        </div>
        <div class="code-box"
             slot="content">
          <div class="code-item">
            <div class="code"></div>
            <div class="txt">xx二维码</div>
          </div>
          <div class="code-item">
            <div class="code"></div>
            <div class="txt">xx二维码</div>
          </div>
        </div>
      </Poptip> -->


			<div @click="showAdvice">
				<Poptip trigger="hover" placement="left">
					<div class="tool-item advice">
						<div class="icon"></div>
					</div>
					<div class="phone-box" slot="content">
						<!-- <p>123@qq.com</p> -->
						<p>点击提交您的宝贵建议</p>
					</div>
				</Poptip>
			</div>
		</div>
		<div class="tool-item control" @click="show = !show">{{show ? '隐藏' : '展示'}}</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: true
			};
		},
		computed: {
			showReAdviceModal: {
				get() {
					return this.$store.state.modal.showReAdviceModal;
				},
				set(newValue) {
					this.$store.state.modal.showReAdviceModal = newValue;
				}
			},
			isLogin() {
				return this.$store.state.token && this.$store.state.userId;
			}
		},
		methods: {
			showAdvice() {
				this.showReAdviceModal = true
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/style/common.scss";

	/deep/ .ivu-poptip-body {
		padding: 0;
	}

	.tool-box {
		position: fixed;
		bottom: 90px;
		right: 40px;
		z-index: 999;
		opacity: 0;
		transition: all .3s;

		.list {
			margin-bottom: 10px;
		}

		.tool-item {
			@include flex;
			width: 60px;
			height: 60px;
			margin-bottom: 10px;
			color: #1e2e54;
			font-size: 16px;
			border-radius: 4px;
			border: 1px solid #dbdcdd;
			background-color: #fff;
			cursor: pointer;

			.icon {
				width: 24px;
				height: 24px;
			}

			&.customer {
				.icon {
					@include bgSrc("customer.png");
				}
			}

			&.phone {
				.icon {
					@include bgSrc("phone.png");
				}
			}

			&.code {
				.icon {
					@include bgSrc("code.png");
				}
			}

			&.advice {
				.icon {
					@include bgSrc("advice.png");
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		&:hover,
		&.active {
			opacity: 1;
		}

		/deep/ .ivu-poptip {
			display: block;
			margin-bottom: 10px;
		}
	}

	.customer-box {
		@include flex;
		flex-direction: column;
		width: 164px;
		height: 60px;
		line-height: 20px;
		background: rgba(255, 255, 255, 1);
		box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
		border-radius: 4px;

		a {
			color: #00b6ff;
		}
	}

	.phone-box {
		width: 200px;
		height: 60px;
		padding: 9px 20px;
		line-height: 20px;
		background: rgba(255, 255, 255, 1);
		box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
		border-radius: 4px;
		@include flex-center;
	}

	.code-box {
		@include flex;
		width: 200px;
		height: 141px;
		background: rgba(255, 255, 255, 1);
		box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
		border-radius: 4px;

		.code-item {
			margin-right: 20px;
			text-align: center;

			.code {
				width: 70px;
				height: 70px;
				margin-bottom: 10px;
				background-color: #eee;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
</style>
