const macroEconomy = {
    path: '/Insight/MacroEconomy',
    name: '宏观经济',
    component: () => import('@/views/economics/index.vue'),
    meta: {
        authId: [3],
        function: '宏观经济',
        indexName:'经济视野',
    },
    children: [
        {
            path: '/Insight/MacroEconomy/iframe',
            name: '经济视野',
            component: () => import('@/views/iframe.vue'),
            meta: {
                id: 106,
                pageId: 106,
                title: '中思拓-数据洞察',
                authId: [106],
                display: true,
                function: '宏观经济',
                indexName:'经济视野',
                icon: 'iconfont icon-jingjishiye',
                iconOrImg: 'icon'
            },
            props: (route) => ({
                rPath: route.query.rPath
            })
        },
        {
            path: 'EconomicStatistics',
            name: '经济统计',
            component: () => import('@/views/economics/EconomicStatistics.vue'),
            meta: {
                pageId: 5,
                title: '中思拓-数据查询',
                authId: [5],
                function: '宏观经济',
                iconOrImg: 'icon',
                icon: "iconfont icon-jingjitongji",
            },
            children: [
            // {
            //     path: '/Insight/MacroEconomy/EconomicStatistics/DataQuery',
            //     name: '数据查询',
            //     component: () => import('@/views/economics/DataQuery.vue'),
            //     meta: {
            //         pageId: 5,
            //         title: '中思拓-数据查询',
            //         authId: [5],
            //         function: '宏观经济',
            //         icon:'',
            //         iconOrImg: 'icon'
            //     }
            // },
            {
                path: '/Insight/MacroEconomy/EconomicStatistics/MapView',
                name: '地图概览',
                component: () => import('@/views/economics/MapView.vue'),
                meta: {
                    pageId: 118,
                    title: '中思拓-地图概览',
                    authId: [118],
                    function: '宏观经济',
                    icon:'',
                    iconOrImg: 'icon'
                },
            },
            {
                path: '/Insight/MacroEconomy/EconomicStatistics/DataSearch',
                name: '数据查询',
                component: () => import('@/views/economics/DataSearch.vue'),
                meta: {
                    pageId: 119,
                    title: '中思拓-数据查询',
                    authId: [119],
                    function: '宏观经济',
                    icon:'',
                    iconOrImg: 'icon'
                },
            },
               /* {
                    path: '/Insight/MacroEconomy/EconomicStatistics/TemplateManagement',
                    name: '我的模板',
                    component: () => import('@/views/economics/TemplateManagement.vue'),
                    meta: {
                        pageId: 6,
                        title: '中思拓-模板管理',
                        authId: [6],
                        function: '宏观经济',
                        icon:'',
                        iconOrImg: 'icon'
                    }
                }*/
            ]
        }
    ]
}
export default macroEconomy;