<template>
    <div>已选条件：
        <slot></slot>
        <template v-if="tagList.length !== 0">
            <template v-for="(item,index) in tagList">
                <template v-if="item.type == 'string'">
                    <Tag type="border" :key="index"
                        @on-close="handleClose(item)" >
                        {{item.name}}: <span class="tabContent">{{item.value}}</span>
                    </Tag>
                </template>
                <template v-if="item.type == 'range' && item.value[0] && item.value[1]">
                    <Tag type="border" :key="index"
                        @on-close="handleClose(item)" >
                        {{item.name}}: <span class="tabContent">{{item.value.join("-")}}{{item.unit}}</span>
                    </Tag>
                </template>
                <template v-if="item.type == 'option'">
                    <Tag type="border" :key="index"
                        @on-close="handleClose(item)" >
                        {{item.name}}: <span class="tabContent">{{item.value}}</span>
                    </Tag>
                </template>
                <template v-if="item.type == 'list' && item.value.length">
                    <Tag type="border" :key="index"
                        v-if="item.allTxt && item.length == item.value.length"
                        @on-close="handleClose(item)" >
                        {{item.name}}: <span class="tabContent">{{item.allTxt}}</span>
                    </Tag>
                    <Tag type="border" :key="index"
                        v-else
                        @on-close="handleClose(item)" >
                        {{item.name}}: <span class="tabContent">{{item.value.join("，")}}</span>
                    </Tag>
                </template>
            </template>
        </template>
        <slot name="end"></slot>
    </div>
</template>
<script>
    export default {
        name: 'tagGroup',
        props: ['tagList'],
        // watch: {
        //     tagList(newValue, oldValue){
        //         this.tagList = newValue;
        //     }
        // },
        methods: {
            handleClose(item){
                this.$emit('close', item);
                // let selectedList = Object.assign({}, this.selectedList);
                // selectedList[type] = {};
                // this.selectedList = selectedList;
                // this.searchForm[type] = [];
            },
        }
    }
</script>