import MessageNew from './MessageNew.vue'
import noticeInfo from '@/data/noticeInfo'

/**
 * des: 封装一个可以直接类似 this.$msg.success() 调用的全局弹框插件
 * @param duration {Number} 弹框显示时间
 * @param msg {String} 弹框内容
 * @param callBack {Function} 回调函数
 */
let instances = [];//消息数组

export default function install (Vue) {
  //   注册组件
  Vue.component('message', MessageNew)

  function createMessage (options) {
    // 为了简便，这里我们不再对有关参数进行校验
    let { type, msg, duration } = options
    msg = typeof msg == 'string' ? msg : msg.toString()
    // 弹框构造器
    let MsgProfile
    // 弹框实例
    let msgInstance
    function _createMsg () {
      // 创建弹框构造器
      MsgProfile = Vue.extend({
        render (h) {
          const props = {
            show: this.show,
            verticalOffset:this.verticalOffset,
            type,
            msg
          }
          // 使用 render 函数，渲染 message 组件，并将 props 传入
          return h('message', { props })
        },
        data () {
          return {
            show: false,
            verticalOffset:20
          }
        }
      })
    }

    function _mountMsg () {
      // 创建弹框实例
      msgInstance = new MsgProfile().$mount()
      // 将弹框挂载到body
       let verticalOffset = options.offset || 100;
        instances.forEach(item => {
    verticalOffset += item.$el.offsetHeight + 16;
  });
      // 显示弹框
      msgInstance.verticalOffset = verticalOffset;
      msgInstance.show = true
      document.body.appendChild(msgInstance.$el)

      instances.push(msgInstance)

    }

    function _destroyMsg () {
      // 用于销毁弹框的定时器
      let t1 = setTimeout(function () {
        clearTimeout(t1)

		// 隐藏弹框
 msgInstance.verticalOffset = 20

        msgInstance.show = false
        instances.splice(instances.indexOf(msgInstance), 1);
        // 用于显示淡入效果的定时器
        let t2 = setTimeout(function () {
          clearTimeout(t2)
          // 移除节点
          document.body.removeChild(msgInstance.$el)
          // 销毁示例
          msgInstance.$destroy()
          msgInstance = null
          // 触发回调
          // callBack && typeof callBack === 'function' && callBack()
        }, 1000)
      }, duration)
    }

    _createMsg()
    _mountMsg()
    _destroyMsg()
  }

  Vue.prototype.$msg = {
    success (options) {
        let params = {
        type: String(),
        code: Number(),
        from: 'info',
        to: '',
        title: '',
        timeout: 3000,
        text: '',
        cb: Function()
    }
        Object.keys(options).forEach(key => {
        params[key] = options[key]
    })
    let msg = options.code ? noticeInfo[params.type][params.code] : params.text

      createMessage({
        type: 'success',
        duration:params.timeout,
        msg: msg,
        callBack: params.cb
      })
    },
    error (options) {
        let params = {
        type: String(),
        code: Number(),
        from: 'info',
        to: '',
        title: '',
        timeout: 3000,
        text: '',
        cb: Function()
    }
        Object.keys(options).forEach(key => {
        params[key] = options[key]
    })
    let msg = options.code ? noticeInfo[params.type][params.code] : params.text

      createMessage({
        type: 'error',
        duration:params.timeout,
        msg: msg,
        callBack: params.cb
      })
    },

  }
}

