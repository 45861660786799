<template>
	<div class="login" :class="type == 'register' ? 'toreg' : ''">
		<Modal v-model="showModal" :mask-closable="false" :closable="false" class-name="vertical-center-modal" footer-hide
			@on-cancel="cancelModalHandle" width="416">
			<p slot="header" style="font-size: 18px; font-weight: bold; color: #333333;" class="modalHeader">
				第一次登录，请重新设置密码</p>
			<div class="modalContent">
				<Form :model="formItem" :label-width="85">
					<FormItem label="输入密码">
						<Input v-model="formItem.psw" type="password" placeholder="输入密码"></Input>
					</FormItem>
					<FormItem label="再次确认">
						<Input v-model="formItem.psw2" type="password" placeholder="再次确认"></Input>
					</FormItem>
				</Form>
				<div style="width: 141px; margin: 0 auto;">
					<Button :loading="loading" @click.native.prevent="handleSetPsw" type="primary" style="width: 141px;"
						class="setPsw" size="large" circle>确定</Button>
				</div>
			</div>
		</Modal>
		<Modal v-model="showIfOne" :mask-closable="false" :closable="false" class-name="vertical-center-modal" width="450">
			<p slot="header" style="text-align:center;font-size: 20px; font-weight: 500; color: #334892;" class="modalHeader">
				温馨提示</p>
			<div style="text-align:left">

				<p style="font-size: 20px; font-weight: 400; color: #333333;">
					为了给您提供更加安全、优质的服务，我院制定了《隐私政策》（或用户协议），本隐私政策是我院收集、利用和保护客户个人信息的声明与承诺。请您仔细阅读本隐私政策，确保对其内容及相应法律后果已全部知晓并充分理解。
				</p>
				<Checkbox v-model="fristIsAllow" class="copyright">我已仔细阅读并接受《广州中思拓研究院有限公司隐私政策》</Checkbox>
			</div>
			<div style="text-align:center" slot="footer">
				<Button :disabled='!fristIsAllow' type="info" size="large"
					@click="showModal = true; showIfOne = false">同意</Button>
				<Button size="large" @click="showIfOne = false">取消</Button>


			</div>
			<!-- <div class="modalContent">

        <div style="width: 141px; margin: 0 auto;">
          <Button :loading="loading"
                  @click.native.prevent="handleSetPsw"
                  type="primary"
                  style="width: 141px;"
                  class="setPsw"
                  size="large"
                  circle>确定</Button>
        </div>
      </div> -->
		</Modal>
		<iframe id="iframe" src="static/iframe.html" scrolling="no"></iframe>
		<div class="loginBox" v-if="type == 'login'">
			<div class="box1">
				<div class="logo"></div>
				<Form class="login-form" autocomplete="off" :model="loginForm" :rules="loginRules" ref="loginForm">
					<FormItem prop="phone">
						<div class="login-form-group">
							<!-- <div class="text">手机号码</div> -->
							<Input @keyup.enter.native="handleLogin" maxlength="11" show-word-limit name="phone" size="large"
								v-model="loginForm.phone" placeholder="手机号" />
						</div>
					</FormItem>

					<FormItem v-if='verifyCodeLogin' prop="verifyCode">
						<div class="login-form-group">
							<!-- <div class="text">短信验证码</div> -->
							<div class="item">
								<Input name="verifyCode" maxlength="4" show-word-limit type="text" size="large"
									v-model="loginForm.verifyCode" @keyup.enter.native="handleLogin" placeholder="短信验证码" />
								<Button @click.native.prevent="handleMessage" type="primary" size="large" class="messageBtn"
									:disabled="checkoutTime" circle>{{ checkoutTime ? `${timeOut}s` : '发送' }}</Button>
							</div>
						</div>
					</FormItem>

					<FormItem v-else prop="password">
						<div class="login-form-group">
							<!-- <div class="text">密码</div> -->
							<Input maxlength="30" show-word-limit @keyup.enter.native="handleLogin" name="password" type="password"
								size="large" v-model="loginForm.password" placeholder="请输入密码" />
						</div>
					</FormItem>

					<div class="foot-btn">
						<!-- <div class="tips" @click="enterLogin">用户协议、隐私政策、版权声明</div> -->
						<Checkbox v-model="isAllow" class="copyright">我已阅读并同意 <span @click.stop="enterLogin"
								style="color: #1EAEF5;">用户协议、隐私政策、版权声明</span> </Checkbox>
						<Button :loading="loading" @click.native.prevent="handleLogin" type="primary" class="loginBtn" size="large"
							:disabled="!isAllow" icon="ios-paper-plane" circle>登录</Button>
						<div class="changelogin" @click="changeLoginType">{{ loginText }}</div>
						<div class="register" @click="type = 'register'">没有账号？申请体验</div>
					</div>
				</Form>
			</div>
		</div>
		<div class="registerBox" v-if="type == 'register'">
			<img src="../assets/image/regLogo.png" alt class="logo" />
			<div class="title">申请试用</div>
			<Icon type="md-close" class="close" @click="type = 'login'" />
			<Tabs @on-click="changeLabel" value="name1">
				<TabPane label="机构用户" name="name1">
					<div class="content">
						<Form :model="createForm" :label-width="85" class="create-form" ref="createForm" :rules="createRules">
							<div class="row">
								<div class="col">
									<FormItem label="单位名称" :label-width="85" prop="yhmc">
										<Input maxlength="40" show-word-limit v-model="createForm.yhmc" placeholder="请输入单位名称" />
									</FormItem>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<FormItem label="联系人" :label-width="85" prop="yhgly">
										<Input maxlength="10" show-word-limit v-model="createForm.yhgly" placeholder="请输入" />
									</FormItem>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<FormItem label="手机号码" :label-width="85" prop="glylxdh">
										<Input maxlength="11" show-word-limit v-model="createForm.glylxdh" placeholder="请输入" />
									</FormItem>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<FormItem label="验证码" :label-width="85" prop="validCode">
										<Input maxlength="4" show-word-limit v-model="createForm.validCode" style="width:250px"
											placeholder="请输入" />
										<Button @click.native.prevent="handleLoginMessage(createForm.glylxdh, 'createForm', 'glylxdh')"
											type="primary" class="messageBtn" :disabled="checkoutTime" circle
											style="margin-left: 20px;width:60px">{{
												checkoutTime ? `${timeOut}s` : '发送'
											}}</Button>
									</FormItem>
								</div>
							</div>
							<div style="text-align: center; margin-top: -18px;">
								<!-- <Button type="primary" size="large" style="margin-right: 20px;" @click="sendApply(0)">确定</Button> -->
								<Button type="primary" size="large" style="margin-right: 20px;" @click="submitOne">确定</Button>
								<!--<Button type="error"
                        size="large"
                                @click="type = 'login'">删除</Button>-->
							</div>
						</Form>
					</div>
				</TabPane>
				<TabPane label="个人用户" name="name2">
					<div class="content">
						<Form :model="createForm2" :label-width="85" class="create-form" ref="createForm2" :rules="createRules2">
							<div class="row">
								<div class="col">
									<FormItem label="联系人" prop="yhmc">
										<Input maxlength="10" show-word-limit v-model="createForm2.yhmc" placeholder="请输入姓名" />
									</FormItem>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<FormItem label="手机号码" prop="lxdh">
										<Input maxlength="11" show-word-limit v-model="createForm2.lxdh" placeholder="请输入" />
									</FormItem>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<FormItem label="验证码" :label-width="85" prop="validCode">
										<Input maxlength="4" show-word-limit v-model="createForm2.validCode" style="width:250px"
											placeholder="请输入" />
										<Button @click.native.prevent="handleLoginMessage(createForm2.lxdh, 'createForm2', 'lxdh')"
											type="primary" class="messageBtn" :disabled="checkoutTime" circle
											style="margin-left: 20px;width:60px">{{
												checkoutTime ? `${timeOut}s` : '发送'
											}}</Button>
									</FormItem>
								</div>
							</div>
							<div style="text-align: center;">
								<!-- <Button type="primary" size="large" @click="sendApply(1)">确定</Button> -->
								<Button type="primary" size="large" @click="submitTow">确定</Button>
							</div>
						</Form>
					</div>
				</TabPane>
			</Tabs>
		</div>
		<disclaimerModal :disclaimerModal='disclaimerModal'></disclaimerModal>
		<ChangeAccountType :changeAccountType='changeAccountType' :phone='loginForm.phone'>
		</ChangeAccountType>
		<AbnormalAccount :showAbnormalAccount='showAbnormalAccount' :phone='loginForm.phone' :password='loginForm.password'
			@loginSuccess='abnormalAccountLoginSuccess'></AbnormalAccount>
	</div>
</template>

<script>
import {
	mapActions,
	mapMutations,
	mapState
} from "vuex";
import {
	getCode,
	apply,
	industry,
	getObj,
	resetPwd,
	getPhoneCode,
	apply2,
	apply3,
	getSingleSelectCity,
	getSingleSelectProvince,
	getSingleSelectRegion,
	getIframeData,
} from "@/api/user/user";
import {
	funcAuthIdApi,
	reportAuthIdApi,
	getDownListByRole,
	get_current_customer,
	data_user_detail_permission
} from "@/api/roleAndAccount.js";
import {
	getDetail
} from "@/api/MyAccount";
import { getAuthCityNew } from '@/api/city';

import disclaimerModal from "@/components/modal/disclaimerModal";
import ChangeAccountType from "@/components/modal/ChangeAccountType"
import AbnormalAccount from "@/components/modal/AbnormalAccount"
import {
	updatepsw
} from "../api/MyAccount.js"
import {
	reg_phone
} from "@/data/reg.js"
export default {
	data() {
		return {
			showAbnormalAccount: false,
			changeAccountType: false,
			isDataPlatformAccount: false,
			verifyCodeLogin: true,
			oneUserId: '',
			//登录返回数据
			loginDate: null,
			showIfOne: false,
			loginForm: {
				phone: "",
				password: '',
				ifVerify: true,
				ifDataPlatform: 1,
				verifyCode: '',
			},
			uploadImg: require("@/assets/image/upload.png"),
			formItem: {
				psw: "",
				psw2: "",
			},
			sfssList: [{
				value: 0,
				label: "是",
			},
			{
				value: 1,
				label: "不是",
			},
			],
			provinceList: [],
			SelectCityList: [],
			SelectRegionList: [],
			sshyList: [],
			createForm: {
				yhmc: "",
				sshy: "",
				jglx: "",
				sfss: "",
				fddbr: "",
				nssbh: "",
				clrq: "",
				gw: "",
				szssq: "",
				xxdz: "",
				lxdh: "",
				zczj: "",
				zzc: "",
				jzc: "",
				yhgly: "",
				glyyx: "",
				glywx: "",
				glylxdh: "",
				validCode: "",
			},
			createForm2: {
				yhmc: "",
				xb: "",
				zy: "",
				gzdw: "",
				zwbm: "",
				zjlx: "",
				zjhm: "",
				szssq: "",
				xxdz: "",
				lxdh: "",
				wx: "",
				yx: "",

				validCode: "",
			},
			szssq: [],
			createRules2: {
				yhmc: [{
					required: true,
					message: "请输入联系人姓名",
					trigger: "blur",
				},],
				lxdh: [{
					required: true,
					message: "请输入手机号",
					trigger: "blur",
				},
				{
					message: '手机号格式错误',
					trigger: 'blur',
					validator: (rule, value) => reg_phone.test(value),
				}
				],
				validCode: [{
					required: true,
					message: "请输入验证码",
					trigger: "blur",
				},],
				xb: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				zy: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				gzdw: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				zwbm: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				zjlx: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				zjhm: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				gw: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				szssq: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				xxdz: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],

				wx: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				yx: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
			},
			createRules: {
				yhmc: [{
					required: true,
					message: "请输入单位名称",
					trigger: "blur",
				},],
				yhgly: [{
					required: true,
					message: "请输入联系人",
					trigger: "blur",
				},],
				glylxdh: [{
					required: true,
					message: "请输入手机号",
					trigger: "blur",
				},
				{
					message: '手机号格式错误',
					trigger: 'blur',
					validator: (rule, value) => reg_phone.test(value),
				}
				],
				validCode: [{
					required: true,
					message: "请输入验证码",
					trigger: "blur",
				},],
				sshy: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				jglx: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				sfss: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				fddbr: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				nssbh: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				clrq: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				gw: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				szssq: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				xxdz: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				lxdh: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				zczj: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				zzc: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				jzc: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],

				glyyx: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],
				glywx: [{
					required: false,
					message: "此项必填",
					trigger: "blur",
				},],

			},
			genderList: [],
			showModal: false,
			disclaimerModal: false,
			isAllow: true,
			fristIsAllow: false,
			loginRules: {
				phone: [{
					required: true,
					trigger: "blur",
					message: "请输入手机号码",
				}, {
					message: '手机号格式错误',
					trigger: 'blur',
					validator: (rule, value) => reg_phone.test(value),
				}],
				password: [{
					required: true,
					trigger: "blur",
					message: "请输入密码",
					// validator: (rule, value) => value.length > 5 && value.length < 16,
				},],
				verifyCode: [{ required: true, trigger: "blur", message: "请输入验证码" }],
			},
			useRules: {
				gsmc: [{
					required: true,
					trigger: "blur",
					message: "请输入",
				},],
				xm: [{
					required: true,
					trigger: "blur",
					message: "请输入",
				},],
				sjhm: [{
					required: true,
					trigger: "blur",
					message: "请输入",
				},],
				// yzm: [{ required: true, trigger: "blur", message: "请输入" }],
			},
			hyList: [],
			xbList: [{
				label: "男性",
				value: 0,
			},
			{
				label: "女性",
				value: 1,
			},
			],
			zyList: [],
			zjlxList: [
				"身份证",
				"军官证",
				"护照",
				"港澳居民来往内地通行证",
				"台湾居民来往内地通行证",
				"港澳居民居住证",
				"台湾居民居住证",
				"出入境通行证",
			],
			loading: false,
			imgData: null,
			useLoading: false,
			timeOut: 60,
			checkoutTime: false,
			timer: "",
			useFormItem: {
				gsmc: "",
				hy: "",
				xm: "",
				zw: "",
				bm: "",
				sjhm: "",
				yzm: "",
				yx: "",
				gxq: [],
			},
			type: "login",
			params: {
				bt: "",
				sxlx: "",
				zt: "",
				tjsj: [],
				hfsj: [],
			},
			list: [],
			params2: {
				bt: "",
			},
			required: {
				customerName: "单位名称",
				customerAdminName: "联系人",
				customerAdminPhone: "手机号码",
				verifyCode: "验证码",
			},
		};
	},
	components: {
		disclaimerModal,
		ChangeAccountType,
		AbnormalAccount

	},
	computed: {
		...mapState(["userId"]),
		loginText() {
			if (this.verifyCodeLogin) {
				return '手机号密码登录>'
			} else {
				return '手机号验证码登录>'
			}
		}
	},

	mounted() {
		this.init();
	},

	destroyed() {
		clearInterval(this.timer);
	},

	methods: {
		...mapMutations(["changeState"]),
		...mapActions({
			loginHandle: "loginByUsername",
		}),
		init() {
			// industry().then((res) => {
			// 	this.hyList = res;
			// });
			// getSingleSelectProvince().then((data) => {
			// 	this.provinceList = data;
			// });
		},
		changeProvince(data) {
			getSingleSelectCity({
				id: data,
			}).then((data) => {
				this.SelectCityList = data;
				this.SelectRegionList = [];
			});
		},
		blobToDataURL(blob, cb) {
			let reader = new FileReader();
			reader.onload = function (evt) {
				var base64 = evt.target.result;
				cb(base64);
			};
			reader.readAsDataURL(blob);
		},
		changeImg(event) {
			const that = this;
			let img = event.target.files[0];
			var base64 = this.blobToDataURL(img, function (base64Url) {
				that.uploadImg = base64Url;
				that.imgData = base64Url;
			});
		},
		changeCity(data) {
			getSingleSelectRegion({
				id: data,
			}).then((data) => {
				this.SelectRegionList = data;
			});
		},
		handleSetPsw() {
			const psw = this.formItem.psw.trim();
			const psw2 = this.formItem.psw2.trim();
			if (!psw)
				return this.$msg.error({
					text: "请输入新密码",
				});
			// this.$msg.error("请输入密码");
			if (psw !== psw2)
				return this.$msg.error({
					text: ["两次输入的密码", "*不一致", "，请重新输入。"],
				});
			//  this.$msg.error("两次密码输入必须一致");
			// resetPwd({
			// 	id: this.loginDate.userId,
			// 	pwd: psw,
			// }).then((res) => {
			// 	// this.$msg.success("修改成功");
			// 	this.noifOneCb(this.loginDate)
			// 	this.$msg.success({
			// 		text: "修改成功",
			// 	});
			// 	this.$router.push({
			// 		path: "/",
			// 	});
			// });
			updatepsw({
				userId: this.loginDate.userId,
				rawPassword: '',
				newPassword: psw
			}).then((res) => {
				// this.$msg.success("修改成功");
				this.noifOneCb(this.loginDate)
				this.$msg.success({
					text: "修改成功",
				});
				this.$router.push({
					path: "/",
				});
			}).catch(err => {
				this.$msg.error({
					text: err.data,
				})
			})

		},
		submitOne() {
			this.$refs['createForm'].validate((valid) => {
				if (valid) {
					this.sendApply(0)
				} else {
					return false;
				}
			});
		},
		submitTow() {
			this.$refs['createForm2'].validate((valid) => {
				if (valid) {
					this.sendApply(1)
				} else {
					return false;
				}
			});
		},
		sendApply(type) {
			let data = {
				customerClass: type,
				customerAdminPhone: type == 0 ? this.createForm.glylxdh : this.createForm2.lxdh,
				customerAdminName: type == 0 ? this.createForm.yhgly : this.createForm2.yhmc,
				customerName: type == 0 ? this.createForm.yhmc : this.createForm2.yhmc,
				verifyCode: type == 0 ? this.createForm.validCode : this.createForm2.validCode,
				source: 1,
			};


			apply3(data).then((res) => {
				this.$msg.success({
					text: "提交成功，审批通过后，短信通知",
				});
				this.type = "login";
			}).catch((err) => {
				this.$msg.errer({
					text: this.$errorCode[err.code],
				});
			})
		},
		cancelModalHandle() { },
		handleLoginMessage(data, ref, phone) {
			//this.useFormItem.sjhm == "" || this.useFormItem.sjhm.length != 11
			let checkPhone = null
			this.$refs[ref].validateField(phone, (res) => {//手机号单独校验
				if (res) {
					checkPhone = false
					return;
				} else {
					checkPhone = true
				}
			});
			if (!checkPhone) {
				return;
			}
			if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(data)) {

				return;
				// this.$msg.error("请输入合法的手机号码！");
			} else {
				this.checkoutTime = true;
				getPhoneCode({
					phone: data,
				})
					.then((res) => {
						this.$msg.error({
							type: "remind",
							code: 4029,
						});
						this.timer = setInterval(() => {
							if (this.timeOut == 0) {
								clearInterval(this.timer);
								this.checkoutTime = false;
								this.timeOut = 60;
								return;
							}
							this.timeOut--;
						}, 1000);
					})
					.catch((e) => {
						this.$msg.error({
							text: e,
						});
					});
			}
		},
		changeLabel() {
			this.imgData = null;
			this.uploadImg = require("@/assets/image/upload.png");
		},
		handleMessage() {
			let checkPhone = null
			this.$refs["loginForm"].validateField("phone", (res) => {//手机号单独校验
				if (res) {
					checkPhone = false
					return;
				} else {
					checkPhone = true
				}
			});
			if (!checkPhone) {
				return;
			}
			this.checkoutTime = true;
			let params = this.loginForm.phone;
			let f = (res) => {
				// this.$msg.success({
				// 	type: "remind",
				// 	code: 4029,
				// });
				this.timer = setInterval(() => {
					if (this.timeOut == 0) {
						clearInterval(this.timer);
						this.checkoutTime = false;
						this.timeOut = 60;
						return;
					}
					this.timeOut--;
				}, 1000);
			};
			let c = (e) => {
				// this.$msg.error({
				// 	text: e.data,
				// });
				this.timer = setInterval(() => {
					if (this.timeOut == 0) {
						clearInterval(this.timer);
						this.checkoutTime = false;
						this.timeOut = 60;
						return;
					}
					this.timeOut--;
				}, 1000);
			};
			getPhoneCode({
				phone: params,
			})
				.then((res) => f(res))
				.catch((e) => c(e));
		},
		handleUse() {
			this.$refs["useForm"].validate((valid) => {
				if (valid) {
					this.useLoading = true;
					apply({
						company: this.useFormItem.gsmc,
						department: this.useFormItem.bm,
						duty: this.useFormItem.zw,
						email: this.useFormItem.yx,
						industry: this.useFormItem.hy,
						interests: this.useFormItem.gxq,
						nickName: this.useFormItem.xm,
						phone: this.useFormItem.sjhm,
						verifyCode: this.useFormItem.yzm,
					})
						.then((res) => {
							this.useLoading = false;
							// this.$msg.success("申请成功");
							this.$msg.success({
								text: "提交成功，审批通过后，短信通知",
							});

							this.type = "login";
						})
						.catch((err) => {
							this.useLoading = false;
						});
				}
			});
		},
		enterLogin() {
			this.disclaimerModal = true;
		},
		ifOneCb(res) {
			this.changeState({
				prop: "token",
				value: `Bearer ${res.accessToken}`,
			});
			this.showIfOne = true;
			return;
		},
		noifOneCb(res) {
			this.changeState({
				prop: "token",
				value: `Bearer ${res.accessToken}`,
			});
			this.changeState({
				prop: "userId",
				value: res.userId,
			});
			this.changeState({
				prop: "roleId",
				value: res.roleId,
			});
			this.changeState({
				prop: "customerAccountEndDate",
				value: res.customerAccountEndDate,
			});
			const day =
				parseInt(
					(new Date(res.customerAccountEndDate).getTime() -
						new Date().getTime()) /
					1000 /
					(24 * 60 * 60)
				) || 0;
			if (day < 30) {
				// this.$Notice.destroy();
				// this.$Notice.warning({
				//   title: "温馨提示",
				//   desc: `尊敬的用户您好！您的账户剩余${day}天就到期了,请您及时与我们联系续期，以免影响您继续使用！`,
				//   duration: 5,
				// });
			}
			localStorage.setItem("token", `Bearer ${res.accessToken}`);
			localStorage.setItem("userId", `${res.userId}`);
			localStorage.setItem("roleId", `${res.roleId}`);

			const authPromise1 = funcAuthIdApi({
				roleId: res.roleId,
			});
			const authPromise2 = reportAuthIdApi({
				roleId: res.roleId,
			});
			const iframePromise = getIframeData();
			const authPromise3 = getDownListByRole({
				roleId: res.roleId,
			});

			Promise.all([
				authPromise1,
				authPromise2,
				iframePromise,
				authPromise3,
			]).then((authRes) => {
				this.changeState({
					prop: "resourceAuthIds",
					value: authRes[0],
				});
				this.changeState({
					prop: "reportAuthIds",
					value: authRes[1],
				});
				this.changeState({
					prop: "iframeData",
					value: authRes[2],
				});
				if (authRes[3].length) {
					this.changeState({
						prop: "downListByRple",
						value: authRes[3],
					});
				} else {
					this.changeState({
						prop: "downListByRple",
						value: [],
					});
				}

				localStorage.setItem(
					"resourceAuthIds",
					JSON.stringify(authRes[0])
				);
				localStorage.setItem(
					"reportAuthIds",
					JSON.stringify(authRes[1])
				);
				localStorage.setItem("iframeData", JSON.stringify(authRes[2]));
				localStorage.setItem(
					"downListByRple",
					JSON.stringify(authRes[3])
				);

				localStorage.setItem(
					"openOfficeTipsSingle",
					true
				);
				localStorage.setItem(
					"openOfficeTipsMore",
					true
				);

				// this.$msg.success("登录成功");
				this.getCustomer()
				this.getUserInfo()


				this.$msg.success({
					text: "登录成功",
				});
				if (this.$route.query.redirect) {
					let redirect = this.$route.query.redirect
					this.$router.push(redirect)
				} else {
					this.$router.push({
						path: "/",
					});
				}

			});
		},

		async getCustomer() {
			let data = await get_current_customer()
			localStorage.setItem("customerInfo", JSON.stringify(data));
			this.getUserFile(data.id)
		},
		async getUserInfo() {
			let data = await getDetail(this.userId)
			localStorage.setItem("userInfo", JSON.stringify(data));
		},
		async getUserFile(id) {
			let data = await data_user_detail_permission({
				id: id
			})
			localStorage.setItem("userFile", JSON.stringify(data));
		},
		//改变登录方式
		changeLoginType() {
			let phone = this.loginForm.phone ? this.loginForm.phone : ''
			this.verifyCodeLogin = !this.verifyCodeLogin
			this.loginForm.password = ''
			this.loginForm.verifyCode = ''
			this.$refs["loginForm"].resetFields()
			this.loginForm.phone = phone
			if (this.verifyCodeLogin) {
				this.loginForm.ifVerify = true
				this.loginRules.password[0].required = false
			} else {
				this.loginForm.ifVerify = false
				this.loginRules.password[0].required = true
			}
		},
		//账号异常登录成功
		abnormalAccountLoginSuccess(res) {
			this.loginDate = res
				this.getAuthCityList(res.userId)
			if (res.ifOne) {
				this.ifOneCb(res)
			} else {
				this.noifOneCb(res)
			}
		},
	async	getAuthCityList(userId) {
			let params = {
				origin: 1,//1数据平添
				// userId: userId
			}
			await	this.$store.dispatch("getcityList");//获取城市列表

			getAuthCityNew(params).then((res) => {
				this.$store.dispatch("getUserCityList", res);//获取用户城市列表

			});
		},
		//登录
		handleLogin() {
			this.$refs["loginForm"].validate((valid) => {
				if (valid) {
					this.loading = true;
					this.loginForm.password = this.loginForm.password.trim();
					this.loginHandle(this.loginForm)
						.then((res) => {
							this.loginDate = res
							this.getAuthCityList(res.userId)
							if (res.ifOne) {
								this.ifOneCb(res)
							} else {
								this.noifOneCb(res)
							}

						})
						.catch((err) => {
							//转平台用户

							if (err.code == 101021) {
								this.changeAccountType = true
								return
							}
							//异地登录
							if (err.code == 101022) {
								this.showAbnormalAccount = true
								return
							}
							//正常的报错逻辑
							if (this.$errorCode[err.code]) {

								// this.$msg.error({
								// 	text: this.$errorCode[err.code],
								// });
							} else {

								// this.$msg.success({
								// 	text: err.data,
								// });
							}
						})
						.finally(() => {
							this.loading = false;
						});
				} else {
					//手机为空
					// if (this.loginForm.phone === "") {
					// 	this.$msg.success({
					// 		type: "remind",
					// 		code: 4030,
					// 	});
					// 	return;
					// }
					//手机号码格式验证
					// if (!reg_phone.test(this.loginForm.phone)) {
					// 	this.$msg.success({
					// 		type: "remind",
					// 		code: 4027,
					// 	});
					// 	return;
					// }
					//密码为空
					// if (this.loginForm.password === "") {
					// 	this.$msg.success({
					// 		type: "remind",
					// 		code: 4031,
					// 	});
					// 	return;
					// }

				}
			});
		},
	},
};
</script>

<style lang="scss">
@import "@/style/common.scss";

.login {
	width: 100%;
	height: 100%;
	background-size: 100%;
	background-image: url("../assets/login/bg.png");
	overflow: hidden;

	&.toreg {
		overflow: auto;
		height: 100%;
		background-size: cover;
		overflow-x: hidden;
	}

	#iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
		padding: 0;
		z-index: 1;
	}

	.loginBox {
		background-image: url("../assets/login/box.png");
		width: 621px;
		height: 710px;
		position: absolute;
		left: 158px;
		background-size: 100%;
		top: calc(50% - 355px);
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 60px;
		box-sizing: border-box;

		.box1 {
			width: 100%;
			height: 100%;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.logo {
			background-image: url("../assets/login/logo.png");
			background-size: cover;
			background-repeat: no-repeat;
			width: 348px;
			height: 83px;
			position: absolute;
			top: 25px;
		}

		.login-form {
			margin-top: 60px;
		}

		.login-form-group {
			width: 350px;
			margin-top: 20px;

			.text {
				color: #fff;
				font-size: 16px;
			}

			.item {
				display: flex;

				.messageBtn {
					margin-left: 20px;
					background: rgba(0, 182, 255, 1);
				}
			}
		}

		.foot-btn {
			width: 360px;
			margin-top: 10px;

			.copyright {
				color: #fff;
				margin-bottom: 5px;
			}

			.tips {
				text-align: center;
				color: #fff;
				margin-bottom: 5px;
				cursor: pointer;
			}

			.loginBtn {
				width: 350px;
				background: rgba(0, 182, 255, 1);
				margin-top: 60px;
			}

			.register {
				color: #fff;
				text-align: center;
				margin-top: 80px;
				cursor: pointer;

				&:hover {
					color: #2d8cf0;
				}
			}

			.changelogin {
				color: #fff;
				margin-top: 15px;
				cursor: pointer;

				&:hover {
					color: #2d8cf0;
				}

			}
		}

		.box2 {
			width: calc(100% - 180px);
			height: 100%;
			color: #fff;
			position: relative;

			.toLogin {
				position: absolute;
				background: rgba(0, 182, 255, 1);
				top: 5px;
				right: 0px;
			}

			.title {
				font-size: 24px;
				font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
				font-weight: bold;
				margin-bottom: 15px;
				text-align: center;
			}

			.required {
				position: relative;

				&::after {
					content: "*";
					color: red;
					position: absolute;
					top: 0;
					left: -10px;
				}
			}

			.useBtn {
				width: 360px;
				height: 30px;
				background: rgba(0, 182, 255, 1);
				border-radius: 5px;
				margin: -25px auto 0;
				transform: translateX(75px);
			}

			.tologin {
				text-align: center;
			}

			.messageBtn {
				margin-left: 20px;
				background: rgba(0, 182, 255, 1);
			}
		}
	}

	.registerBox {
		width: 502px;
		height: 350px;
		background: rgba(255, 255, 255, 1);
		box-shadow: 0px 4px 33px 0px rgba(20, 37, 59, 0.41);
		border-radius: 2px;
		position: absolute;
		top: 320px;
		left: calc(50% - 251px);
		z-index: 100;

		.logo {
			position: absolute;
			top: -94px;
			display: block;
			left: calc(50% - 174px);
			width: 348px;
			height: 74px;
		}

		.close {
			position: absolute;
			right: 20px;
			top: 15px;
			font-size: 24px;
			z-index: 10;
			cursor: pointer;
		}

		.title {
			position: absolute;
			top: -100px;
			left: 50%;
			transform: translateX(-50%);
			font-size: 24px;
			font-weight: bold;
			color: #fff;
		}

		.ivu-tabs-ink-bar {
			background: #00b6ff !important;
			height: 3px !important;
		}

		.ivu-tabs-nav .ivu-tabs-tab-active {
			color: #00b6ff !important;
		}

		.ivu-tabs-nav .ivu-tabs-tab {
			font-size: 24px;

			font-weight: bold;
		}

		.content {
			padding-top: 30px;
			padding-left: 20px;
			padding-right: 20px;

			.create-form {
				width: 100%;

				.row {
					width: 100%;
					height: 30px;
					margin-bottom: 24px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.col {
						flex: 1;
					}
				}
			}
		}

		.uploadBox {
			width: 100px;
			height: 100px;
			overflow: hidden;
			background-repeat: no-repeat;
			background-size: 100%;
			cursor: pointer;

			.upload {
				width: 100px;
				height: 100px;
				font-size: 100%;
				opacity: 0;
			}
		}
	}

	// @media screen and (max-width: 1600px) {
	//   .loginBox {
	//     width: 506px;
	//     height: 578px;
	//     top: calc(50% - 289px);
	//     .logo {
	//       width: 348px;
	//       height: 75px;
	//       position: absolute;
	//       top: 0px;
	//     }
	//     .box2 {
	//       width: 110%;
	//       transform: scale(0.7, 0.7);
	//       position: absolute;
	//       left: -20px;
	//       top: 20px;
	//     }
	//   }
	// }
}

.mask-content {
	height: 600px;
	overflow-y: auto;
	width: 100%;
}
</style>
