const bank = {
    path: '/Insight/enterprise/bank',
    name: '银行',
    component: () => import('@/views/houseEnterprise/HouseEnterprise.vue'),
    //redirect: '/Insight/enterprise/bank/bankInformation',
    meta: {
        authId: [51],
        function: '企业监测',
        iconOrImg: 'icon',
        icon: '',
        img: ''
    },
    children: [

        {
            path: '/Insight/enterprise/bank/operate',
            name: '运营统计',
            meta: {
                pageId: 54,
                authId: [54],
                function: '企业监测',
                iconOrImg: 'icon',
                icon: '',
                img: ''
            },
            component: () => import('@/views/bank/Operate.vue'),

        },
        {
            path: '/Insight/enterprise/bank/bankInformation',
            name: '银行信息',
            meta: {
                pageId: 53,
                authId: [53],
                function: '企业监测',
                iconOrImg: 'icon',
                icon: '',
                img: ''
            },
            component: () => import('@/views/bank/BankInformation.vue'),
        },
    ]
}
export default bank;
