<template>
    <svg :class="svgClass" aria-hidden="true" v-on="$listeners">
        <use :xlink:href="iconName" />
    </svg>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'SvgIcon',
    components: {
    },
    props: {
        iconClass: {
            type: String,
            required: true
        },
        className: {
            type: String,
            default: ''
        }
    },
    computed: {
        iconName() {
            return `#icon-${this.iconClass}`
        },
        svgClass() {
            if (this.className) {
                return 'svg-icon ' + this.className
            } else {
                return 'svg-icon'
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.svg-icon {
    width: 24px;
    height: 24px;
    fill: currentColor;
    overflow: hidden;
}
</style>
