//文字前标*代表文字字体加粗，文字前带^表示为跳转连接，需要加红且跳转,带~表示换行
export default {
	"100001": "文件上传失败",
	"100002": "文件md5校验失败",
	"101001": "用户名格式错误",
	"101002": "密码错误",
	"101003": "该账号不存在，如需开通请与我们联系",
	"101004": "密码错误",
	"101005": "邮箱格式错误",
	"101006": "手机号无效",
	"101007": "用户名已经存在",
	"101008": "验证码错误",
	"101009": "验证码发送失败请重试",
	"101010": "当前用户获取验证码次数到达上限溢出",
	"101011": "此用户正在申请中,请等待审核",
	"101012": "此账号未获得访问权限",
	"101013": "申请用户不存在",
	"101014": "账号不可用，请联系贵司用户管理员或我们处理",
	"101015": "账号已过期",
	"101016": "账号审核不通过，请联系贵司用户管理员或我们处理",
	"101017": "账号审核中",
	"101018": "账号不存在",
	"101019": "未到有效期，暂不能登录",
	"101020": "手机号重复，请联系贵司用户管理员或我们处理",
	"101021": "移动端的账户，需装平台账户",
	"101022": "请输入验证码",
	"101023": "该手机号已注册，直接登录",
	"101024": "该用户已过期，如需续期请与我们联系",
	"101025": "移动端注册-该手机号已注册，请直接登录 登录-账号不存在",
	"102001": "token过期",
	"102002": "token无效",
	"103001": "表已经存在",
	"103002": "指定指标为空，无法查数建表",
	"103003": "表删除失败",
	"103004": "建表失败",
	"103005": "插入数据失败",
	"103006": "表名含有不可使用的特殊字符",
	"103007": "指标提数为空",
	"109001": "当前状态无法修改",
	"109002": "无法反复提交",
	"109003": "上传的空文件或者文件格式错误",
	"109004": "文件格式错误(必须是.xlsx格式)",
	"109005": "没有此土地匹配记录",
	"109006": "时间格式错误请用",
	"109007": "土地记录不完整",
	"109008": "文件生成失败",
	"109010": "文件读取失败",
	"109011": "省市请确认到区",
	"109012": "查询组名称重复，请输入其他名称",
	"109013": "查询组已达上限，请删除部分查询组再保存",
	"110001": "不为空",
	"110002": "当前用户不是审核者,或者以作废,审核不通过",
	"110003": "当前用户无法查看",
	"110004": "没有记录",
	"110005": "没有权限撤销",
	"110006": "参数不能都为空",
	"110007": "事务工单需要配流程",
	"110008": "当前模块没有配置审核流程无法提交",
	"110009": "当前流程存在工单未完成",
	"120001": "导入错误",
	"120002": "请求错误",
	"120003": "数据单元格的参数格式错误，请填写数字格式",
	"120004": "未配置审核流程，请联系管理员配置该子版块的审核流程",
	"120005": "无此工单",
	"130100": "没有此表",
	"130101": "土地区域维度数据异常",
	"150001": "sql 异常",
	"150002": "只允许select操作",
	"150003": "记录条数不能大于10000",
	"150005": "查询结果为空",
	"160001": "已存在此记录,确认是否需要更新文件",
	"160002": "没有文件记录",
	"160003": "找不到此记录",
	"160004": "文件名不规范",
	"170001": "文件为空",
	"170002": "数据源无法连接",
	"170003": "表不存在",
	"170004": "excel解析失败",
	"170005": "sql语法错误",
	"17006": "文件元数据缺失",
	"180001": "顶级和二级不能新增删除",
	"180002": "帆软url结构错误",
	"180003": "土地统计请加入行表头",
	"180004": "当前指标全为空，无法成为表头",
	"190001": "自定义项目必须指定定位区域",
	"190002": "非默认月份请指定时间区间",
	"190003": "没有此记录或者无法定位区域",
	"401": "未授权",
	"-1": "失败",
	"700000": "Excel没有数据",
	"700001": "Excel必填项未填",
	"700002": "Excel模板错误等",
	"2021001": "按钮没有获得授权",
	"0": "成功",
	"202104301": "创建审批待办失败",
	"202104302": "查询审批待办失败",
	"20210520": "数据重复",
	"2021061701": "经度或纬度为空",
	"2021070901": "保存失败,系统公告时间冲突",
	"2021070902": "保存失败,系统公告标题冲突",
	"2021070903": "保存失败,系统公告开始时间不能晚于结束时间",
	"2021072601": "已经存在数据平台账号",
	"202107301": "手机号码已被注册",
	"2021072801": "用户可授权账号数不足",
	"2021080601": "解析EXCEL出错，单元格不能为空",
	"2021081001": "解析excel校验失败",
	"2021083101": "此账号未赋予权限，请联系管理员",
	"2021093102": "该用户不可用，请与我们联系",
	"2021083103": "该用户不可用，请与我们联系",
	"2021083104": "该用户未到使用时间，如需提前请与我们联系",
	"2021083105": "该用户已到期，如需续期请与我们联系",
	"2021083106": "此账号没有审核通过，请向系统管理员申请",
	"2021083107": "该账号不可用，请联系我们或贵司用户管理员",
	"202104303":"权益包不存在",
	"101026":"【验证码】不可留空，请您输入验证码。",
}
