import { pagelog } from "../utils/dataReport";

export default {
    data() {
        return {
            currentPageId:''
        }
    },
    methods: {
        getPageId() {
           
            return this.$route.meta.pageId;
        },
        enterPagelog(){
            const pageId = this.getPageId();
            this.currentPageId = pageId
            sessionStorage.setItem('pageId', pageId)
            if (pageId) {
                this.$pagelog(pageId, 'enter', this);
            }
        },
        leavePageLog() {
            const lastEnterPageTime = sessionStorage.getItem('lastEnterPageTime')
            const enterPageTime = sessionStorage.getItem('enterPageTime')

            // const pageId = +sessionStorage.getItem('pageId');
            const pageId = this.currentPageId;
            if (pageId && enterPageTime != lastEnterPageTime) {
                return this.$pagelog(pageId, 'leave', this);
            }
        },
        closePageLog(){
            this.leavePageLog()
        },

        beforeunloadHandler(e) {
            const pageId = sessionStorage.getItem('pageId');
            this.$pagelog(pageId,'leave',this,true)
          }

    },
    mounted() {
        this.enterPagelog()
        window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
        // const pageId = this.getPageId();
        // sessionStorage.setItem('pageId', pageId)
        // if (pageId) {
        //     this.$pagelog(pageId, 'enter', this);
        // }
    },
    activated() {
        this.enterPagelog()
    },
    deactivated() {
        this.leavePageLog()
    },
    destroyed() {
        this.leavePageLog();
        window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    },
};
