<template>
	<Modal v-model="showModal" width="60%" @on-visible-change='changeState' footer-hide>
		<h3 slot="header" style="text-align:center">
			<span>用户协议、隐私政策、版权声明</span>
		</h3>
		<div class="mask-content">

			<div style="width: 100%;margin: 0 auto 30px">

				<h3 style="text-align:center">用 户 协 议</h3>
				<Row style="padding: 8px">
					<Col span="24">
					<div style="text-indent:24px">
						在使用本网站之前，请您仔细阅读本协议的全部内容。如您不同意接受本协议的任意内容，或者无法准确理解相关条款含义的，请不要进行后续操作。如果您对本协议的条款有疑问的，请通过我们客服渠道进行询问，我们将向您解释条款内容。任何域名中包含“www.zhongsituo.com”的网站（以下称本网站）均属广东中思拓大数据研究院有限公司（以下称“中思拓研究院”）所有，并由其负责运营。
					</div>
					<div style="text-indent:24px">

						访问或使用本网站，您承认您已阅读、理解本服务条款的所有条款和条件以及本网站所包含的各项指引、规则、政策或免责声明，并同意受其约束。本网站的各项指引、规则、政策和免责声明是本服务条款的重要组成部分，援引本服务条款也包括对该等文件的援引。同时也请您阅读我们的《隐私政策》和《版权声明》。若您不同意本用户协议，请勿使用本网站。
					</div>
					<div style="text-indent:24px">
						无论是作为依据中思拓研究院或其关联公司与单位、组织或个人的《大数据服务协议》（以下称“服务协议”）而取得授权的授权用户（以下称“授权用户”），还是作为体验访问的访客模式（以下称“访客模式”），您均可以访问本网站。
					</div>
					<div style="text-indent:24px">

						您了解并同意，我们有权随时对“www.zhongsituo.com”中思拓数据平台的《用户协议》、《隐私政策》和《版权声明》等内容进行单方面的变更，毋须事先做出通知或另行单独通知您，并在平台予以公告；若您在协议有效期内使用我们上述数据平台，表示您已充分阅读、理解并接受变更修改后《用户协议》、《隐私政策》和《版权声明》内容，也将遵循变更修改后的内容使用本服务；若您不同意变更修改后的内容，您应在变更生效前停止使用。
						访问和使用本网站，包括其中的各项特征和内容，受以下条款和条件的约束。在本条款中，“您”或“您的”应包括您自己、您的机构和您在使用本网站时所代表或代理的任何机构。除非中思拓研究院特别指明外，对于“授权用户”，本服务条款不会以任何方式更改您与中思拓研究院就产品、软件、服务或其他达成的任何其他协议的条款和条件。如果本服务条款的内容与《服务协议》相冲突，应以《服务协议》的条款为准。如果您未能遵守该等条款，您访问和使用本网站的权限将自动终止，同时，您必须立即销毁所有已下载或打印的资料，并停止使用指向本网站的任何超链接。
					</div>
					</Col>
				</Row>

				<h4 style="text-indent:24px;color:#ff3737">1.使用授权及限制：</h4>
				<Row style="padding: 8px">
					<Col span="24">
					<Col style="text-indent:24px">
					1.1.使用授权。本网站及其所含资料仅限于您以内部管理、援引及参考为目的使用（统称为“授权使用”）。“授权用户”可以访问本网站，下载、通过电邮发送或打印本网站限定数量的资料，或通过本网站的保存功能将本网站选定资料保存为限定数量的电子文档。
					“授权使用”不包括：（1）对本网站全部或部分资料进行再发行、转发、出版、转让、商业或其他开发；（2）通过对本网站资料系统提取而直接或间接创建或编纂专辑、汇编、数据库或目录。
					您不得以营利为目的，将您在使用中思拓数据平台服务时获得的服务信息或该服务自身向第三方出售或以其他形式提供。否则，中思拓研究院有权停止向您提供服务，且您应支付相当于您因前述转售行为所获利益的违约金。
					</Col>
					<Col style="text-indent:24px">
					1.2.商标。本网站所使用和显示的商标、服务标识、商品名称和标志（统称为“商标”）归中思拓研究院或另行指定的第三方所有。您不得以任何目的复制、仿造或使用这些“商标”或与这些“商标”整体或部分相似的任何标志。
					</Col>
					<Col style="text-indent:24px">
					1.3.超链接。本网站可能包含指向其他网站或资源的超链接。中思拓研究院对通过这些链接而访问到的任何第三方资料或资源不承担任何责任。您与第三方发生的任何交易均与中思拓研究院无关。请查阅任何链接网站的条款和条件以及隐私政策，它们可能与我们的相关规定不同。
					</Col>
					<Col style="text-indent:24px">
					1.4.禁止访问中思拓研究院的计算机或服务器系统。严格禁止以任何理由访问或企图访问中思拓研究院的计算机系统区域或其他系统信息。禁止以任何目的使用网络爬虫或其他自动下载程序或设备，包括但不限于从本网站获取他人邮寄或电子邮件地址而发送任何未经请求或授权的商业广告。
					</Col>
					<Col style="text-indent:24px">
					1.5.禁止上传非法信息。您不得使用本网站上传、出版或散发任何非法的，侵犯他人权利的，具有辱骂、憎恶、淫秽、亵渎、色情、恐吓或粗俗性质的，包含错误、病毒或其他危害性成分的，或在法律上可被提起诉讼的信息（包括软件或其他内容）。
					</Col>
					<Col style="text-indent:24px">
					1.6.妥善保管用户名和密码。您同意不会将您的用户名或密码或访问您账号的其他方式提供给任何未经授权的个人。即使未经您授权，您仍然要对所有使用您的用户名和密码对本网站的访问负责。如果您认为您的密码或者访问您账号的其他方式已经泄漏、丢失或被盗，或者认为某一未授权个人正在或可能试图通过您的账号使用本网站，请立即发送电子邮件致8968@zhongsituo.com或致电020-22008968通知我们。
					</Col>
					<Col style="text-indent:24px">
					1.7 您承诺如下：
					</Col>
					<Col style="text-indent:24px">
					1.7.1如果您利用我们提供的服务进行的经营活动需要获得国家有关部门的许可或批准的，应获得该有关的许可或批准。包括但不限于以下内容：
					如您网站提供非经营性互联网信息服务的，必须办理非经营性网站备案，并保证所提交的所有备案信息真实有效，在备案信息发生变化时及时在备案系统中提交更新信息；
					如您网站提供经营性互联网信息服务的，还应自行在当地通信管理部门取得经营性网站许可证；
					您理解并认可，以上列举并不能穷尽您进行经营或非经营活动需要获得国家有关部门的许可或批准的全部类型，您应获得有关的许可或批准，并应符合国家及地方不时颁布相关法律法规之要求。
					</Col>
					<Col style="text-indent:24px">
					1.7.2除我们明示许可外，不得修改、翻译、改编、出租、转许可、在信息网络上传播或转让我们提供的服务，也不得逆向工程、反编译或试图以其他方式发现我们提供的服务的源代码；若我们的服务涉及第三方软件之许可使用的，您同意遵守相关的许可协议的约束；
					</Col>
					<Col style="text-indent:24px">
					1.7.3不散布电子邮件广告、垃圾邮件（SPAM）：不利用我们提供的服务散发大量不受欢迎的或者未经请求的电子邮件、电子广告或包含反动、色情等有害信息的电子邮件；
					</Col>
					<Col style="text-indent:24px">
					1.7.4不利用我们提供的资源和服务上传、下载、储存、发布如下信息或者内容，不为他人发布该等信息提供任何便利（包括但不限于设置URL、BANNER链接等）：违反国家规定的政治宣传和/或新闻信息；涉及国家秘密和/或安全的信息；封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；博彩有奖、赌博游戏、“私服”、“外挂”等非法互联网出版活动；违反国家民族和宗教政策的信息；妨碍互联网运行安全的信息；侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容；其他违反法律法规、部门规章或国家政策的内容。
					</Col>
					<Col style="text-indent:24px">
					1.7.5不应大量占用,亦不得导致如程序或进程等大量占用我们云计算资源（如云服务器、网络带宽、存储空间、数据库系统等）所组成的平台（以下简称“云平台”）中服务器内存、CPU或者网络带宽资源，并给云平台或者我们的其他用户的网络、服务器（包括但不限于本地及外地和国际的网络、服务器等）、产品/应用等带来严重的、不合理的负荷，影响我们与国际互联网或者我们与特定网络、服务器及我们内部正常通畅的联系，或者导致云平台产品与服务或者我们的其他用户的服务器宕机、死机或者用户基于云平台的产品/应用不可访问等；
					</Col>
					<Col style="text-indent:24px">
					1.7.6不进行任何破坏或试图破坏网络安全的行为（包括但不限于钓鱼，黑客，网络诈骗，网站或空间中含有或涉嫌散播：病毒、木马、恶意代码，及通过虚拟服务器对其他网站、服务器进行涉嫌攻击行为如扫描、嗅探、ARP欺骗、DOS等）；不进行任何改变或试图改变我们提供的系统配置或破坏系统安全的行为；不利用我们提供的服务从事损害我们合法权益之行为，前述损害关联公司合法权益的行为包括但不限于违反我们公布的任何服务协议/条款、管理规范、交易规则等规范内容、破坏或试图破坏我们公平交易环境或正常交易秩序等；
					不从事其他违法、违规或违反我们服务协议的行为；
					</Col>
					<Col style="text-indent:24px">
					1.7.7如我们发现您违反上述协议的约定，我们有权根据情况采取相应的处理措施，包括但不限于立即终止服务、中止服务或删除相应信息等。如果第三方机构或个人对您提出质疑或投诉，我们将通知您，您有责任在规定时间内进行说明并出具证明材料，如您未能提供相反证据或您逾期未能反馈的，我们将采取包括但不限于立即终止服务、中止服务或删除相应信息等处理措施。因您未及时更新联系方式或联系方式不正确而致使未能联系到您的，亦视为您逾期未能反馈。
					</Col>
					<Col style="text-indent:24px">
					1.7.8您应自行判断本服务与您选择适用的操作系统、数据库等软件、硬件的适配性，并自行承担因此种选择而造成的一切后果。您应自行完成本服务所需的各项准备及相应操作，包括但不限于：仔细阅读我们在网站上所做的服务说明及相关文档、配置第三方客户端、依照相关操作指引进行操作等，并自行承担因上述操作而造成的一切后果。我们不会主动干预您进行的上述行为，也不对此承担任何责任。
					</Col>
					<Col style="text-indent:24px">
					1.7.9.如果您在使用数据平台的同时，还在使用我们提供的其他服务的，则您了解并同意：
					当数据平台与其他服务之间为相互独立的关系，则您应分别遵守数据平台条款与其他的服务条款；您如果违反我们其他服务的服务条款而导致我们中断、中止、终止其服务的，不影响数据平台的正常运行；当数据平台与我们其他服务之间存在依存关系，即大数据服务以我们提供的其他服务为基础或前提的；则您应同时遵守大数据服务条款与我们其他服务的服务条款；您如果违反我们其他服务的服务条款而导致我们中断、中止、终止其他服务而影响您大数据服务的正常使用的，我们不承担任何责任。
					</Col>
					<Col style="text-indent:24px">

					1.7.10您不应在我们服务或云平台之上安装、使用盗版软件；您对自己行为（如自行安装的软件和进行的操作）所引起的结果承担全部责任。
					</Col>
					<Col style="text-indent:24px">

					1.7.11您对自己存放在云平台上的数据以及进入和管理云平台上各类产品与服务的口令、密码的完整性和保密性负责。非因我们原因致使上述数据、口令、密码等丢失或泄漏所引起的一切损失和后果均由您自行承担。
					</Col>
					<Col style="text-indent:24px">

					1.7.12您须依照《互联网信息服务管理办法》、《互联网电子公告服务管理规定》等法律法规的规定保留自己网站的访问日志记录，包括发布的信息内容及其发布时间、互联网地址（IP）、域名等，国家有关机关依法查询时应配合提供。您自行承担未按规定保留相关记录而引起的全部法律责任。
					</Col>

					<Col style="text-indent:24px">

					1.7.13您应向我们提交执行本服务协议的联系人和管理用户网络及云平台上各类产品与服务的人员名单和联系方式并提供必要的协助。如以上人员发生变动，您应自行将变动后的信息及时通知我们并提供给我们进行在线更新。因您提供的人员的信息不真实、不准确、不完整，以及因以上人员的行为或不作为而产生的结果，均由您负责。
					</Col>
					<Col style="text-indent:24px">

					1.7.14您了解我们无法保证其所提供的服务毫无瑕疵（如我们安全产品并不能保证您的硬件或软件的绝对安全），但我们承诺不断提升服务质量及服务水平。所以您同意：即使我们提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为我们违约。您同意和我们一同合作解决上述瑕疵问题。
					</Col>

					</Col>
				</Row>

				<h4 style="text-indent:24px;color:#ff3737">2.匿名使用：</h4>
				<Row style="padding: 8px">
					<Col span="24" style="text-indent:24px">
					如果您以匿名方式使用本网站（或访问本网站的某些内容），或者换言之，您是“访客模式”，您只能以信息查询为目的使用本网站（或该等内容）。您仅有权阅读本网站上的信息和资料，除此无它。您的使用必须遵守本服务条款的所有其他条款和条件，包括但不限于其中的所有限制条款。

					</Col>
				</Row>

				<h4 style="text-indent:24px;color:#ff3737">3.赔偿：</h4>
				<Row style="padding: 8px">
					<Col span="24" style="text-indent:24px">
					您同意针对以下情况引起或导致的包括但不限于索赔、诉讼、开庭、损害赔偿、律师费、鉴定费等费用和支出（包括合理的法律和会计费用）将由您方承担：
					（1）您违反了本用户协议或《大数据服务协议》；（2）您提供了不完整的或不准确的信息或数据；（3）您上传或粘贴于本网站的任何内容。
					中思拓研究院保留自行决定承担或参与因您方原因导致的，可能得到赔偿的诉讼、调解、和解、索赔调查以及相关事项的处理中，费用由您和您的单位负责。
					未经中思拓研究院事先书面同意，您方不得擅自解决任何索赔，除非这些解决方案完全免除了中思拓研究院的所有责任，并且不包括或要求中思拓研究院作出任何支付或救济。

					</Col>
				</Row>

				<h4 style="text-indent:24px;color:#ff3737">4.免责声明：</h4>
				<Row style="padding: 8px">
					<Col span="24">
					<Col style="text-indent:24px">
					4.1.本网站所包含的由中思拓研究院或另行明示的第三方（“许可方”，中思拓研究院和“许可方”统称为“服务提供方”）所提供的信息资料，并不构成法律意见或替代恰当的专业咨询意见。
					</Col>
					<Col style="text-indent:24px">

					4.2.中思拓研究院平台所登载的信息均来自于网络公开信息，中思拓研究院仅对相关网站依法公示的信息向用户如实展示。受限于现有技术水平、各信息来源网站更新不同步等原因，对此类信息的展示，并不视为中思拓研究院对其内容的真实性、准确性、完整性、时效性作出任何形式的确认或担保。请您在依据中思拓研究院服务相关信息作出判断或决策前，自行进一步核实此类信息的完整或准确性，并自行承担使用后果。对于本网站所提供的数据、信息材料我们不作包括但不限于如下承诺：(A)
					保证符合您的需求或符合特定目的；及(B)保证本网站的任何信息材料或您使用本网站绝无错误、永不中断、不受其它故障影响或会达到您的要求。中思拓研究院承诺将不断提升技术水平，逐步完善服务信息来源质量与更新频次，为您提供更高质量的服务。
					</Col>
					<Col style="text-indent:24px">

					4.3.“服务提供方”将不承担以下责任：（A）任何由于计算机病毒、或其他恶意软件、程序、代码等以任何方式对您的计算机系统造成的任何负面影响，或造成您的系统崩溃或出现其他技术问题；或（B）在提供或访问本网站过程中使用任何第三方服务，该第三方的任何行为、疏忽或过失。
					</Col>
					<Col style="text-indent:24px">

					4.4在任何情况下，对于使用或未能使用本网站或本网站所包含的材料、软件或其他信息而导致的间接性的、特殊的、附带的，惩罚性的、后果性的损失，或者由此造成的利润损失、数据丢失或业务中断等损失，无论以何种形式提起诉讼，即无论基于合同法、侵权法（包括但不限于过失）还是其他任何法律理论，亦无论是否已被告知该等损失的可能性，“服务提供方”或其董事、职员、雇员、分包商、代理商、继承人、受让人、关联方或下属机构，均不承担责任。
					</Col>
					<Col style="text-indent:24px">

					4.5您在自担风险的情况下使用本网站，若主张由中思拓研究院对于由本协议或您对本网站的使用而产生的或与其相关的损失、伤害或损害承担责任，则中思拓研究院对任何及所有索赔的赔偿总额不应超过之前六个月期间在您或您所在机构向我们所缴纳费用中所占份额。您承认本限制条款为您访问本网站和本网站所含的信息或资料的前提条件。
					</Col>
					<Col style="text-indent:24px">

					4.6依照监管要求，对于中思拓研究院在向您提供相应数据及服务信息等，您应当仅在中华人民共和国大陆范围内储存及/或使用，同时您不得利用中思拓研究院提供的数据及服务信息从事任何违反法律、法规或其他监管政策的行为。否则，由此产生的一切不利法律后果由您自行承担，且您应全额赔偿中思拓研究院因此遭受的商誉、企业形象等损失。
					</Col>

					</Col>
				</Row>
				<h4 style="text-indent:24px;color:#ff3737">5．其他：</h4>
				<Row style="padding: 8px">
					<Col span="24" style="text-indent:24px">
					本服务条款可由中思拓研究院随时全部或部分转让。未经中思拓研究院事先明确书面同意，您不得以任何方式转让本服务条款。
					本服务条款由中华人民共和国法律管辖，并依其解释。您或任何用户与中思拓研究院，发生与本协议有关的或因本协议引起的任何争议，应协商解决，协商不成的可以向广州仲裁委员会申请仲裁解决，仲裁的效力是一裁终局的。

					</Col>

				</Row>
			</div>
			<div style="width: 100%;margin: 0 auto 30px">
				<h3 style="text-align:center">隐 私 政 策：</h3>
				<Row style="padding: 8px">
					<Col span="24" style="text-indent:24px">
					在使用我们的产品或服务前，请务必仔细阅读并充分理解本隐私政策。一旦您开始使用我们的产品或服务，或者向我们提交个人信息，则表示您知晓本隐私政策的全部条款并且完全同意我们依据本隐私政策对这些信息的处理。

					<Col span="24" style="text-indent:24px">
					我们深知个人信息对您的重要性。我们会严格按照相关法律法规及本隐私政策的规定收集、使用、储存和分享（以下通称“处理”）您的个人信息。
					</Col>
					<Col span="24" style="text-indent:24px">

					本隐私政策适用于我们向您提供的各项产品及服务。本隐私政策旨在说明，在您使用我们的产品或服务时，我们如何处理您的个人信息，以及您如何可以访问、更新或删除这些信息等。
					</Col>
					<Col span="24" style="text-indent:24px">

					我们提供产品或服务的网络平台（包括但不限于官网、移动APP）可能包含跳转至其他网站的链接或指引。请注意，我们不控制这些网站，本隐私政策在任何情况下均不适用于这些网站。我们建议您在访问这些网站时仔细阅读他们的隐私政策。
					</Col>
					<Col span="24" style="text-indent:24px">

					如对本隐私政策内容有任何疑问、意见或建议，您可按照本隐私政策第9条显示的联系方式与我们联系。
					</Col>

					</Col>
				</Row>

				<h4 style="text-indent:24px;color:#ff3737">2.定义：</h4>
				<Row style="padding: 8px">
					<Col span="24">
					<Col style="text-indent:24px">

					您：指使用我们产品或服务的个人，包括通过机构客户名下账号使用我们产品或服务的个人。

					我们：指产品或服务的提供者广东中思拓大数据研究院有限公司（或称“中思拓研究院”）。

					产品或服务：指我们通过包括但不限于电脑、移动智能终端等渠道提供的宏观经济、土地市场、金融市场、城市系统、企业监测等专业领域的数据库信息浏览、查询、下载等服务。

					个人信息：是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括姓名、身份证件号码、通信通讯联系方式、住址、账号密码、财产状况、行踪轨迹等。

					</Col>
					</Col>
				</Row>
				<h4 style="text-indent:24px;color:#ff3737">3.如何收集和使用您的信息：</h4>
				<Row style="padding: 8px">
					<Col span="24">
					<Col style="text-indent:24px">

					在您使用我们产品或服务的过程中，我们仅会在非常必要的情况下和非常有限的限度内收集您的个人信息，并且这些信息仅用于向您提供产品或服务、优化我们的产品或服务，以及保障您的账号安全。具体而言，我们将按照如下方式收集您主动提供或因为使用产品和服务而产生的信息：
					</Col>
					<Col style="text-indent:24px">

					3.1.
					我们仅提供以机构客户账号使用我们的产品或服务，在您正式使用我们的产品或服务之前，需要向我们提供您的电子邮箱地址、手机号码、公司名称等信息（以下统称“基础信息”），用于确定您的使用资格以及必要时用于我们的客户服务人员与您联络。
					</Col>
					<Col style="text-indent:24px">

					3.2.
					在您使用我们的产品或服务过程中，我们可能还会收集您所属单位名称、部门、职务、单位规模及所处行业等信息。这些信息有助于我们更好地进行营销研究分析，以便向您提供更好的服务。您可以决定是否提供这些信息，不提供这些信息不会影响使用我们的产品和服务。
					</Col>
					<Col style="text-indent:24px">

					3.3. 在机构客户购买我们的产品或服务的情形下，为能有效地沟通与交流，我们会要求机构客户指定联系人。如您被机构客户指定为联系人的话，该客户会将您的手机号码、电子邮件地址等信息提供给我们。
					</Col>
					<Col style="text-indent:24px">

					3.4.
					我们可能会使用您的联系信息向您推送问卷调查、活动邀请、商业广告等信息。如果您不想接收这些信息，可在收到上述邮件后，随时按邮件说明退订，也可以通过www.zhongsituo.com与我们联系进行退订。
					</Col>
					<Col style="text-indent:24px">

					3.5.
					当您与我们联系或我们主动通过问卷调查、电话征求您的使用建议或意见时，我们可能会保存您的通信/通话记录和内容等信息，该等信息用于帮助我们改进服务/回应用户的问题。除非相关法律另有规定外，这些信息不应被视为具有保密性。
					</Col>
					<Col style="text-indent:24px">

					3.6.
					当您使用我们的产品或服务时，您的某些信息，包括但不限于您使用设备的型号、唯一标识号、操作系统、浏览器类型、显示器分辨率、互联网协议地址、域名、网页、访问网站时间和时长、使用服务时所访问的页面，可能会通过互联网协议地址、cookies、网址标签以及导航数据采集等各种技术和方法被采集（即在您非主动提供信息的情况下进行收集）并记入服务器日志文件。我们可能会使用这些信息作数据统计分析，以便改进我们的服务和营销。在您通过机构客户账号使用我们的产品或服务的情形下，我们还会应根据该机构客户的要求提供在其账号下各用户使用我们产品或服务的统计信息。这些信息通常不属于可以识别用户身份的个人信息，但如果将这些非个人信息与其他信息结合可以用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这些信息可以被视为个人信息的话，我们会在提供或使用时将其做匿名化、去标识化处理，以保护您的个人信息不被滥用。
					</Col>
					<Col style="text-indent:24px">

					3.7.
					我们的网站采用互联网协议（IP）地址技术。IP地址是互联网服务提供商分配给您电脑的一个编号，以便您能访问互联网。多数情况下IP地址是动态的（每次接入互联网时都发生变化），而不是静态的（与用户电脑一一对应），因此IP地址通常不被视为个人身份验证信息。我们使用您的IP地址用于诊断服务器故障、报告信息、确定您的电脑和本站连接能使用的最快路径以及对网站进行管理和改进。
					</Col>
					<Col style="text-indent:24px">

					3.8. “cookie”是网站向您的浏览器发送的少量信息，以帮助网站记住有关您及您偏好的信息。
					</Col>
					<Col style="text-indent:24px">

					3.9. “会话cookies”是一些暂时的信息，当您退出浏览器窗口或关闭计算机时将自动删除。 会话cookies用于改进网站的导航并收集统计信息。
					</Col>
					<Col style="text-indent:24px">

					3.10.
					“持久cookies”是一些更持久的信息，如果不删除则会始终存放在您的电脑硬盘中。持久cookies在您的电脑上储存信息有若干目的，如：找回您曾经提交的某些信息（如密码）、帮助确定网站访问者认为最有价值的区域、基于您的偏好对网站进行定制等。我们的网站使用cookies的主要目的是储存您的默认偏好。
					</Col>
					<Col style="text-indent:24px">

					3.11. 您可以将浏览器设置为收到cookie时立即通知您，以便您决定是否接受它。
					</Col>
					<Col style="text-indent:24px">

					3.12. 您也可以将浏览器设置为完全禁止储存cookies，但必须提醒您的是这将可能会防碍您对我们网站或服务的使用。
					</Col>
					<Col style="text-indent:24px">

					3.13.
					目前我们不会主动收集本隐私政策明示以外的信息包括从第三方获取您的个人信息。如未来为业务发展需要收集该类个人信息，我们会在获取前征得您的明示同意，从第三方获取您的个人信息，我们会要求第三方保障其提供的信息的合法性。
					<Col style="text-indent:24px">

					3.14. 我们仅依照法律要求及本隐私政策列明的目的使用您的信息。如需要超出前述目的使用您的个人信息，我们会再次征求您的同意。
					</Col>

					</Col>
					</Col>
				</Row>

				<h4 style="text-indent:24px;color:#ff3737">4.我们如何保护和保存您的信息：</h4>
				<Row style="padding: 8px">
					<Col span="24">
					<Col style="text-indent:24px">

					4.1. 保护个人信息的措施
					</Col>
					<Col style="text-indent:24px">

					4.1.1. 我们非常重视个人信息安全，并采取符合业界标准的安全技术措施保护您提供的个人信息安全。 </Col>
					<Col style="text-indent:24px">

					4.1.2.
					为保障您的信息安全，我们努力采取各种合理的安全措施来保护您的信息，以防信息的泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们通过建立专门的管理制度、流程、组织等管理措施以保障信息的安全。我们对可能接触到您的信息的员工采取了严格管理，包括但不限于与他们签署保密协议，根据岗位的不同采取不同的权限控制，监控他们的操作情况等措施。我们严格设定访问客户信息的权限分级，同时要求可能接触到您个人信息的所有人员履行相应的保密义务。我们还不时对工作人员培训相关法律法规及隐私安全准则，强化宣导安全意识。
					</Col>
					<Col style="text-indent:24px">

					4.1.3. 若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。</Col>
					<Col style="text-indent:24px">

					4.1.4.
					保障您的个人信息安全，需要您和我们的共同配合，请您不向他人泄露登录账号和密码，如您发现自己的个人信息尤其是您的账号或密码发生泄露时，请您立即联络我们，以便我们根据您的申请采取相应合理措施。
					</Col>
					<Col style="text-indent:24px">

					4.1.5.我们将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但也请您理解，由于技术的限制以及可能存在的各种恶意行为，在互联网上不能保证百分之百的安全。
					</Col>
					<Col style="text-indent:24px">
					请您妥善保护自己的个人信息，仅在必要的情形下向他人提供，并且对此行为产生的后果承担责任。</Col>

					<Col style="text-indent:24px">
					4.2. 保存您的信息
					</Col>
					<Col style="text-indent:24px">
					4.2.1. 存储地域范围：我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。 </Col>
					<Col style="text-indent:24px">

					4.2.2. 存储期限：一般而言，我们仅为实现本隐私政策所述目的所必需的时间保留您的个人信息，除非法律要求或允许可以在更长时间内保留这些信息。
					</Col>
					</Col>
					</Col>
				</Row>

				<h4 style="text-indent:24px;color:#ff3737">5.我们如何对外提供信息：</h4>
				<Row style="padding: 8px">
					<Col span="24">
					<Col style="text-indent:24px">
					5.1. 我们可能会将您的信息与我们的关联方或合作方共享。中思拓研究院为数据统计分析的需要，在不识别您身份的前提下，可能会整合您使用我们产品或服务的相关信息。
					</Col>
					<Col style="text-indent:24px">
					5.2. 除了我们可能在适用法律、法院命令或政府法规的要求下披露您的个人信息以外，未经您的同意，我们不会对外公开披露所获得的您的个人信息。</Col>
					<Col style="text-indent:24px">

					5.2. 除了我们可能在适用法律、法院命令或政府法规的要求下披露您的个人信息以外，未经您的同意，我们不会对外公开披露所获得的您的个人信息。</Col>

					</Col>
					</Col>
				</Row>
				<h4 style="text-indent:24px;color:#ff3737">6.如何管理您的个人信息：</h4>
				<Row style="padding: 8px">
					<Col span="24">
					<Col style="text-indent:24px">
					6.1. 您可以通过登录您在我们网站开立的账号来查看您提交给我们的个人信息、订单和交易信息、浏览搜索下载内容信息等。
					</Col>
					<Col style="text-indent:24px">
					6.2. 为保证您正确身份验证，请及时更新相关信息，以确保它们是准确、最新且完整的，在此过程中如有任何问题，请随时与我们联系。</Col>
					<Col style="text-indent:24px">

					6.2. 为保证您正确身份验证，请及时更新相关信息，以确保它们是准确、最新且完整的，在此过程中如有任何问题，请随时与我们联系。</Col>

					</Col>
					</Col>
				</Row>
				<h4 style="text-indent:24px;color:#ff3737">7.未成年人隐私保护：</h4>
				<Row style="padding: 8px">
					<Col span="24">>
					<Col style="text-indent:24px">7.1.
					如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私权政策，并在获得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。</Col>
					<Col style="text-indent:24px">
					7.2. 对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享或披露信息。</Col>

					</Col>
					</Col>
				</Row>
				<h4 style="text-indent:24px;color:#ff3737">8.隐私政策的修订和通知：</h4>

				<Row style="padding: 8px">
					<Col span="24">
					<Col style="text-indent:24px">
					由于服务内容、联络方式、法律和监管政策要求等变化，我们可能会适时对本隐私政策进行修订。当隐私政策发生变更时，我们会在我们的官方网站、移动端发布或向您提供的电子邮箱地址发送更新后的版本。为了您能及时接收到通知，建议您在电子邮箱地址变动时及时更新账号信息或通知我们。

					</Col>
					</Col>
					</Col>
				</Row>
				<h4 style="text-indent:24px;color:#ff3737">9.联系我们：</h4>
				<Row style="padding: 8px">
					<Col span="20">
					<Col style="text-align:center">
					在本隐私政策提及的所有可能需要与我们取得联系的情况下，您可以通过以下方式联系我们：

					<Col style="text-align:center">
					电子邮件：8968@zhongsituo.com
					电话：020-22008968
					</Col>
					</Col>

					</Col>
					</Col>
					</Col>
				</Row>
				<h4 style="text-indent:24px;color:#ff3737">10.争议解决：</h4>
				<Row style="padding: 8px">
					<Col span="24">
					<Col style="text-indent:24px">
					本隐私政策由中华人民共和国法律管辖，并依其解释。您或任何用户与中思拓研究院，发生与本隐私政策有关的或因本隐私政策引起的任何争议，应协商解决，协商不成的可以向广州仲裁委员会申请仲裁解决，仲裁的效力是一裁终局的。
					</Col>

					</Col>
					</Col>
					</Col>
				</Row>
			</div>
			<div style="width: 100%;margin: 0 auto 30px">
				<h3 style="text-align:center">版 权 声 明：</h3>
				<Row style="padding: 8px">
					<Col span="24" style="text-align:center;">
					Copyright © 中思拓研究院
					</Col>
				</Row>

				<Row style="padding: 8px">
					<Col span="24" style="text-indent:24px">

					访问任何域名中含“www.zhongsituo.com”的网站（以下称“本网站”），表示您已无条件同意本版权声明的所有条款。而由于这些条款可由广东中思拓大数据研究院有限公司（以下称“中思拓研究院”）随时更新或补充，且毋须另行通知，请定期查阅本页以了解相关的更新或补充。

					</Col>
					</Col>
				</Row>
				<h4 style="text-indent:24px;color:#ff3737">版权所有</h4>

				<Row style="padding: 8px">
					<Col span="24">
					<Col style="text-indent:24px">

					“本网站”所有内容的版权归“中思拓研究院”或另外指定的第三方（以下称“许可方”）所有，受包括但不限于中华人民共和国著作权法等相关法律法规以及国际条约的保护。除非另有声明，平台内的所有产品、软件、程序、技术、数据及其他信息（包括但不限于文字、图像、照片、图片、视频、音频、图表、版面设计、电子文档）的所有权利（包括但不限于版权、商标权、专利权、商业秘密等知识产权及其他所有相关权利）均归其或其关联公司所有。未经书面许可，任何人不得擅自使用（包括但不限于通过任何机器人、爬虫等程序或设备复制、传播、展示、监视、镜像、上载、下载平台内的任何内容）。商标、品牌等文字、图形及其组合，以及平台的其他标识、产品和服务名称均为及其关联公司在中国和其它国家的商标，未经书面授权，任何人不得以任何方式展示、使用或作其他处理，也不得向他人表明您有权展示、使用或作其他处理。

					</Col>

					</Col>
				</Row>
				<h4 style="text-indent:24px;color:#ff3737">版权许可</h4>

				<Row style="padding: 8px">
					<Col span="24">
					<Col style="text-indent:24px">

					“本网站”以及其中任何的内容，包括但不限于设计，标志，配色方案，图形样式，文本，图片和视频（统称“版权作品”），仅供您为个人目的使用。
					除了在保留全部版权和其他所有权声明的前提下，您可以为个人使用所需在单台计算机上获取“版权作品”的任一部分或章节的一份副本以外，所有“版权作品”均不得以任何方式复制、翻印、再版、上传、转贴、传播或散发。
					但是，自“本网站”系统提取“版权作品”的全部或相当部分，将被视为超出个人使用而予以禁止。对“版权作品”进行修改或将其用于任何其他用途，均构成对版权所有人权利的侵犯，而予以禁止。就本条款而言，禁止在任何其他网站或计算机联网环境中使用任何“版权作品”。

					</Col>
					</Col>
					</Col>

				</Row>
				<h4 style="text-indent:24px;color:#ff3737">版权保护</h4>
				<Row style="padding: 8px">
					<Col span="24">
					<Col style="text-indent:24px">
					中思拓研究院尊重并严格遵守中国《著作权法》、《商标法》等相关法律法规及国际条约。如您侵犯中思拓研究院或相关“许可方”涉及任何“版权作品”的权利，您将被要求停止此类违法活动并对中思拓研究院或相关“许可方”造成的或因侵权行为导致的任何和全部损失（包括律师费）承担全部责任。
					如果您是合法的版权所有人，并认为“本网站”所提供的内容或服务以任何方式对您的权利造成了侵害，请通过以下联系方式通知我们：
					<div>

						联系电话：020-22008968
					</div>
					<div>
						客服邮箱：8968@zhongsituo.com

					</div>
					<div>
						我们将根据相关法律法规认真处理您的投诉，并适时给予反馈。

					</div>

					</Col>
					</Col>
					</Col>

				</Row>

			</div>

		</div>
	</Modal>
</template>

<script>
	export default {
		data() {
			return{
			}
		},
		computed:{
			showModal(){
				return this.disclaimerModal
			}
		},
		props: {
			disclaimerModal: {
				type: Boolean,
				default: true
			},
		},
		methods:{
			//
			changeState(e){
				this.$parent.disclaimerModal = e
			}
		}
	}
</script>

<style>
</style>
