<template>
    <Modal class="time-modal" footer-hide :closable="false" :width="500" v-model="showTimeModal" class-name="vertical-center-modal">
        <div class="txt">
            尊敬的用户您好！您的账户剩余<span class="time">{{time}}</span>天就到期了，请您及时与我们联系续期，以免影响您继续使用！
        </div>
        <div class="btn" @click="showTimeModal = false;">知道了</div>
    </Modal>
</template>

<script>
export default {
    props : {
        time : Number
    },
    computed: {
        showTimeModal: {
            get() {
                return this.$store.state.modal.showTimeModal;
            },
            set(newValue) {
                this.$store.state.modal.showTimeModal = newValue;
            }
        }
    },
    methods : {

    }
}
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";
/deep/ .vertical-center-modal {
    @include flex;

    .ivu-modal{
        top: 0;
    }
    .ivu-modal-body {
        padding: 0;
        overflow: hidden;
    }
}
.time-modal {
    .txt {
        padding: 50px 67px 0;
        font-size: 18px;
    }
    .time {
        color: #FF3333;
        font-size: 24px;
    }
    .btn {
        @include flex;
        margin: 64px auto 50px;
        width:144px;
        height:40px;
        background:rgba(0,182,255,1);
        border-radius:5px;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
    }
}

</style>