<template>
    <div style="display:inline">
        <DatePicker
            :type="dataRType"
            v-model="startTime"
            placeholder="开始日期"
            style="width: 140px"
            :options="startDateOptions"
            @on-change="handleChangeStartime"
        ></DatePicker>
        <Select
            v-model="quarterStart"
            v-show="dateType == 'jd'"
            @on-change="checkTime"
            placeholder="季度"
            style="width:70px;"
        >
            <Option v-for="(item,index) in quarterList" :value="item" :key="index">{{ item }}</Option>
        </Select>
        <template v-if="timeNum!=1">
            -
            <DatePicker
                :type="dataRType"
                v-model="endTime"
                placeholder="截止日期"
                style="width: 140px"
                :options="endDateOptions"
                @on-change="handleChangeEndtime"
            ></DatePicker>
            <Select
                v-model="quarterEnd"
                v-show="dateType == 'jd'"
                @on-change="checkTime"
                placeholder="季度"
                style="width:70px;"
            >
                <Option v-for="(item,index) in quarterList" :value="item" :key="index">{{ item }}</Option>
            </Select>
        </template>
    </div>
</template>
<script>
export default {
    name: 'dateRange',
    model: {
        prop: 'time',
        event: 'change'
    },
    props: {
        time: Array,
        timeNum: Number,
        dateType: {
            type: String,
            default: 'date'
        }
    },
    data() {
        return {
            startTime: '',
            endTime: '',
            quarterStart: '',
            quarterEnd: '',
            quarterList: ["Q1", "Q2", "Q3", "Q4"],
            dataRType: 'date',
            startDateOptions:{},
            endDateOptions:{}
        }
    },
    watch: {
        dateType(newValue) {
            if (newValue == 'jd') {
                this.dataRType = 'year'
            } else {
                this.dataRType = newValue
            }
        },
        time(newValue) {
            this.startTime = JSON.parse(JSON.stringify(this.time[0].split('Q')[0]));
            this.endTime = JSON.parse(JSON.stringify(this.time[1].split('Q')[0]));
        },
    },
    mounted() {
        this.startTime = JSON.parse(JSON.stringify(this.time[0].split('Q')[0]));
        this.endTime = JSON.parse(JSON.stringify(this.time[1].split('Q')[0]));
        if (this.dateType == 'jd') {
            this.dataRType = 'year'
        } else {
            this.dataRType = this.dateType
        }
    },
    methods: {
        handleChangeStartime(value) {
            this.startTime = value;
            if (this.endTime.getFullYear) {
                if (this.dataRType == "month") {
                    this.endTime = `${this.endTime.getFullYear()}${
                        this.endTime.getMonth() + 1 < 10
                            ? `-0${this.endTime.getMonth() + 1}`
                            : `-${this.endTime.getMonth() + 1}`
                        }`;
                } else if (this.dataRType == "year") {
                    this.endTime = `${this.endTime.getFullYear()}`;
                } else {
                    this.endTime = `${this.endTime.getFullYear()}${
                        this.endTime.getMonth() + 1 < 10
                            ? `-0${this.endTime.getMonth() + 1}`
                            : `-${this.endTime.getMonth() + 1}`
                        }${
                        this.endTime.getDate() < 10
                            ? `-0${this.endTime.getDate()}`
                            : `-${this.endTime.getDate()}`
                        }`;
                }
            }
            this.setDateDisabled()
            this.$emit('change', [`${this.startTime}${this.quarterStart ? this.quarterStart : ''}`, `${this.endTime}${this.quarterEnd ? this.quarterEnd : ''}`]);
        },
        handleChangeEndtime(value) {
            this.endTime = value;
            if (this.startTime.getFullYear) {
                if (this.dataRType == "month") {
                    this.startTime = `${this.startTime.getFullYear()}${
                        this.startTime.getMonth() + 1 < 10
                            ? `-0${this.startTime.getMonth() + 1}`
                            : `-${this.startTime.getMonth() + 1}`
                        }`;
                } else if (this.dataRType == "year") {
                    this.startTime = `${this.startTime.getFullYear()}`;
                } else {
                    this.startTime = `${this.startTime.getFullYear()}${
                        this.startTime.getMonth() + 1 < 10
                            ? `-0${this.startTime.getMonth() + 1}`
                            : `-${this.startTime.getMonth() + 1}`
                        }${
                        this.startTime.getDate() < 10
                            ? `-0${this.startTime.getDate()}`
                            : `-${this.startTime.getDate()}`
                        }`;
                }
            }
            this.setStartDateDisabled()
            this.$emit('change', [`${this.startTime}${this.quarterStart ? this.quarterStart : ''}`, `${this.endTime}${this.quarterEnd ? this.quarterEnd : ''}`]);
        },
        checkTime(value) {
            this.$emit('change', [`${this.startTime}${this.quarterStart ? this.quarterStart : ''}`, `${this.endTime}${this.quarterEnd ? this.quarterEnd : ''}`]);
        },
        clearAll() {
            this.startTime = ''
            this.endTime = ''
            this.quarterStart = ''
            this.quarterEnd = ''
        },
        setDateDisabled(){
            this.endDateOptions = {
                disabledDate: date => {
                    let startDate =  this.startTime ? new Date(this.startTime).valueOf() : ''
                    return date && date.valueOf() < startDate
                }
            }
        },
        setStartDateDisabled(){
            this.startDateOptions = {
                disabledDate: date => {
                    let endDate =  this.endTime ? new Date(this.endTime).valueOf() : {}
                    return date && date.valueOf() > endDate
                }
            }
        }
    }
}
</script>
