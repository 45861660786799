import { get, post ,getBaseURL} from '@/utils/http'
import axios from 'axios'; // 引入axios


// if (process.env.NODE_ENV == 'development') {
//     baseURL = 'https://api.zstnb.cn:18081';//测试库
// } else if (process.env.NODE_ENV == 'test') {
//     baseURL = 'https://api.zstnb.cn:18081';
// } else if (process.env.NODE_ENV == 'production') {
//     baseURL = 'https://api.zhongsituo.com';
// }

let baseURL = getBaseURL();
import store from '@/store/index.js';
//暴露baseURL出去


export function getSingleSelectProvince(params) {
    return get({ url: '/service-insight/index_data/singleSelectProvince', params })
}
export function getSingleSelectCity(params) {
    return get({ url: '/service-insight/index_data/singleSelectCity', params })
}
export function getSingleSelectRegion(params) {
    return get({ url: '/service-insight/index_data/singleSelectRegion', params })
}

//土地
export function getLandSingleSelectProvince(params) {
    return get({ url: '/service-insight/land_dim/singleSelectProvince', params })
}
export function getLandSingleSelectCity(params) {
    return get({ url: '/service-insight/land_dim/singleSelectCity', params })
}
export function getLandSingleSelectRegion(params) {
    return get({ url: '/service-insight/land_dim/singleSelectRegion', params })
}
// 交易状态维度
export function getDealState(params) {
    return get({ url: '/service-insight/land_dim/dealState', params })
}
// 土地使用维度
export function getLandUse(params) {
    return get({ url: '/service-insight/land_dim/landUse', params })
}
// 土地使用维度
export function getLandUse2(params) {
    return get({ url: '/service-insight/xmfx_dim/landUse', params })
}
// 出让方式维度
export function getSellWay(params) {
    return get({ url: '/service-insight/land_dim/sellWay', params })
}
// 企业分群维度
export function getEnterprise(params) {
    return get({ url: '/service-insight/land_dim/enterprise', params })
}
// 时间维度
export function getTime(params) {
    return get({ url: '/service-insight/land_dim/time', params })
}
// 区域树维度
export function getArea(params) {
    return get({ url: '/service-insight/land_dim/area_tree', params })
}
// 城市区域维度
export function getCity(params) {
    return get({ url: '/service-insight/land_flock_area/get_flock', params })
}
// 土地统计受让方企业维度
export function dimSrf(params) {
    return post({ url: '/service-insight/land_dim/dimSrf', params })
}
// 土地统计受让方集团维度
export function dimSrfJt(params) {
    return post({ url: '/service-insight/land_dim/dimSrfJt', params })
}

// input 搜索城市
export function searchCity(params) {
    return get({ url: "/service-insight/index_data/selectCity", params });
}

// 搜索
export function placeSearch(params) {
    return post({ url: "/service-insight/land_statistics_master/get_data", params });
}
export function conditionSearch(params) {
    return post({ url: "/service-insight/query_land/conditionSearch", params });
}


//证券市场
export function getZqsc(params) {
    return get({ url: '/service-insight/finance_jnz/zjsc', params })
}
//证券类型(中地会)
export function getZjlxZdh(params) {
    return get({ url: '/service-insight/finance_jnz/zjlx_zdh', params })
}

//证券类型(原始)
export function getZjlx(params) {
    return get({ url: '/service-insight/finance_jnz/zqlxy', params })
}

// 文件上传
export function uploadFile(params) {
    return post({ url: '/service-upload/upload', params })
}
// 文件上传设置
export const _file_conf = {
    host: baseURL,
    uploadUrl: `${baseURL}/service-upload/upload`,
    maxSize: 102400, //100M
    formatXls: ['xls', 'xlsx'],
    accept: "file",
    headers: {
        Authorization: localStorage.getItem("token") || "",
    }
}


//获取企业
export function getQy(params) {
    return get({ url: '/service-insight/enterprise_dim/getQy', params })
}


//添加个人消息
export function add_info(params) {
    return post({ url: '/service-insight/my_info/add_info', params })
}
//反馈顶层菜单维度
export function top_menu(params) {
    return get({ url: '/service-insight/my_info/top_menu', params })
}
//反馈二级菜单维度
export function two_menu(params) {
    return get({ url: '/service-insight/my_info/two_menu', params })
}


//建议选项
export function info_options(params) {
    return get({ url: '/service-insight/my_sys_info/info_options', params })
}

//文件下载
export function downFile(url, data = {}, responseType = "", cb) {
    return new Promise((resolve, reject) => {
        axios({
                method: "post",
                url: url,
                data: data,
                responseType: responseType,
                onDownloadProgress: (a) => {
                    if (cb) {
                        cb(a);
                    }
                },
            })
            .then((response) => {
                if (
                    response.headers.hasOwnProperty("content-type") &&
                    response.headers["content-type"].toLowerCase() ==
                    "application/json;charset=utf-8"
                ) {
                    resolve(response);
                } else {
                    resolve(response);
                }
            })
            .catch(function(error) {

                reject(error);
            });
    });
}