
import {custom_order_type} from '@/api/customOrder';

const city = {
    state: {
        option : {
            orderType : [],
        },
        showTip:true,
        item : null,
    },
    actions: {
        initCustomizationData({ state }) {
            custom_order_type().then(data => {
                state.option.orderType = data;
            })
        }
    }
}
export default city;
