import { post, get } from '@/utils/http';

// 角色列表
export function getRoleList(params) {
    return post({ url: '/service-user/data_sys_role_info/pageList', params });
}

// 新增/修改角色
export function saveOrUpdate(params) {
    return post({ url: '/service-user/data_sys_role_info/save', params });
}

// 删除角色
export function deleteRoleApi(params) {
    return get({ url: '/service-user/data_sys_role_info/delete', params });
}

// 获取角色信息
export function getRoleDetail(params) {
    return get({ url: '/service-user/data_sys_role_info/getObj', params });
}

// 新增账号
export function addAccount(params) {
    return post({ url: '/service-user/data_user_info/add', params });
}

// 账号列表
// export function getAccountList(params) {
//     return post({ url: '/service-user/data_user_info/get_user_page', params });
// }
// 数据平台账号列表
export function get_data_user_page(params) {
    return post({ url: '/service-user/data_user_info/get_data_user_page', params });
}
// 检查是否重复
export function checkRepeat(params) {
    return get({ url: '/service-user/data_user_info/user_repetition', params });
}

// 查看账号资料
export function detailApi(params) {
    return post({ url: `/service-user/data_user_info/detail?id=${params}` });
}

// 修改用户资料
export function editAccount(params) {
    return post({ url: `/service-user/data_user_info/update`, params });
}

// 修改密码
export function updatePassword(params) {
    return get({ url: `/service-user/user_info/resetPwd`, params });
}

// 账号授权
export function userAuthApi(params) {
    return post({ url: '/service-user/data_user_info/data_user_auth', params });
}

// 获取全部功能授权
export function funcAuthApi(params) {
    return post({ url: '/service-user/data_sys_resource_info/dataResourceTree', params });
}

// 获取对应角色的功能授权
export function funcAuthIdApi(params) {
    return get({ url: '/service-user/data_sys_resource_info/roleResourceIDs', params });
}

// 获取对应角色的功能授权
export function getDownListByRole(params) {
    return get({ url: '/service-user/data_sys_resource_info/getDownListByRole', params });
}
// 获取全部报表授权
export function reportAuthApi(params) {
    return post({ url: '/service-insight/index_open/model_tree_by_ids', params });
}

// 获取对应角色的报表授权权限
export function reportAuthIdApi(params) {
    return get({ url: '/service-user/data_sys_resource_info/roleIndexIDs', params });
}

// 获取功能授权——数据权限——树状结构
export function dataAuthTreeApi(params) {
    return get({ url: '/service-user/data_sys_down_data/down_list_tree', params });
}

// 功能授权
export function grantResourceApi(params) {
    return post({ url: '/service-user/data_sys_role_info/grantResource', params });
}

// 报表授权
export function grantReportApi(params) {
    return post({ url: '/service-user/data_sys_role_index/grantIndex', params });
}

// 获取角色Id
export function getRoleIdApi(params) {
    return get({ url: '/service-user/data_sys_resource_info/getRoleByUser', params });
}

//获取角色授权
export function resource_tree_for_role(params) {
    return get({ url: '/service-user/data-sys-role-package/resource-tree-for-role', params });
}
//授权
export function grant_role(params) {
    return post({ url: '/service-user/data-sys-role-package/grant-role', params });
}


//获取用户信息
export function get_current_customer(params) {
    return get({ url: '/service-user/data_sys_customers/get_current_customer', params });
}

//获取用户档案
export function data_user_detail_permission(params) {
    return get({ url: '/service-user/data_sys_customers/data_user_detail_permission', params });
}

//获取角色列表
export function customer_role_list(params) {
    return post({ url: '/service-user/data-sys-role-package/customer-role-list', params });
}


//删除账号
export function deleteAccount(params) {
    return get({ url: '/service-user/data_user_info/delete', params });
}
//获取可用账号
export function usableUserCount(params) {
    return get({ url: '/service-user/data_user_info/usableUserCount', params });
}
