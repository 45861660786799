const competing = {
	path: '/Insight/enterprise/competing',
	name: '企业分析',
	component: () => import('@/views/reHouseEnterprise/competing'),
	//redirect: '/Insight/enterprise/competing/financial',
	meta: {
		authId: [98],
		function: '企业监测',
		iconOrImg: 'icon',
		icon: 'iconfont icon-qiyefenxi',
		img:''
	},
	children: [{
		path: '/Insight/enterprise/competing/financial',
		name: '财务',
		component: () => import('@/views/reHouseEnterprise/competing/financial'),
		meta: {
			pageId: 99,
			authId: [99],
			function: '企业监测',
			iconOrImg: 'icon',
			icon: '',
			img:''
		},
	}, ]

}
export default competing;
