<template>
	<Modal class="advice-modal" footer-hide :closable="false" :width="860" v-model="showAdviceModal"
		class-name="vertical-center-modal">
		<div class="modal-title">用户建议</div>
		<div class="option-box">
			<Form :model="formItem" inline :rules="ruleValidate" ref="form1">
				<FormItem label="标题" prop="title">
					<Input :disabled='addAdviceDataDisable' v-model="formItem.title" style="width:740px;" />
				</FormItem>
			</Form>
			<Form :model="formItem" inline :rules="ruleValidate" ref="form2">
				<FormItem label="类型" prop="adviseType">
					<Select :disabled='addAdviceDataDisable' v-model="formItem.adviseType" clearable filterable
						style="width:315px;">
						<Option v-for="(item,index) in optionList.adviseType" :key="index" :value="item.value">
							{{item.name}}
						</Option>
					</Select>
				</FormItem>

				<FormItem label="内容类型" prop="contentType">
					<Select :disabled='addAdviceDataDisable' v-model="formItem.contentType" clearable filterable
						style="width:300px;">
						<Option v-for="(item,index) in optionList.contentType" :key="index" :value="item.value">
							{{item.name}}
						</Option>
					</Select>
				</FormItem>
			</Form>
			<Form :model="formItem" inline ref="form3">
				<FormItem label="所属模板" style="margin-right:42px;">
					<Select :disabled='addAdviceDataDisable' @on-change="topMenuChange" v-model="formItem.moduleId"
						clearable filterable style="width:145px;margin-right:10px;">
						<Option v-for="(item,index) in optionList.topMenu" :key="index" :value="item.id">{{item.name}}
						</Option>
					</Select>
					<Select :disabled='addAdviceDataDisable' v-model="formItem.moduleSunId" clearable filterable
						style="width:145px;">
						<Option v-for="(item,index) in optionList.twoMenu" :key="index" :value="item.id">{{item.name}}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="页面url">
					<Input :disabled='addAdviceDataDisable' v-model="formItem.pageUrl" style="width:300px;" />
				</FormItem>
			</Form>
			<Form :model="formItem" label-position="top" :rules="ruleValidate" ref="form4">
				<FormItem label="内容" prop="infoDetail" class="top-item">
					<Input v-model="formItem.infoDetail" type="textarea" show-word-limit maxlength="1000" :rows="6" />
				</FormItem>
			</Form>
		</div>
		<div class="upload-box">
			<div class="title">上传附件</div>
			<div class="line">
				<div class="upload" @click="addFile">
					<img :src="formItem.src" v-if="formItem.src" />
				</div>
				<div class="tip">*为便于您更加清晰快捷描述问题，您可以截图上传。</div>

				<input class="file-input" type="file" accept="image/*" ref="fileInput" @change="uploadFile" />
			</div>
		</div>
		<div class="btn-box" v-if="!disabled">
			<div class="btn sure" @click="sure">确定</div>
			<div class="btn cancel" @click="cancel">取消</div>
		</div>
	</Modal>
</template>

<script>
	import SparkMD5 from 'spark-md5';
	import {
		uploadFile
	} from '@/api/public';
	import {
		getFileType
	} from '@/utils/index';
	import {
		add_info,
		top_menu,
		two_menu
	} from '@/api/public'
	import {
		mapState
	} from 'vuex';
	export default {
		data() {
			return {
				formItem: {
					src: "",
					title: "",
					infoDetail: "",
					moduleId: "",
					moduleSunId: "",
					adviseType: "",
					contentType: "",
					attachUrl: "",
					pageUrl: "",
				},
				addAdviceDataDisable: false,
				disabled: false,
				optionList: {
					topMenu: [],
					twoMenu: [],
					adviseType: [{
							name: '咨询',
							value: 1
						},
						{
							name: '建议',
							value: 3
						},
						{
							name: '求助',
							value: 4
						},
						{
							name: '疑问',
							value: 5
						},
						{
							name: '投诉',
							value: 2
						},
						{
							name: '其他',
							value: 6
						},
					],
					contentType: [{
							name: "综合",
							value: 1,
						},
						{
							name: "功能疑问及优化",
							value: 2,
						},
						{
							name: "数据疑问及其优化",
							value: 3,
						},
						{
							name: "统计结果",
							value: 4,
						},
						{
							name: "评估结果",
							value: 5,
						},
						{
							name: "响应速度",
							value: 6,
						},
						{
							name: "操作便利性",
							value: 7,
						},
						{
							name: "其他",
							value: 8,
						},
					]
				},

				ruleValidate: {
					title: [{
						required: true,
						message: '此项必填',
						trigger: 'blur'
					}, ],
					adviseType: [{
						required: true,
						message: '此项必填'
					}, ],
					contentType: [{
						required: true,
						message: '此项必填'
					}, ],
					infoDetail: [{
						required: true,
						message: '此项必填',
						trigger: 'blur'
					}, ],
				}
			}
		},
		computed: {
			...mapState(['token']),
			showAdviceModal: {
				get() {
					return this.$store.state.modal.showAdviceModal;
				},
				set(newValue) {
					this.$store.state.modal.showAdviceModal = newValue;
				}
			},
			adviceData: {
				get() {
					return this.$store.state.modal.adviceData;
				},
				set(newValue) {
					this.$store.state.modal.adviceData = newValue;
				}
			},
			adviceInfo: {
				get() {
					return this.$store.state.adviceInfo;
				},
				set(newValue) {
					this.$store.state.adviceInfo = newValue;
				}
			},
			addAdviceData: {
				get() {
					return this.$store.state.modal.addAdviceData;
				},
				set(newValue) {
					this.$store.state.modal.addAdviceData = newValue;
				}
			},
			route() {
				return this.$route.meta
			}
		},
		watch: {
			token: {
				immediate: true,
				handler: function(val) {
					if (val) {
						top_menu().then(res => {
							this.optionList.topMenu = res;
						})
					}
				}
			},
			showAdviceModal: function(val) {
				if (!val) {
					this.clear();
				}
			},
			adviceData: async function(val) {
				if (val) {
					let data = this.formItem;
					data.pageUrl = val.pageUrl;
					if (val.moduleId) {
						data.moduleId = val.moduleId;
						let id = await this.topMenuChange(val.moduleId);
						val.moduleSunId && (data.moduleSunId = val.moduleSunId)
					}
					this.adviceData = null;
				}
			},
			adviceInfo: async function(val) {
				if (val) {
					const {
						title,
						infoDetail,
						moduleId,
						moduleSunId,
						adviseType,
						contentType,
						attachUrl,
						pageUrl,
					} = val
					this.formItem = {
						src: attachUrl,
						title,
						infoDetail,
						moduleId,
						moduleSunId,
						adviseType,
						contentType,
						attachUrl,
						pageUrl,
					}
					this.disabled = true
					this.adviceInfo = ''
				}
			},
			addAdviceData: async function(val) {
				if (val) {
					this.addAdviceDataDisable = true
					const {
						title,
						infoDetail,
						moduleId,
						moduleSunId,
						adviseType,
						contentType,
						attachUrl,
						pageUrl,
					} = val
					if (val.moduleId) {
						this.formItem.moduleId = val.moduleId;
						let id = await this.topMenuChange(val.moduleId);
						val.moduleSunId && (this.formItem.moduleSunId = val.moduleSunId)
					}
					this.formItem = {
						src: attachUrl,
						title,
						infoDetail,
						moduleId,
						moduleSunId,
						adviseType,
						contentType,
						attachUrl,
						pageUrl,
					}
					this.addAdviceData = ''
				}
			},

		},
		mounted() {},
		methods: {
			addFile() {
				this.$refs.fileInput.click();
			},
			beforeUpload(file) {
				return new Promise((resolve, reject) => {
					let _self = this;
					let fileReader = new FileReader();
					let blobSlice = File.prototype.mozSlice || File.prototype.webkitSlice || File.prototype.slice,
						chunkSize = 2097152,
						chunks = Math.ceil(file.size / chunkSize),
						currentChunk = 0,
						spark = new SparkMD5();

					fileReader.onload = function(e) {
						spark.appendBinary(e.target.result);
						currentChunk++;
						if (currentChunk < chunks) {
							loadNext();
						} else {
							resolve({
								md5_key: spark.end(),
								md5_name: file.name
							});
						}
					};

					function loadNext() {
						let start = currentChunk * chunkSize,
							end = start + chunkSize >= file.size ? file.size : start + chunkSize;
						fileReader.readAsBinaryString(blobSlice.call(file, start, end));
					}
					loadNext();
				});
			},
			async uploadFile(ev) {
				let formItem = this.formItem;
				const file = ev.target.files[0];
				// const type = getFileType(file.name);
				const formData = new FormData();
				// const obj = {};
				formItem.src = URL.createObjectURL(file);

				const result = await this.beforeUpload(file);
				formData.append('file', file);
				formData.append('filename', result.md5_name);
				formData.append('md5', result.md5_key);

				uploadFile(formData).then(url => {
					formItem.attachUrl = url;
					// this.name = file.name;
				});
				// obj.name = file.name;
				// obj.type = type;

				ev.target.value = null;
			},

			getName(id, list) {
				let str = "";
				this.optionList[list].filter(item => {
					if (item.id == id || item.value == id) {
						str = item.name;
						return true;
					}
				})
				return str;
			},

			async topMenuChange(id) {
				if (!id) return;
				this.formItem.moduleSunId = "";
				two_menu({
					pid: id
				}).then(res => {
					this.optionList.twoMenu = res;
				})
			},
			checkValid() {
				let valid1, valid2, valid3, valid4 = false;

				this.$refs['form1'].validate((valid) => valid1 = valid)
				this.$refs['form2'].validate((valid) => valid2 = valid)
				this.$refs['form3'].validate((valid) => valid3 = valid)
				this.$refs['form4'].validate((valid) => valid4 = valid)

				return valid1 && valid2 && valid4;
			},
			sure() {
				if (!this.checkValid() || this.busy) {
					return;
				}

				this.busy = true;
				let item = this.formItem;
				add_info({
					title: item.title,
					adviseType: item.adviseType,
					adviseTypeName: this.getName(item.adviseType, "adviseType"),
					attachUrl: item.attachUrl,
					contentName: this.getName(item.adviseType, "contentType"),
					contentType: item.contentType,
					infoDetail: item.infoDetail,
					moduleId: item.moduleId,
					moduleName: this.getName(item.moduleId, "topMenu"),
					moduleSunId: item.moduleSunId,
					moduleSunName: this.getName(item.moduleSunId, "twoMenu"),
					pageUrl: item.pageUrl,
					userId: this.$store.state.userId,
				}).then(res => {
					this.clear();
					this.cancel();
					this.$msg.success({
						text: '提交成功，感谢您的宝贵意见！'
					})
					// this.$msg.success("反馈成功")
				}).finally(() => {
					this.addAdviceDataDisable = false
					this.busy = false;
				})
			},
			clear() {
				this.formItem = {
					src: "",
					title: "",
					infoDetail: "",
					moduleId: "",
					moduleSunId: "",
					adviseType: "",
					contentType: "",
					attachUrl: "",
					pageUrl: "",
				}
				this.disabled = false
				this.addAdviceDataDisable = false

			},
			cancel() {
				this.showAdviceModal = false;
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/style/common.scss";

	/deep/ .vertical-center-modal {
		@include flex;

		.ivu-modal {
			top: 0;
		}

		.ivu-modal-body {
			padding: 0 20px 30px;
			overflow: hidden;
		}
	}

	.advice-modal {
		.modal-title {
			margin: 20px 0 30px;
			font: 18px bold;
		}

		/deep/ .ivu-form-item {
			margin-right: 30px;

			.ivu-form-item-label {
				// width: 120px;
			}

			.ivu-form-item-content {
				display: inline-block;
			}
		}

		/deep/ .top-item {
			margin-right: 0;

			.ivu-form-item-label {
				width: auto;
			}

			.ivu-form-item-content {
				display: block;
			}
		}

		.upload-box {
			.title {
				margin-bottom: 10px;
			}

			.line {
				@include flex(flex-start, flex-end);

				.upload {
					@include bgSrc("upload.png");
					width: 100px;
					height: 100px;
					margin-right: 10px;
					cursor: pointer;
				}

				.tip {
					color: #ff3333;
					font-size: 12px;
				}

				.file-input {
					display: none;
				}

				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.btn-box {
			@include flex;
			margin: 30px 0 0;

			.btn {
				@include flex;
				width: 144px;
				height: 40px;
				font-size: 18px;
				border-radius: 5px;
				margin-right: 20px;
				cursor: pointer;

				&.sure {
					color: #fff;
					background-color: #00b6ff;
				}

				&.cancel {
					background-color: #f2f2f2;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
</style>
