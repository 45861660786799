import { heartCountApi, pageCountApi, btnCountApi,buttonSysCountApi } from '../api/dateReport';
import store from '../store';

//获取操作系统版本
function detectOS() {
    const sUserAgent = navigator.userAgent;
    const isWin = navigator.platform == 'Win32' || navigator.platform == 'Windows';
    const isMac =
        navigator.platform == 'Mac68K' ||
        navigator.platform == 'MacPPC' ||
        navigator.platform == 'Macintosh' ||
        navigator.platform == 'MacIntel';
    if (isMac) return 'Mac';
    const isUnix = navigator.platform == 'X11' && !isWin && !isMac;
    if (isUnix) return 'Unix';
    const isLinux = String(navigator.platform).indexOf('Linux') > -1;
    if (isLinux) return 'Linux';
    if (isWin) {
        const isWin2K = sUserAgent.indexOf('Windows NT 5.0') > -1 || sUserAgent.indexOf('Windows 2000') > -1;
        if (isWin2K) return 'Win2000';
        const isWinXP = sUserAgent.indexOf('Windows NT 5.1') > -1 || sUserAgent.indexOf('Windows XP') > -1;
        if (isWinXP) return 'WinXP';
        const isWin2003 = sUserAgent.indexOf('Windows NT 5.2') > -1 || sUserAgent.indexOf('Windows 2003') > -1;
        if (isWin2003) return 'Win2003';
        const isWinVista = sUserAgent.indexOf('Windows NT 6.0') > -1 || sUserAgent.indexOf('Windows Vista') > -1;
        if (isWinVista) return 'WinVista';
        const isWin7 = sUserAgent.indexOf('Windows NT 6.1') > -1 || sUserAgent.indexOf('Windows 7') > -1;
        if (isWin7) return 'Win7';
        const isWin10 = sUserAgent.indexOf('Windows NT 10') > -1 || sUserAgent.indexOf('Windows 10') > -1;
        if (isWin10) return 'Win10';
    }
    return 'other';
}

// 获取浏览器及版本
function browserVersion() {
    const userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1; //判断是否IE<11浏览器
    const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
    const isEdge = userAgent.indexOf('Edge') > -1 && !isIE; //Edge浏览器
    const isFirefox = userAgent.indexOf('Firefox') > -1; //Firefox浏览器
    const isOpera = userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1; //Opera浏览器
    const isChrome =
        userAgent.indexOf('Chrome') > -1 &&
        userAgent.indexOf('Safari') > -1 &&
        userAgent.indexOf('Edge') == -1 &&
        userAgent.indexOf('OPR') == -1; //Chrome浏览器
    const isSafari =
        userAgent.indexOf('Safari') > -1 &&
        userAgent.indexOf('Chrome') == -1 &&
        userAgent.indexOf('Edge') == -1 &&
        userAgent.indexOf('OPR') == -1; //Safari浏览器
    if (isIE) {
        const reIE = new RegExp('MSIE (\\d+\\.\\d+);');
        reIE.test(userAgent);
        const fIEVersion = parseFloat(RegExp['$1']);
        if (fIEVersion == 7) {
            return 'IE7';
        } else if (fIEVersion == 8) {
            return 'IE8';
        } else if (fIEVersion == 9) {
            return 'IE9';
        } else if (fIEVersion == 10) {
            return 'IE10';
        } else {
            return 'IE6'; //IE版本<7
        }
    } else if (isIE11) {
        return 'IE11';
    } else if (isEdge) {
        return 'Edge' + userAgent.split('Edge/')[1].split('.')[0];
    } else if (isFirefox) {
        return 'Firefox' + userAgent.split('Firefox/')[1].split('.')[0];
    } else if (isOpera) {
        return 'Opera' + userAgent.split('OPR/')[1].split('.')[0];
    } else if (isChrome) {
        return 'Chrome' + userAgent.split('Chrome/')[1].split('.')[0];
    } else if (isSafari) {
        return 'Safari' + userAgent.split('Safari/')[1].split('.')[0];
    } else {
        return -1; //不是ie浏览器
    }
}

var heartCountTimer = null;
let firstHeartCount = true;
const clientOS = detectOS();
const clientBrowser = browserVersion();

// 心跳上报
export function heartCount(store) {
    if (heartCountTimer) {
        clearInterval(heartCountTimer);
        heartCountTimer = null;
    }

    const interval = 60000;

    // 先执行一次
    if (firstHeartCount) {
        heartCountApi({
            clientOS,
            clientBrowser,
            // clientIP: returnCitySN.cip,
            pageId: store.state.pageId,
            userId: store.state.userId,
            reportTime: Date.now(),
        }).then(() => {
            firstHeartCount = false;
        });
    }

    heartCountTimer = setInterval(() => {
        heartCountApi({
            pageId: store.state.pageId,
            userId: store.state.userId,
            // clientIP: returnCitySN.cip,
            clientOS,
            clientBrowser,
            reportTime: Date.now(),
        });
    }, interval);
}

// 页面访问上报
export function pagelog(id, type, self,close = false) {
    switch (type) {
        case 'enter':
            sessionStorage.setItem('enterPageTime', Date.now());
            break;
        case 'leave': {
            const enterPageTime = +sessionStorage.getItem('enterPageTime');
            if(sessionStorage.getItem('lastEnterPageTime')==enterPageTime){
                return
            }
            const leavePageTime = Date.now();
            sessionStorage.setItem('leavePageTime', leavePageTime);
            const diff = leavePageTime - enterPageTime;
            const lastEntrPageTime = sessionStorage.setItem('lastEnterPageTime',enterPageTime)
            return pageCountApi({
                pageId: id,
                pageEnterTime: enterPageTime,
                pageDestoryTime: leavePageTime,
                pageStayTime: diff,
                userId: self.$store.state.userId,
                // clientIP: returnCitySN.cip,
                clientOS,
                clientBrowser,
            },close);
        }
        case 'customer':{
            return pageCountApi({
                pageId: id,
                pageEnterTime: self.pageEnterTime,
                pageDestoryTime: self.leavePageTime,
                pageStayTime: self.leavePageTime-self.pageEnterTime,
                userId: self.$store.state.userId,
                // clientIP: returnCitySN.cip,
                clientOS,
                clientBrowser,
            });
        }
        default:
            throw new Error('pagelog error');
    }
}

// 按钮点击上报
export function btnlog(id) {
    btnCountApi({
        controlId: id,
        triggerTime: Date.now(),
        userId: parseInt(store.state.userId),
        // clientIP: returnCitySN.cip,
        clientOS,
        clientBrowser,
    });
}

/**
 *
 * @param {Number} fid 按钮ID
 * @param {Number} firstStage
 * @param {Number} followUserId 跟进人账号id(查询条件)
 * @param {Number} pageId 页面ID
 * @param {Number} pageName 按钮链接名称
 * @param {Number} userId 账号id（查询条件）
 * @param {Number} buttonType 按钮类型 0 查询 1 下载数据 2 下载报告
 */
export function btnSysLog(id,buttonType,page = ''){
    let btnId = ''
    let type = ''
    if( id < 10 ){
        btnId = '0'
    }
    btnId += id
    if(buttonType< 10){
        type = "0"
    }

    type += buttonType
    let pageId = page?page.pageId:this.$route.meta.pageId
    let pageName = page?page.pageName:this.$route.meta.pageName?this.$route.meta.pageName:this.$route.name
    let buttonId = pageId + type + btnId
    // debugger
    buttonSysCountApi({
        fid: Number(buttonId),
        firstStage:'',
        followUserId:'',
        pageId: pageId,
        pageName:pageName,
        userId: parseInt(store.state.userId),
        buttonType: buttonType
    })
}
