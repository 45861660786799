import bank from "./bank";
import competing from "./competing";
import reHouseEnterprise from "./reHouseEnterprise";

const enterprise = {
    path: "/Insight/enterprise",
    name: "enterprise",
    component: () => import('@/views/enterprise/index.vue'),
    meta: {
        authId: [38, 40],
        function: "企业监测",
    },
    children: [
        {
            path: '/Insight/enterprise/iframe',
            name: '企业视角',
            component: () => import('@/views/iframe.vue'),
            meta: {
                id: 110,
                pageId: 110,
                title: '中思拓-数据洞察',
                authId: [110],
                display: true,
                function: '企业监测',
                iconOrImg: 'icon',
                icon: 'iconfont icon-qiyeshijiao',
                img:''
            },
            props: (route) => ({
                rPath: route.query.rPath
            }),
            children: [
                {
                    path: '/Insight/enterprise/111/iframe',
                    name: '企业财务分析',
                    component: () => import('@/views/iframe.vue'),
                    meta: {
                        id: 111,
                        pageId: 111,
                        title: '中思拓-数据洞察',
                        authId: [111],
                        display: true,
                        function: '企业监测',
                        indexName: '企业财务分析',
                        iconOrImg: 'icon',
                        icon: '',
                        img:''
                    },
                    props: (route) => ({
                        rPath: route.query.rPath
                    }),
                },
                {
                    path: '/Insight/enterprise/112/iframe',
                    name: '三道红线',
                    component: () => import('@/views/iframe.vue'),
                    meta: {
                        indexName: '三道红线',
                        id: 112,
                        pageId: 112,
                        title: '中思拓-数据洞察',
                        authId: [112],
                        display: true,
                        iconOrImg: 'icon',
                        icon: '',
                        img:'',
                        function: '企业监测',
                    },
                    props: (route) => ({
                        rPath: route.query.rPath
                    }),
                },
                {
                    path: '/Insight/enterprise/113/iframe',
                    name: '企业融资成本分析',
                    component: () => import('@/views/iframe.vue'),
                    meta: {
                        id: 113,
                        pageId: 113,
                        title: '中思拓-数据洞察',
                        authId: [113],
                        display: true,
                        function: '企业监测',
                        indexName: '企业融资成本分析',
                        iconOrImg: 'icon',
                        icon: '',
                        img:''
                    },
                    props: (route) => ({
                        rPath: route.query.rPath
                    }),
                },
                {
                    path: '/Insight/enterprise/114/iframe',
                    name: '企业收入质量分析',
                    component: () => import('@/views/iframe.vue'),
                    meta: {
                        id: 114,
                        pageId: 114,
                        title: '中思拓-数据洞察',
                        authId: [114],
                        display: true,
                        function: '企业监测',
                        indexName: '企业收入质量分析',
                        iconOrImg: 'icon',
                        icon: '',
                        img:''
                    },
                    props: (route) => ({
                        rPath: route.query.rPath
                    }),
                },
                {
                    path: '/Insight/enterprise/115/iframe',
                    name: '企业销售及土地储备分析',
                    component: () => import('@/views/iframe.vue'),
                    meta: {
                        id: 115,
                        pageId: 115,
                        title: '中思拓-数据洞察',
                        authId: [115],
                        display: true,
                        function: '企业监测',
                        indexName: '企业销售及土地储备分析',
                        iconOrImg: 'icon',
                        icon: '',
                        img:''
                    },
                    props: (route) => ({
                        rPath: route.query.rPath
                    }),
                }
            ]
        },
        // 企业分析
        competing,
        {
            path: "/Insight/enterprise/enterpriseHome",
            name: "企业大数据",
            component: () => import("@/views/enterprise/index.vue"),
            meta: {
                authId: [40],
                function: "企业监测",
                iconOrImg: 'img',
                icon: 'iconfont icon-qiyedashuju',
                img:require("@/assets/image/dsj.png")
            },
            children: [
                {
                    path: '/Insight/enterprise/enterpriseHome/enterpriseSearch',
                    name: '企业搜索',
                    component: () => import('@/views/enterprise/EnterpriseHome.vue'),
                    meta: {
                        pageName: '企业搜索',
                        pageId: 117,
                        authId: [117],
                        function: '企业监测',
                        iconOrImg: 'icon',
                        icon: '',
                        img:''
                    }
                },
                reHouseEnterprise,
                bank
            ]
        },

    ],
}
export default enterprise;
