// 城市系统
const city =
    {
        path: '/Insight/City',
        name: '城市发展',
        component: () => import('@/views/city/index.vue'),
        meta: {
            authId: [28],
            function: '城市系统',
            indexName:'城市洞察',
        },
        children: [
            {
                path: '/Insight/City/iframe',
                name: '城市洞察',
                component: () => import('@/views/iframe.vue'),
                meta: {
                    id: 109,
                    pageId: 109,
                    title: '中思拓-数据洞察',
                    authId: [109],
                    display: true,
                    function:'城市系统',
                    indexName:'城市洞察',
                    iconOrImg: 'icon',
                    icon: 'iconfont icon-qiyeshijiao',
                    img:''
                },
                props : (route) => ({
                    rPath : route.query.rPath
                })
            }
            ,{
            path: 'stat',
            name: '商品房统计',
            component: () => import('@/views/city/statPage.vue'),
            meta: {
                authId: [29],
                function: '城市系统',
                iconOrImg: 'icon',
                icon:"iconfont icon-shangpinfangtongji",
                img:''
            },
            children: [
            // {
            //     path: '/Insight/City/stat/provinceStat',
            //     name: '省市统计',
            //     component: () => import('@/views/city/provinceStat.vue'),
            //     meta: {
            //         pageId: 30,
            //         authId: [30],
            //         function: '城市系统',
            //         iconOrImg: 'icon',
            //         icon:'',

            //         img:''
            //     },
            // },
                {
                    path: '/Insight/City/stat/cityStat',
                    name: '城市统计',
                    component: () => import('@/views/city/cityStat2.vue'),
                    meta: {
                        title: '中思拓-城市统计',
                        pageId: 31,
                        authId: [31],
                        function: '城市系统',
                        iconOrImg: 'icon',
                        icon:'',
                        img:''
                    },
                },
            ]
        }]
    }
export default city