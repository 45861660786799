import placeMarketOld from "@/router/dataInsight/land/placeMarketOld";

const reLand = {
    path: '/Insight/land',
    name: '土地市场',
    component: () => import('@/views/reLand/index.vue'),
    meta: {
        id:9,
        pageId: 9,
        authId: [9],
        display: true,
        function: '土地市场'
    },
    redirect: '/Insight/land/107/iframe',
    children: [
        {
            path: '/Insight/land/107/iframe',
            name: '土地观察',
            component: () => import('@/views/iframe.vue'),
            meta: {
                id: 107,
                pageId: 107,
                authId: [107],
                title: '中思拓-数据洞察',
                function: '土地观察',
                indexName:'土地观察',
                iconOrImg: 'icon',
                icon: 'iconfont icon-tudiguancha'
            },
            props: (route) => ({
                rPath: route.query.rPath
            })
        },
        {
            path: '/Insight/land/landOverview',
            name: '地图概览',
            meta: {
                id:102,
                pageId: 102,
                authId: [102],
                function: '土地市场',
                iconOrImg: 'img',
                icon: 'iconfont icon-ditugailan',
                img:require('../../../assets/image/dtgl.svg'),
                //keepAlive: true // 需要缓存
            },
            component: () => import('@/views/reLand/landOverview/index.vue')
        },
        {
            path: '/Insight/land/landList',
            name: '地块列表',
            meta: {
                id:103,
                pageId: 103,
                authId: [103],
                function: '土地市场',
                iconOrImg: 'img',
                icon: 'iconfont icon-dikuailiebiao',
                img:require('../../../assets/image/dklb.svg'),
                //keepAlive: true // 需要缓存
            },
            component: () => import('@/views/reLand/landList/index.vue'),
            children: [{
                path: '/Insight/land/listSearch',
                name: '列表搜索',
                meta: {
                    id:104,
                    pageId: 104,
                    authId: [104],
                    function: '土地市场',
                    iconOrImg: 'icon',
                    icon: '',
                    img:''
                    //keepAlive: true // 需要缓存
                },
                component: () => import('@/views/reLand/landList/listSearch/index.vue')
            },
                {
                    path: '/Insight/land/mapSearch',
                    name: '地图搜索',
                    meta: {
                        id:105,
                        pageId: 105,
                        authId: [105],
                        function: '土地市场',
                        iconOrImg: 'icon',
                        icon: '',
                        img:''
                        //keepAlive: true // 需要缓存
                    },
                    component: () => import('@/views/reLand/landList/mapSearch/index.vue')
                }
            ]
        },
        {
            path: '/Insight/land/landStatistical',
            name: '土地统计',
            meta: {
                authId: [9],
                function: '土地市场',
                hidden: true,
                //keepAlive: true // 需要缓存
            },
            component: () => import('@/views/reLand/landStatistical')
        },
        {
            path: '/Insight/land/landRanking',
            name: '土地排行榜',
            meta: {
                authId: [9],
                function: '土地市场',
                hidden: true,
                //keepAlive: true // 需要缓存
            },
            component: () => import('@/views/reLand/landRanking'),
            children: [{
                path: '/Insight/land/landRanking/land',
                name: '地块排行',
                meta: {
                    authId: [9],
                    function: '土地市场',
                    hidden: true,
                    //keepAlive: true // 需要缓存
                },
                component: () => import('@/views/reLand/landRanking/land')
            },
                {
                    path: '/Insight/land/landRanking/company',
                    name: '企业排行',
                    meta: {
                        authId: [9],
                        function: '土地市场',
                        hidden: true,
                        //keepAlive: true // 需要缓存
                    },
                    component: () => import('@/views/reLand/landRanking/company')
                },
                {
                    path: '/Insight/land/landRanking/area',
                    name: '区域排行',
                    meta: {
                        authId: [9],
                        function: '土地市场',
                        hidden: true,
                        //keepAlive: true // 需要缓存
                    },
                    component: () => import('@/views/reLand/landRanking/area')
                }
            ]
        },
        {
            path: '/Insight/land/landDetail',
            name: '土地详情',
            meta: {
                // authId: [9],
                function: '土地市场',
                hidden: true,
                //keepAlive: true // 需要缓存
            },
            component: () => import('@/views/reLand/landDetail')
        },
        //旧土地
        // placeMarketOld,
    ]
}
export default reLand;
