import store from '@/store/index.js';
import {
	post
} from "./http";
import router from '@/router/index.js';


const img = require('../assets/image/advice.png')
// 格式化数字
function formatNumber(n) {
	const str = n.toString();
	return str[1] ? str : `0${str}`;
}
//把字符串日期转化为时间戳
export function localeDateStringtoTimestamp(dateString) {
	if (dateString) {
		if (dateString.split('上午').length == 2) {
			var _date = dateString.split('上午').join("");
			return new Date(_date)
		}
		if (dateString.split('下午').length == 2) {
			var _dateArray = dateString.split('下午');
			var ymd = _dateArray[0];
			var hms = _dateArray[1].split(":");
			hms[0] = parseInt(hms[0]) < 10 ? (parseInt(hms[0]) + 12) + '' : hms[0];
			return new Date(ymd + hms.join(':'))
		}
	}
}
export function formatTime(date, v) {
	let _v = v ? v : '-'
	if (!date.getFullYear) {
		date = new Date(date);
	}
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	const hour = date.getHours();
	const minute = date.getMinutes();
	const second = date.getSeconds();

	const t1 = [year, month, day].map(formatNumber).join(_v);
	const t2 = [hour, minute, second].map(formatNumber).join(':');

	return `${t1} ${t2}`;
}

export function formatDate(date) {
	if (typeof date !== 'object') {
		date = new Date(date);
	}
	const year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	if (month < 10) {
		month = '0' + month;
	}
	if (day < 10) {
		day = '0' + day;
	}
	const t1 = [year, month, day].map(formatNumber).join('-')
	return `${t1}`;
}


export function formatDateYYYYMM(date) {
	if (typeof date !== 'object') {
		date = new Date(date);
	}
	const year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	if (month < 10) {
		month = '0' + month;
	}
	if (day < 10) {
		day = '0' + day;
	}
	const t2 = [year, month].map(formatNumber).join('')
	return `${t2}`;
}

export function formatDateYYYY(date) {
	if (typeof date !== 'object') {
		date = new Date(date);
	}
	const year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	if (month < 10) {
		month = '0' + month;
	}
	if (day < 10) {
		day = '0' + day;
	}
	const t3 = [year].map(formatNumber).join('')
	return `${t3}`;
}
/*
 * 获取倒计时
 * */
export function getTime(smallTime, bigTime) {
	let date1 = new Date(smallTime);
	let date2 = new Date(bigTime);

	let s1 = date1.getTime(),
		s2 = date2.getTime();
	let total = (s2 - s1) / 1000;
	let day = total / (24 * 60 * 60); //计算整数天数
	let afterDay = total - day * 24 * 60 * 60; //取得算出天数后剩余的秒数
	let hour = afterDay / (60 * 60); //计算整数小时数
	let afterHour = total - day * 24 * 60 * 60 - hour * 60 * 60; //取得算出小时数后剩余的秒数
	let min = afterHour / 60; //计算整数分
	let afterMin = total - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60; //取得算出分后剩余的秒数
	return {
		day: day > 0 ? day : 0,
		hour: hour > 0 ? hour : 0,
		min: min > 0 ? min : 0,
		afterMin: afterMin > 0 ? afterMin : 0,
	};
}

export function getFileType(fileName) {
	const imgReg = /\.jpg$|\.jpeg$|\.gif$|\.png$/i;
	const videoReg = /\.mp4$|\.rmvb$|\.flv$|\.avi$|\.mpeg$/i;
	const audioReg = /\.mp3$|\.cda$/i;

	let result = '';

	if (imgReg.test(fileName)) {
		result = '图片';
	} else if (videoReg.test(fileName)) {
		result = '视频';
	} else if (audioReg.test(fileName)) {
		result = '音频';
	} else {
		result = '文件';
	}

	return result;
}

function throttol(fn, threshold) {
	let timer;

	return function() {
		let args = [...arguments];
		if (!timer) {
			timer = setTimeout(() => {
				fn.apply(this, args);
				timer = null;
			}, threshold || 200);
		}
	};
}

export const log = throttol(() => {}, 2000);

// 下载表格数据
export async function downloadData(tHeader, data, name) {
	let rData = data;
	let downList = store.state.downListByRple
	if (Array.isArray(downList)) {
		downList.some((item) => {
			if (
				item.pageUri &&
				(item.pageUri.includes(router.history.current.fullPath) ||
					router.history.current.fullPath.includes(item.pageUri))
			) {
				rData = rData.splice(0, item.rowLimited);
			}
		});
		const url = '/service-enterprise/finance/getExport'
		// let res = await post({
		// 	url,
		// 	params: {
		// 		data: rData,
		// 		excelName: name,
		// 		head: tHeader
		// 	}
		// })
		// window.open(res)
		post({
			url,
			params: {
				data: rData,
				excelName: name,
				head: tHeader
			}
		}).then(res => {
			window.open(res)
		}).catch(err => {
			// this.$msg.error({
			// 	text: err.data
			// })
		})
		// .then(res => {
		//   window.open(res)
		// }).catch(err => {
		//   return false
		// })
		// require.ensure([], () => {
		//     const { export_json_to_excel } = require('../assets/7Export2Excel'); //这个地址和页面的位置相关，这个地址是Export2Excel.js相对于页面的相对位置
		//     export_json_to_excel(tHeader, rData, name); //列表excel  这个是导出表单的名称
		// });
		return true

	} else {
		this.$msg.error({
			type: 'noAuthority',
			code: 1006
		})
		return false

	}

}
// 下载表格数据
export async function downloadTableData(tHeader, data, name) {
	let rData = data;
	let downList = store.state.downListByRple
	if (Array.isArray(downList)) {
		downList.some((item) => {
			if (
				item.pageUri &&
				(item.pageUri.includes(router.history.current.fullPath) ||
					router.history.current.fullPath.includes(item.pageUri))
			) {
				rData = rData.splice(0, item.rowLimited);
			}
		});
		const url = '/service-insight/macroeconomy/getExport'
		// let res = await post({
		// 	url,
		// 	params: {
		// 		data: rData,
		// 		excelName: name,
		// 		head: tHeader
		// 	}
		// })
		// window.open(res)
		post({
			url,
			params: {
				data: rData,
				excelName: name,
				head: tHeader
			}
		}).then(res => {
			window.open(res)
		}).catch(err => {
			this.$msg.error({
				text: err.data
			})
		})
		// .then(res => {
		//   window.open(res)
		// }).catch(err => {
		//   return false
		// })
		// require.ensure([], () => {
		//     const { export_json_to_excel } = require('../assets/7Export2Excel'); //这个地址和页面的位置相关，这个地址是Export2Excel.js相对于页面的相对位置
		//     export_json_to_excel(tHeader, rData, name); //列表excel  这个是导出表单的名称
		// });
		return true

	} else {
		this.$msg.error({
			type: 'noAuthority',
			code: 1006
		})
		return false

	}

}

export function formatJson(filterVal, jsonData) {
	return jsonData.map((v) => filterVal.map((j) => v[j]));
}

export function checkDate(s, e) {
	if (!s || !e) {
		return true;
	}
	// eslint-disable-next-line no-useless-escape
	const sDate = s.replace('/-/g', '/');
	// eslint-disable-next-line no-useless-escape
	const eDate = e.replace('/-/g', '/');
	if (sDate > eDate) {
		return false;
	}
	return true;
}

// 保留两位小数
export function returnFloat(number, bitNum) {
	const f_x = parseFloat(number);
	if (isNaN(f_x)) {
		return 0;
	}
	let s_x = number.toString();
	let pos_decimal = s_x.indexOf('.');
	//没有小数点的加上小数点
	if (pos_decimal < 0) {
		pos_decimal = s_x.length;
		if (bitNum != 0) {
			s_x += '.';
		} else {
			//没有小数点还要保留0位小数
			return s_x;
		}
	}
	if (s_x.length <= pos_decimal + bitNum) {
		while (s_x.length <= pos_decimal + bitNum) {
			s_x += '0';
		}
	} else {
		s_x = s_x.substring(0, pos_decimal + bitNum + 1);
	}
	return s_x;
}

// 科学计数法转换
export function fixNumber(x) {
	if (Math.abs(x) < 1.0) {
		let e = parseInt(x.toString().split('e-')[1]);
		if (e) {
			x *= Math.pow(10, e - 1);
			x = '0.' + new Array(e).join('0') + x.toString().substring(2);
		}
	} else {
		let e = parseInt(x.toString().split('+')[1]);
		if (e > 20) {
			e -= 20;
			x /= Math.pow(10, e);
			x += new Array(e + 1).join('0');
		}
	}
	return parseInt(x);
}


//优雅解决async/await的catch
//使用：const [err, data] = await awaitWrap(asyncFn())
export function awaitWrap(promise) {
	return promise
		.then(data => [null, data])
		.catch(err => [err, null])
}

//防抖
export const debounce = (fn, delay) => {
	let timer = null //借助闭包
	return function() {
		if (timer) {
			clearTimeout(timer)
		}
		timer = setTimeout(fn, delay) // 简化写法
	}
}

const os = require('os');
export function getIPAdress() {
	var interfaces = os.networkInterfaces();
	for (var devName in interfaces) {
		var iface = interfaces[devName];
		for (var i = 0; i < iface.length; i++) {
			var alias = iface[i];
			if (alias.family === 'IPv4' && alias.address !== '127.0.0.1' && !alias.internal) {
				return alias.address;
			}
		}
	}
}

/**
 * @desc 函数节流
 * @param func 函数
 * @param wait 延迟执行毫秒数
 * @param type 1 表时间戳版，2 表定时器版
 */
export function throttle(func, wait, type) {
	let previous = 0;
	let timeout;
	return function() {
		let context = this;
		let args = arguments;
		if (type === 1) {
			let now = Date.now();
			if (now - previous > wait) {
				func.apply(context, args);
				previous = now;
			}
		} else if (type === 2) {
			if (!timeout) {
				timeout = setTimeout(() => {
					timeout = null;
					func.apply(context, args)
				}, wait)
			}
		}
	}
}
/**
 * @desc 函数防抖
 * @param func 函数
 * @param wait 延迟执行毫秒数
 * @param immediate true 表立即执行，false 表非立即执行
 */
export function _debounce(func, wait, immediate) {
	let timeout;
	return function() {
		let context = this;
		let args = arguments;
		if (timeout) clearTimeout(timeout);
		if (immediate) {
			var callNow = !timeout;
			timeout = setTimeout(() => {
				timeout = null;
			}, wait)
			if (callNow) func.apply(context, args)
		} else {
			timeout = setTimeout(function() {
				func.apply(context, args)
			}, wait);
		}
	}
}

export function toCurrency  (total) {
  //字符转数字
	// total = Number(total);
    if (total.toString().indexOf(",") > 0) {
             return total;
         }
         if (total.toString().indexOf(".") > 0) {

             if (total.toString().substr((total.toString().indexOf(".") + 1)).length > 4) {
                 total = parseFloat(total).toFixed(4);
             }

             let pre = total.toString().substr(0, total.toString().indexOf("."));
             let behind = total.toString().substr((total.toString().indexOf(".")));
             if (pre.length > 3) {
                 let m = Math.floor(pre.length / 3);
                 let n = pre.length % 3;
                 let ns = "";
                 //位数是3的倍数
                 if (n == 0) {
                     for (let l = 0; l < m; l++) {
                         if (l < m - 1) {
                             ns += pre.substr(3 * l, 3) + ",";
                         }
                         else {
                             ns += pre.substr(3 * l, 3);
                         }
                     }
                 }
                 //位数不能被3整除
                 else {
                     ns = pre.substr(0, n) + ",";
                     for (let i = 0; i < m; i++) {
                         if (i < m - 1) {
                             ns += pre.substr(3 * i + n, 3) + ",";
                         }
                         else {
                             ns += pre.substr(3 * i + n, 3);
                         }
                     }
                 }
                 total = ns + behind;

             }
         }
         else {
             let newtotal = total.toString();
             if (newtotal.length > 3) {
                 let m = Math.floor(newtotal.length / 3);
                 let n = newtotal.length % 3;
                 let ns = "";
                 //位数是3的倍数
                 if (n == 0) {
                     for (let l = 0; l < m; l++) {
                         if (l < m - 1) {
                             ns += newtotal.substr(3 * l, 3) + ",";
                         }
                         else {
                             ns += newtotal.substr(3 * l, 3);
                         }
                     }
                 }
                 //位数不能被3整除
                 else {
                     ns = newtotal.substr(0, n) + ",";
                     for (let i = 0; i < m; i++) {
                         if (i < m - 1) {
                             ns += newtotal.substr(3 * i + n, 3) + ",";
                         }
                         else {
                             ns += newtotal.substr(3 * i + n, 3);
                         }
                     }
                 }
                 total = ns;
             }
         }
         return total;
}
