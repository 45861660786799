import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/login.vue';
import store from '../store';
import nprogress from 'nprogress'; //虚拟进度条
import 'nprogress/nprogress.css';//进度条样式
import {
    constantRouters
} from './constantRouters'; //永久路由
import asyncRouters from './asyncRouters'; //异步路由
import {
    heartCount,
    pagelog
} from '../utils/dataReport';//埋点
import {
    Message,
    Notice
} from 'view-design';
import noticeInfo from '../data/noticeInfo'
import toase from '../utils/toase'

import _this from '../main.js'


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [...constantRouters, ...asyncRouters];

const router = new VueRouter({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {
                x: 0,
                y: 0
            };
        }
    },
});
router.beforeEach((to, from, next) => {
    nprogress.start();
    Notice.destroy()
    if (to.meta.title) {
        document.title = to.meta.title;
    }

    // store.commit('changeState', {
    //     prop: 'routerActive',
    //     value: to.path.split('/')[1] || ''
    // });
    if (!(store.state.token && store.state.userId) && to.name !== 'm_index') {
        if (to.name == 'login') {
            next();
        } else {
            next();
        }
    } else {
        if (to.name == 'login') {
            next({
                path: '/'
            });
        } else {
            // 根据后端返回的路由权限id来控制可以进入的路由
            const {
                resourceAuthIds
            } = store.state;
            const {
                authId
            } = to.meta || [];
            // if (!authId || authId === 'common' || resourceAuthIds.some((item) => authId.includes(item))) {
            if (
                // process.env.NODE_ENV === 'development' ||
                !authId ||
                authId === 'common' ||
                resourceAuthIds.some((item) => authId.includes(item))
            ) {
                next();
            } else {
                //把前一次跳转的的目的路由存储到vuex ，从而在本次跳转时限制提醒
                if (store.state.toInfo == to.meta.authId) {
                    nprogress.done();
                    return
                }
                //存储到vuex
                store.commit('changeState', {
                    prop: 'toInfo',
                    value: to.meta.authId
                })
                //5s后重置目标路由，可以弹出提醒
                setTimeout(() => {
                    store.commit('changeState', {
                        prop: 'toInfo',
                        value: null
                    })
                }, 5000);

                let reportAuth = ['common']
                let modelAuth = [3, 14, 15, 28, 38, 64, 65, 77, 83]
                let showModal1 = [67, 68]
                let showModal2 = [70, 72]
                //当一键看项目没有权限时，显示一键看项目的弹窗
                // if (authId.some((item) => showModal1.includes(item))) {

                //     store.commit('changeState', {
                //         prop: 'showYijianModal',
                //         value: true
                //     })
                //     nprogress.done();
                //     return
                // }
                //当押品管理没有权限时，显示押品管理的弹窗
                // if (authId.some((item) => showModal2.includes(item))) {
                //     store.commit('changeState', {
                //         prop: 'showYapinModal',
                //         value: true
                //     })
                //     nprogress.done();
                //     return
                // }
                if (authId.includes(9)) {
                    //土地跳转权限重定向
                    next({
                        path: '/Insight/PlaceSearch',
                    })

                    nprogress.done();
                    return;

                }
                if (authId.includes(30)) {
                    //城市跳转权限重定向
                    next({
                        path: '/Insight/City/search/buildingSearch',
                    })
                    nprogress.done();
                    return;

                }
                if (authId.some((item) => reportAuth.includes(item))) {
                    _this.$msg.error({
                        type: 'noAuthority',
                        code: 1001,
                    })
                } else if (authId.some((item) => modelAuth.includes(item))) {

                    _this.$msg.error({
                        type: 'noAuthority',
                        code: 1004
                    })
                } else {
                    _this.$msg.error({
                        type: 'noAuthority',
                        code: 1002
                    })

                }
                nprogress.done();
                next({
                    path: from.path,
                });

                // setTimeout(() => {
                //     router.go(0);
                // }, 500);
                return;
            }
            if (store.state.pageId == to.meta.pageId) {
                nprogress.done();
                return
            }
            //存储到vuex
            store.commit('changeState', {
                prop: 'pageId',
                value: to.meta.pageId ? to.meta.pageId : sessionStorage.getItem('pageId') ? sessionStorage.getItem('pageId') : ''
            })
            //5s后重置目标路由，可以弹出提醒
            setTimeout(() => {
                store.commit('changeState', {
                    prop: 'pageId',
                    value: to.meta.pageId ? to.meta.pageId : sessionStorage.getItem('pageId') ? sessionStorage.getItem('pageId') : ''
                })
            }, 5000);

            next();
            //心跳上报
            heartCount(store);
        }


    }
});
router.afterEach(() => {
    nprogress.done();
});
export default router;