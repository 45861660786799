import { post, get } from '@/utils/http'

// 朝向
export function cx(params) {
    return get({ url: '/service-insight/dim_city/cx', params })
}
// 房龄
export function age(params) {
    return get({ url: '/service-insight/dim_city/age', params })
}
// 户型
export function apartment(params) {
    return get({ url: '/service-insight/dim_city/apartment', params })
}
// 城市维度
export function cityArea(params) {
    return get({ url: '/service-insight/dim_city/cityArea', params })
}
export function cityPro(params) {
    return get({ url: '/service-insight/dim_city/cityPro', params })
}
//最新楼价
export function newJg(params) {
    return get({ url: '/service-insight/dim_city/newJg', params })
}



// 行政区维度
export function cityDistrict(params) {
    return get({ url: '/service-insight/dim_city/cityDistrict', params })
}
// 板块维度
export function cityPlate(params) {
    return get({ url: '/service-insight/dim_city/cityPlate', params })
}
// 统计详细类型维度
export function detailType(params) {
    return get({ url: '/service-insight/dim_city/detailType', params })
}
// 楼层维度
export function floor(params) {
    return get({ url: '/service-insight/dim_city/floor', params })
}
// 楼盘维度
export function houses(params) {
    return post({ url: '/service-insight/dim_city/houses', params })
}
// 建筑类型维度
export function jzlx(params) {
    return get({ url: '/service-insight/dim_city/jzlx', params })
}
// 开发商维度
export function kfs(params) {
    return get({ url: '/service-insight/dim_city/kfs', params })
}
// 租赁模式维度
export function leasingMode(params) {
    return get({ url: '/service-insight/dim_city/leasingMode', params })
}
export function cityDistrictOrChildren(params) {
    return get({ url: '/service-insight/dim_city/cityDistrictOrChildren', params })
}
export function allCity(params) {
    return get({ url: '/service-insight/dim_city/allCity', params })
}

// 物业类型(一级)维度
export function propertyType(params) {
    return get({ url: '/service-insight/dim_city/propertyType', params })
}
// 物业类型(二级)维度
export function propertyType2(params) {
    return get({ url: '/service-insight/dim_city/propertyType2', params })
}
// 装修维度
export function renvation(params) {
    return get({ url: '/service-insight/dim_city/renvation', params })
}
// 销售状态维度
export function saleStatus(params) {
    return get({ url: '/service-insight/dim_city/saleStatus', params })
}
// 统计面积维度
export function statAcreage(params) {
    return get({ url: '/service-insight/dim_city/statAcreage', params })
}
// 统计价格维度
export function statPrice(params) {
    return get({ url: '/service-insight/dim_city/statPrice', params })
}
// 统计物业类型维度
export function statPropertyType(params) {
    return get({ url: '/service-insight/dim_city/statPropertyType', params })
}
// 时间维度(包含日,周)
export function statTime(params) {
    return get({ url: '/service-insight/dim_city/statTime', params })
}
// 时间维度(不包含日,周)
export function statTimeNoDay(params) {
    return get({ url: '/service-insight/dim_city/statTimeNoDay', params })
}
//租金
export function rent(params) {
    return get({ url: '/service-insight/dim_city/rent', params })
}
//总价
export function zj(params) {
    return get({ url: '/service-insight/dim_city/zj', params })
}

// 租赁详情
export function lease_detail(params) {
    return get({ url: '/service-insight/query_city/lease_detail', params })
}
// 一手房详情
export function new_house_detail(params) {
    return get({ url: '/service-insight/query_city/new_house_detail', params })
}
// 二手房详情
export function second_house_detail(params) {
    return get({ url: '/service-insight/query_city/second_house_detail', params })
}

// 城市项目搜索
export function city_new_house(params) {
    return post({ url: '/service-insight/query_city/city_new_house', params })
}
// 租赁搜索
export function lease(params) {
    return post({ url: '/service-insight/query_city/lease', params })
}
// 租赁地图搜索
export function lease_map(params) {
    return post({ url: '/service-insight/query_city/lease_map', params })
}
// 租赁地图搜索+区域
export function lease_qy_map(params) {
    return post({ url: '/service-insight/query_city/lease_qy_map', params })
}
// 一手房搜索
export function new_house(params) {
    return post({ url: '/service-insight/query_city/new_house', params })
}
// 一手房地图搜索
export function new_house_map(params) {
    return post({ url: '/service-insight/query_city/new_house_map', params })
}
// 二手房搜索
export function second_house(params) {
    return post({ url: '/service-insight/query_city/second_house', params })
}
// 二手房地图搜索
export function second_house_map(params) {
    return post({ url: '/service-insight/query_city/second_house_map', params })
}
// 二手房地图搜索+区域
export function second_house_qy_map(params) {
    return post({ url: '/service-insight/query_city/second_house_qy_map', params })
}

// 省市一手房统计
export function stat_pro_new(params) {
    return post({ url: '/service-insight/city_statistics_pro_new/stat_pro_new', params })
}
// 省市二手房统计
export function stat_pro_two(params) {
    return post({ url: '/service-insight/city_statistics_pro_new/stat_pro_two', params })
}
// 省市租房统计
export function stat_pro_rent(params) {
    return post({ url: '/service-insight/city_statistics_pro_new/stat_pro_rent', params })
}

// 获取楼盘统计成交记录
export function get_bargain_data(params) {
    return post({ url: '/service-insight/city_statistics/get_bargain_data', params })
}
// 获取楼盘统计一手房数据
export function get_new_data(params) {
    return post({ url: '/service-insight/city_statistics/get_new_data', params })
}
// 获取楼盘统计二手房数据
export function get_two_data(params) {
    return post({ url: '/service-insight/city_statistics/get_two_data', params })
}
// 获取楼盘统计租赁数据
export function get_rent_data(params) {
    return post({ url: '/service-insight/city_statistics/get_rent_data', params })
}

// 行政区统计一手房统计
export function stat_dis_new(params) {
    return post({ url: '/service-insight/city_statistics_dis_new/stat_dis_new', params })
}
// 行政区统计二手房统计
export function stat_dis_two(params) {
    return post({ url: '/service-insight/city_statistics_dis_new/stat_dis_two', params })
}
// 行政区统计租房统计
export function stat_dis_rent(params) {
    return post({ url: '/service-insight/city_statistics_dis_new/stat_dis_rent', params })
}

// 获取开发商统计
export function get_dev_data(params) {
    return post({ url: '/service-insight/city_statistics_developers/get_dev_data', params })
}

//楼盘预售
export function lp_presale(params) {
    return get({ url: '/service-insight/query_city/lp_presale', params })
}

//楼盘动态
export function lp_dynamic(params) {
    return get({ url: '/service-insight/query_city/lp_dynamic', params })
}

//楼盘价格
export function lp_price(params) {
    return get({ url: '/service-insight/query_city/lp_price', params })
}
//成交信息
export function lp_transaction_info(params) {
    return get({ url: '/service-insight/query_city/lp_transaction_info', params })
}


// 推送开盘监测
export function push_kpjc(params) {
    return post({ url: '/service-insight/query_city/push_kpjc', params })
}
// 推送热门项目
export function push_rmxm(params) {
    return post({ url: '/service-insight/query_city/push_rmxm', params })
}
//开盘检测
export function open_monitor(params) {
    return post({ url: '/service-insight/query_city/open_monitor', params })
}
// 热门项目
export function hot_project(params) {
    return post({ url: '/service-insight/query_city/hot_project', params })
}

export function dimHouseDimCompany(id) {
    return post({ url: '/service-insight/dim_city/dimHouseDimCompany?csId='+id, id })
}

export function dimHouseDimCompanyJt(id) {
    return post({ url: '/service-insight/dim_city/dimHouseDimCompanyJt?csId='+id, id })
}





//获取维度详情列表
export function listByCategoryCode(params) {
    return get({ url: '/service-insight/dataCityStatistics/dim/listByCategoryCode', params })
}
//获取城市映射
export function cityMap(params) {
    return get({ url: '/service-insight/dataCityStatistics/dim/cityMap', params })
}

//获取区域映射
export function districtMap(params) {
    return get({ url: '/service-insight/dataCityStatistics/dim/districtMap', params })
}

//获取楼盘列表
export function lpMap(params) {
    return get({ url: '/service-insight/dataCityStatistics/dim/lpMap', params })
}

//获取开发商列表
export function developerMap(params) {
    return get({ url: '/service-insight/dataCityStatistics/dim/developerMap', params })
}


//查询
export function statistics_page(params) {
    return post({ url: '/service-insight/dataCityStatistics/statistics/page', params })
}

//每日成交
export function dailyTransaction(params) {
    return post({ url: '/service-insight/dataCityStatistics/statistics/dailyTransaction', params })
}

//获取城市统计数据说明

export function getDataCityStatisticsAlertMessagesConfig(params) {
    return get({ url:'/service-insight/dataCityStatistics/get_alert_message'})
}
//获取城市统计数据说明

export function getAuthCity(params) {
    return get({ url:'/service-evaluation/intelligentEvaluation/v3/getAuthCity',params})
}

//获取授权城市-新
export function getAuthCityNew(params) {
    return get({ url:'/service-evaluation/intelligentEvaluation/v3/platform/getAuthCity',params})
}




