<template>
  <transition>
    <div v-show="show" :style="positionStyle" :class="`message-container message-container-${type}`">
      <div>{{ msg }}</div>
    </div>
  </transition>
</template>

<script >
export default {
  name: 'MessageNew',
  props: {
    type: {
      type: String,
      default: 'success'
    },
    msg: {
      type: String
    },
    show: {
      type: Boolean
    },
    verticalOffset:{
    type: Number,
    default: 40
    },
  },
  data() {
    return {
      // verticalOffset:20
    };
  },
  computed: {
          positionStyle() {
        return {
          'top': `${ this.verticalOffset }px`
        };
  },
  },
  mounted() {

  },
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: all 1s;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

.message-container {
  position: fixed;
  text-align: center;
  top: 20px;
  left: 50%;
  height: 32px;
  transform: translateX(-50%);
  min-width: 300px;
  padding: 5px 20px;
  border-radius: 4px;
  line-height: 20px;
  z-index: 9999;
  color: #ffffff;
  background-color: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  font-family: PingFangSCMedium-Medium;
font-size: 16px;
font-weight: normal;
}

.message-container-success {
  background-color: #25CB64;

}

.message-container-error {
  background-color: #FFB330;
}
</style>
