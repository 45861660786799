import {
	enterprise_gsxxType
} from '@/api/reHouseEnterprise'

const reHouseEnterprise = {
	namespaced: true,
	state: {
		selectedCompantName: '万科企业股份有限公司',
		selectedCompantId: 107,
		selectedCompantGpmc: '000002',
		componeyData: {
			// sszx: '深圳地产资信10强',
			ssgs: '上市公司',
			ssqk: '深交所',
		},
		selectedIndex: [],
		selectedIndexHaslabel: [],
		searchForm:{},
		financialData:{},
		chartData:{},
		formType:1,
		raceData:[],
		raceChartData:{},
		raceChartSearchData:{},
		raceChartIndexList:[],
		raceChartBarIndexList:[],
		raceChartLineIndexList:[],
		spinShow:false
	},
	mutations: {
		changeState(state, {
			prop,
			value
		}) {
			state[prop] = value;
		},
		changeIndex(state,obj) {
			let index = state.selectedIndex.find(item => item.tableName == obj.tableName)
			if(index){
				state.selectedIndex.forEach(item => {
					if(item.tableName == obj.tableName){
						item.list = obj.list
					}
				})
			}else{
				state.selectedIndex.push(obj)
			}
		},
		changeIndexHaslabel(state,obj) {
			let index = state.selectedIndexHaslabel.find(item => item.tableName == obj.tableName)
			if(index){
				state.selectedIndexHaslabel.forEach(item => {
					if(item.tableName == obj.tableName){
						item.list = obj.list
					}
				})
			}else{
				state.selectedIndexHaslabel.push(obj)
			}
		},
		setSearchForm(state,obj){
			state.searchForm = obj
		},
		setFinancialData(state,obj){
			state.financialData = obj
		},
	},
	actions: {
		getComponeyData({
			state,
			commit
		}, value) {
			commit("changeState", {
				prop: "selectedCompantName",
				value: value.name,
			});
			commit("changeState", {
				prop: "selectedCompantId",
				value: value.id,
			});
			//有gpmc时保存gpmc，没有时保存name
			commit("changeState", {
				prop: "selectedCompantGpmc",
				value: value.gpmc ? value.gpmc : value.name
			});
			enterprise_gsxxType({
				code: state.selectedCompantGpmc
			}).then(data => {
				commit("changeState", {
					prop: "componeyData",
					value: data,
				});
			})
		}
	}
}
export default reHouseEnterprise;
