import {
	post,
	get
} from '@/utils/http'



export function fin_dimClassify(params) {
	return get({
		url: '/service-insight/finance-report/data/fin/dimClassify',
		params
	})
}


export function fin_entType(params) {
	return get({
		url: '/service-insight/finance-report/data/fin/entType',
		params
	})
}


//企业分类
export function enterprise_dim_dimClassify(params) {
	return get({
		url: '/service-insight/enterprise_dim/dimClassify',
		params
	})
}

//企业类型-财务
export function enterprise_gsxxType(params) {
	return post({
		url: '/service-insight/enterprise/gsxxType',
		params
	})
}



//获取指标
export function fin_index(params) {
	return get({
		url: '/service-insight/finance-report/data/fin/index',
		params
	})
}

//获取报表
export function fin_ent_list(params) {
	return post({
		url: '/service-insight/finance-report/data/fin/ent/list',
		params
	})
}

//获取报表-图
export function fin_ent_view(params) {
	return post({
		url: '/service-insight/finance-report/data/fin/ent/view',
		params
	})
}



