import { post, get } from '@/utils/http';
import store from '@/store/index.js';
import axios from 'axios'; // 引入axios

// 心跳数据上报
export function heartCountApi(params) {
    return post({ url: '/service-insight/data_sys_count/heart_count', params });
}

// 页面访问上报
export function pageCountApi(params,close) {
    if(close){
        let baseurl = '';
        if (process.env.NODE_ENV == 'development') {
            // http://192.168.3.163:8007
            baseurl = 'https://dev.api.zstnb.cn:18081';//开发库
            // baseurl = 'https://api.zstnb.cn:18081';//测试库

        } else if (process.env.NODE_ENV == 'test') {
            baseurl = 'https://api.zstnb.cn:18081';
        } else if (process.env.NODE_ENV == 'production') {
            baseurl = 'https://api.zhongsituo.com';
        }

        // let blob = new Blob([JSON.stringify(params)], {type : 'Authorization:'+ store.state.token});
        // // window.navigator.sendBeacon(baseurl+"/service-insight/data_sys_count/page_count", JSON.stringify(params))
        navigator.sendBeacon(baseurl+"/service-insight/data_sys_count/page_count", JSON.stringify(params))

    }else{
    return post({ url: '/service-insight/data_sys_count/page_count', params });}
}

// 按钮点击上报
export function btnCountApi(params) {
    return post({ url: '/service-insight/data_sys_count/button_count', params });
}

//按钮上报
export function buttonSysCountApi(params){
    return post({url:'/service-insight/data_sys_count/button_sys_count',params})
}
