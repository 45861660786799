import { post, get } from "@/utils/http";

// 查询用户信息
export function getUserInfo(params) {
    return post({ url: "/service-insight/my_info/nom_info_page", params });
}
// 查询系统信息
export function getSysInfo(params) {
    return post({ url: "/service-insight/my_sys_info/sys_info_page", params });
}

// 客户未读消息
export function getUnreadInfo(params) {
    return get({ url: "/service-insight/my_info/unread_info", params });
}
// 客户未读消息
export function getSysUnreadInfo(params) {
    return get({ url: "/service-insight/my_sys_info/unread_info", params });
}

// 客户未读消息
export function lookInfo(params) {
    return post({ url: "/service-insight/my_info/feedback_reply", params });
}
// 客户未读消息
export function lookSysInfo(params) {
    return get({ url: "/service-insight/my_sys_info/look_info", params });
}
// 客户未读消息
export function lookInfoById(params) {
    return get({ url: "/service-insight/my_info/info_by_id", params });
}

// 消息
export function getInfoById(params) {
    return get({ url: "/service-insight/my_info/get_info_by_id", params });
}



