import publicSentiment from '@/router/dataInsight/sentiment/publicSentiment';
import research from '@/router/research/research';
import customerOrder from '@/router/customerorder/customerOrder';
import dataInsight from "@/router/dataInsight/dataInsights";
import empowerment from "@/router/empowerment/empowerment";
import personalCenter from "@/router/personalcenter/personalCenter";

 const asyncRouters = [
	// 深度研究
	research,
	// 定制化
	//customization,
	//新定制化
	customerOrder,
	// 数据洞察
	dataInsight,
	// 大数据赋能
	empowerment,
	// 个人中心
	personalCenter,
publicSentiment
]
export default asyncRouters;
