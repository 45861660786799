import { getAuthCity } from '@/api/city';
import cityList from './cityList'
  let AllcityList = cityList.state.cityList

// let arrlist = [{
//         provinceCode: "44",
//         cityCode: "440100",
//         provinceShortName: "广东",
//         cityShortName: "广州",
//         provinceFullName: "广东省",
//         cityFullName: "广州市",
//         authority: true,
//     }, {
//         provinceCode: "44",
//         cityCode: "440600",
//         provinceShortName: "广东",
//         cityShortName: "佛山",
//         provinceFullName: "广东省",
//         cityFullName: "佛山市",
//         authority: true,

//     },
//     {
//         provinceCode: "440000000000",
//         cityCode: "441900000000",
//         provinceShortName: "广东",
//         cityShortName: "东莞",
//         provinceFullName: "广东省",
//         cityFullName: "东莞市",
//         authority: false,

//     }
// ]

// cityAuthority: "440100,440600"
function filterCities(cityAuthority) { //通过cityAuthority过滤arrlist
    let cityCodes = cityAuthority.split(",");
     let AllcityList = cityList.state.cityList
    let filteredArr = AllcityList.filter(function(obj) {
      let cityCode = String(obj.cityCode)
        return cityCodes.includes(cityCode);
    });
    //   filteredArr = AllcityList
    return filteredArr;
}

const userAutoCityList = {
    state: {
        cityList: []
    },
    mutations: {
        getUserCityList(state, cityList) {
            state.cityList = cityList
        }
    },
    actions: {
        getUserCityList({ commit }, cityList) {
            let arr = cityList
            // arr = filterCities(cityList)
            commit('getUserCityList', arr)
            localStorage.setItem('userAutoCityList', JSON.stringify(arr))

            // commit('getUserCityList',cityList)
        }
    }
}
export default userAutoCityList;