import {
    Notice
} from 'view-design';
import noticeInfo from '../data/noticeInfo'
export default function toase(options) {
    let params = {
        type: String(),
        code: Number(),
        from: 'info',
        to: '',
        title: '',
        timeout: 1,
        text: null,
        cb: Function()
    }
    Object.keys(options).forEach(key => {
        params[key] = options[key]
    })
    //当提醒文字为“remind”时，把显示时间改为10s
    if (params.type == 'remind' ||params.type == 'noAuthority') {
        params.timeout = 30
    }
    Notice.destroy();
    let infoArr = []
    infoArr = options.code ? noticeInfo[params.type][params.code] : typeof (params.text) == 'string' ? params.text.split('') : params.text
    Notice[params.from]({
        title: params.title,
        desc:infoArr,
        closable: true,
        // render: (h) => {
        //     return h("div", infoArr.map(i => {
        //         //文字前标*代表文字颜色需要变红，文字前带^表示为跳转连接，需要加红且跳转
        //         return i[0] == '*' ? h("span", {
        //             style: {
        //                 'font-weight': '700',
        //                 'font-size': '14px',
        //             }
        //         }, i.replace("*", "")) : i[0] == '^' ? h("a", {
        //             style: {
        //                 'color': "#ffd130",
        //                 'font-size': '14px',
        //                 'font-weight': '700',
        //                 'text-decoration': 'underline'
        //             },
        //             on: {
        //                 click: () => {
        //                     if (options.to) {
        //                         this.$router.push(params.to);
        //                     } else {
        //                         params.cb()
        //                     }
        //                 },
        //             },
        //         }, i.replace("^", "")) : i[0] == '~' ? h('br') : i
        //     }));
        // },
        duration: params.timeout,
    });
}