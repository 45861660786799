const personalCenter ={
    path: '/PersonalCenter',
        name: 'PersonalCenter',
    component: () => import('../../views/PersonalCenter.vue'),
    redirect: '/PersonalCenter/MyAccount',
    meta: {
    title: '中思拓-用户中心',
        authId: [92],
        function:'个人中心'
},
    children: [{
        path: 'MyAccount',
        name: '我的账户',
        component: () => import('../../views/personalCenter/MyAccount.vue'),
        meta: {
            authId: [93],
            function:'个人中心'
        }
    },
        {
            path: 'InfoManage',
            name: '信息管理',
            component: () => import('../../views/personalCenter/InfoManage.vue'),
            meta: {
                authId: [94],
                function:'个人中心'
            }
        },
        // {
        //     path: 'MyPush',
        //     name: '我的推送',
        //     component: () => import('../../views/personalCenter/MyPush.vue'),
        //     meta: {
        //         authId: [95],
        //         function:'个人中心'
        //     }
        // },
        {
            path: 'AccountManage',
            name: '账号管理',
            component: () => import('../../views/personalCenter/AccountManage.vue'),
            meta: {
                authId: [96],
                function:'个人中心'
            }
        },
        {
            path: 'RoleManage',
            name: '角色管理',
            component: () => import('../../views/personalCenter/RoleManage.vue'),
            meta: {
                authId: [97],
                function:'个人中心'
            }
        }
    ]
}
export default personalCenter;