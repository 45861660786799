import Vue from "vue";
import Vuex from "vuex";
import modal from "./modules/modal";
import city from "./modules/city";
import city2 from "./modules/city2";
import project from "./modules/projectAnalysis";
import personal from "./modules/personal";
import dataReport from "./modules/dataReport";
import customization from "./modules/customization";
import permission from "./modules/permission";
import reHouseEnterprise from "./modules/reHouseEnterprise";
import cityList from "./modules/cityList";
import userAutoCityList from "./modules/userAutoCityList";

import {
    login
} from "@/api/user/user";
import {
    getEnterprise,
    getSellWay,
    getDealState,
    getLandUse,
    getArea,
    getCity,
    getTime,
    getLandSingleSelectProvince,
    getZqsc,
    getZjlxZdh,
    getZjlx,
} from "../api/public";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //token: 'Bearer d68e264d-74b6-401a-9180-ea64ad09f400',
        isLogin: localStorage.getItem("isLogin") || false,
        routerActive: "",
        cityObj: {//车故事数据
            provinceCode: "44",
            cityCode: "440100",
            countyCode: "",
            provinceShortName: "广东",
            cityShortName: "广州",
            countyShortName: "",
            provinceFullName: "广东省",
            cityFullName: "广州市",
            countyFullName: ""
        },
        sellWay: [],
        landUse: [],
        allLandUse: [],
        dealState: [],
        enterprise: [{
                id: 1,
                name: "集团",
            },
            {
                id: 2,
                name: "项目公司",
            },
        ],
        areaList: [],
        timeList: [],
        cityFlockList: [],

        landProvinceList: [],
        modalList: [],
        zqscList: [],
        zqlxList: [],
        zqlxListZdh: [],
        saveSearchData: null,
        activeDetail: localStorage.getItem("activeDetail") ?
            JSON.parse(localStorage.getItem("activeDetail")) : {},
        token: localStorage.getItem("token") || "",
        userId: localStorage.getItem("userId") || "",
        roleId: localStorage.getItem("roleId") || "",
        componeyCode: "",
        componeyId: "",
        actionRouter: localStorage.getItem("actionRouter") ?
            JSON.parse(localStorage.getItem("actionRouter")) : [],
        iframeUrl: "",
        showLeft: true,
        fixedLeft: true,
        user: {},
        customerAccountEndDate: 0,
        customization: null,
        // 币种
        bzList: [],

        // 路由菜单权限id
        resourceAuthIds: localStorage.getItem("resourceAuthIds") ?
            JSON.parse(localStorage.getItem("resourceAuthIds")) : [],
        // iframe报表权限id
        reportAuthIds: localStorage.getItem("reportAuthIds") ?
            JSON.parse(localStorage.getItem("reportAuthIds")) : [],
        downListByRple: localStorage.getItem("downListByRple") ?
            JSON.parse(localStorage.getItem("downListByRple")) : [],

        // iframe数据
        iframeData: localStorage.getItem("iframeData") ?
            JSON.parse(localStorage.getItem("iframeData")) : null,
        adviceInfo: 111,
        toInfo: null,
        pageId: null,
        //一键看项目无权限弹窗
        showYijianModal: false,
        //押品管理无权限弹窗
        showYapinModal: false,
        testModal: true,
        openAnnounce: true,
        reAdviceInfo: '',
    },
    mutations: {
        changeState(state, {
            prop,
            value
        }) {
            state[prop] = value;
        },
				changeCityObj(state,data){//改变城市数据
				state.cityObj = data
				},
        setComponeyCode(state, data) {
            state.componeyCode = data;
        },
        setComponeyId(state, data) {
            state.componeyId = data;
        },
        //改变固定导航
        changeFixed(state) {
            state.fixedLeft = !state.fixedLeft;
        },
        changeModulesState(state, {
            modal,
            prop,
            value
        }) {
            state[modal][prop] = value
        }
    },
    actions: {
        loginByUsername({
            commit
        }, userInfo) {
            //暂时改回username，之后需要改回mobilePhone
            const phone = userInfo.phone.trim();
            return new Promise((resolve, reject) => {
                login({
                        phone,
                        password: userInfo.password ? userInfo.password : null,
                        ifVerify: userInfo.ifVerify,
                        verifyCode: userInfo.verifyCode ? userInfo.verifyCode : null,
                        ifDataPlatform: userInfo.ifDataPlatform,
                    })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        initPublicData({
            state,
            commit
        }) {
            getZqsc().then((data) => {
                commit("changeState", {
                    prop: "zqscList",
                    value: data,
                });
            });
            getZjlx().then((data) => {
                commit("changeState", {
                    prop: "zqlxList",
                    value: data,
                });
            });
            getZjlxZdh().then((data) => {
                commit("changeState", {
                    prop: "zqlxListZdh",
                    value: data,
                });
            });

            // getEnterprise().then((data) => {
            //   commit('changeState', {
            //     prop: 'enterprise',
            //     value: data,
            //   });
            // });
            getSellWay().then((data) => {
                state.sellWay = data.filter((item) => {
                    return (
                        item.name != "全部" &&
                        item.name != "公开公告" &&
                        item.name != "招拍挂" &&
                        item.name != "划拨" &&
                        item.name != "协议"
                    );
                });
            });
            getLandUse().then((data) => {
                let arr = data;
                state.allLandUse = arr;
                state.landUse = arr.filter((item) => {
                    return item.id < 5;
                });
            });
            getDealState().then((data) => {
                commit("changeState", {
                    prop: "dealState",
                    value: data,
                });
            });
            getArea().then((data) => {
                commit("changeState", {
                    prop: "areaList",
                    value: data,
                });
            });
            getCity().then((data) => {
                commit("changeState", {
                    prop: "cityFlockList",
                    value: data,
                });
            });
            getTime().then((data) => {
                commit("changeState", {
                    prop: "timeList",
                    value: data,
                });
            });

            // getSingleSelectProvince().then(data => {
            //   state.provinceList = data;
            // });

            getLandSingleSelectProvince().then((data) => {
                state.landProvinceList = data;
            });
        },
    },
    modules: {
        modal,
        city,
        city2,
        project,
        personal,
        dataReport,
        customization,
        permission,
        reHouseEnterprise,
        cityList,
        userAutoCityList
    },
});