// 金融市场
const financialMarket = {
    path: '/Insight/financialMarket',
    name: '金融市场',
    component: () => import('@/views/financial/index.vue'),
    meta: {
        authId: [15],
        function: '金融市场',
        indexName:'金融焦点',
        iconOrImg: 'icon',
        icon: '',
        img:''
    },
    children: [
        {
            path: '/Insight/financialMarket/iframe',
            name: '金融焦点',
            component: () => import('@/views/iframe.vue'),
            meta: {
                id: 108,
                pageId: 108,
                title: '中思拓-数据洞察',
                authId: [108],
                display: true,
                function: '金融市场',
                indexName: '金融焦点',
                iconOrImg: 'icon',
                icon:  'iconfont icon-jinrongjiaodian',
                img:''
            },
            props: (route) => ({
                rPath: route.query.rPath
            })
        },

        {
            path: 'financialProduct',
            name: '金融产品',
            component: () => import('@/views/financial/financialProduct.vue'),
            meta: {
                authId: [16],
                function: '金融市场',
                iconOrImg: 'icon',
                icon:   "iconfont icon-jinrongchanpin",
                img:''
            },
            children: [{
                path: '/Insight/financialMarket/financialProduct/stock',
                // name: '首发｜增发｜配股',
                name: '首发',
                component: () => import('@/views/financial/stock.vue'),
                meta: {
                    pageId: 17,
                    title: '中思拓-股票',
                    authId: [17],
                    function: '金融市场',
                    iconOrImg: 'icon',
                    icon: '',
                    img:''
                }
            },
                {
                    path: '/Insight/financialMarket/financialProduct/stockDetail',
                    name: '股票详情',
                    component: () => import('@/views/financial/stockDetail.vue'),
                    meta: {
                        title: '中思拓-股票详情',
                        authId: [17],
                        function: '金融市场',
                        hidden:true
                    }
                },
                {
                    path: '/Insight/financialMarket/financialProduct/Bond',
                    name: '债券',
                    component: () => import('@/views/financial/Bond.vue'),
                    meta: {
                        authId: [18],
                        function: '金融市场',
                        iconOrImg: 'icon',
                        icon: '',
                        img:''
                    },
                    children: [{
                        path: '/Insight/financialMarket/financialProduct/Bond/domesticDebt',
                        name: '境内债',
                        component: () => import(
                            '@/views/financial/domesticDebt.vue'),
                        // redirect: '/Insight/PlaceMarket',
                        meta: {
                            pageId: 19,
                            title: '中思拓-境内债',
                            authId: [19],
                            function: '金融市场',
                            iconOrImg: 'icon',
                            icon: '',
                            img:''
                        }
                    },
                        {
                            path: '/Insight/financialMarket/financialProduct/Bond/domesticDetail',
                            name: '境内债详情',
                            component: () => import(
                                '@/views/financial/domesticDetail.vue'),
                            // redirect: '/Insight/PlaceMarket',
                            meta: {
                                title: '中思拓-境内债详情',
                                authId: [19],
                                function: '金融市场',
                                iconOrImg: 'icon',
                                icon: '',
                                img:'',
                                hidden:true
                            }
                        },
                        {
                            path: '/Insight/financialMarket/financialProduct/Bond/foreignDebt',
                            name: '境外债',
                            component: () => import(
                                '@/views/financial/foreignDebt.vue'),
                            // redirect: '/Insight/PlaceMarket',
                            meta: {
                                pageId: 20,
                                title: '中思拓-境外债',
                                authId: [20],
                                function: '金融市场',
                                iconOrImg: 'icon',
                                icon: '',
                                img:''
                            }
                        },
                        {
                            path: '/Insight/financialMarket/financialProduct/Bond/foreignDetail',
                            name: '境外债详情',
                            component: () => import(
                                '@/views/financial/foreignDetail.vue'),
                            // redirect: '/Insight/PlaceMarket',
                            meta: {
                                title: '中思拓-境外债详情',
                                authId: [20],
                                function: '金融市场',
                                iconOrImg: 'icon',
                                icon: '',
                                img:'',
                                hidden:true
                            }
                        },
                        {
                            path: '/Insight/financialMarket/financialProduct/Bond/ConvertibleBonds',
                            name: '可转债',
                            component: () => import(
                                '@/views/financial/convertibleBonds.vue'),
                            // redirect: '/Insight/PlaceMarket',
                            meta: {
                                pageId: 21,
                                title: '中思拓-可转债',
                                authId: [21],
                                function: '金融市场',
                                hidden:false,
                                iconOrImg: 'icon',
                                icon: '',
                                img:''
                            }
                        },
                        {
                            path: '/Insight/financialMarket/financialProduct/Bond/ConvertibleDetail',
                            name: '可转债详情',
                            component: () => import(
                                '@/views/financial/convertibleDetail.vue'),
                            // redirect: '/Insight/PlaceMarket',
                            meta: {
                                title: '中思拓-可转债详情',
                                authId: [21],
                                function: '金融市场',
                                iconOrImg: 'icon',
                                icon: '',
                                img:'',
                                hidden:true
                            }
                        },
                        {
                            path: '/Insight/financialMarket/financialProduct/trust',
                            name: '信托',
                            component: () => import('@/views/financial/trust.vue'),
                            // redirect: '/Insight/PlaceMarket',
                            meta: {
                                pageId: 33,
                                title: '中思拓-信托',
                                authId: [22],
                                function: '金融市场',
                                hidden:true
                            }
                        },
                        {
                            path: '/Insight/financialMarket/financialProduct/trustDetail',
                            name: '信托详情',
                            component: () => import(
                                '@/views/financial/trustDetail.vue'),
                            // redirect: '/Insight/PlaceMarket',
                            meta: {
                                title: '中思拓-信托详情',
                                authId: [22],
                                function: '金融市场',
                                hidden:true
                            }
                        },
                        {
                            path: '/Insight/financialMarket/financialProduct/assetManagement',
                            name: '资管',
                            component: () => import(
                                '@/views/financial/assetManagement.vue'),
                            // redirect: '/Insight/PlaceMarket',
                            meta: {
                                pageId: 34,
                                title: '中思拓-资管',
                                authId: [23],
                                function: '金融市场',
                                hidden:true
                            }
                        },
                        {
                            path: '/Insight/financialMarket/financialProduct/othersProduct',
                            name: '其他产品',
                            component: () => import(
                                '@/views/financial/othersProduct.vue'),
                            // redirect: '/Insight/PlaceMarket',
                            meta: {
                                title: '中思拓-其他产品',
                                authId: [26],
                                function: '金融市场',
                                iconOrImg: 'icon',
                                icon: '',
                                img:'',
                                hidden:true
                            }
                        },
                    ]
                },
                {
                    // path: '/Insight/financialMarket/financialProduct/otherDetail',
                    path: '/Insight/financialMarket/financialProduct/otherIndex',
                    name: '其他',
                    component: () => import(
                        '@/views/financial/otherIndex.vue'),
                    // redirect: '/Insight/PlaceMarket',
                    meta: {
                        title: '中思拓-其他详情',
                        authId: [26],
                        function: '金融市场',
                        iconOrImg: 'icon',
                        icon: '',
                        hidden:false,
                        img:''
                    },

                    children:[
                         {
                            path: '/Insight/financialMarket/financialProduct/others',
                            // path: '/Insight/financialMarket/financialProduct/others/otherDetail',
                            name: '其他融资',
                            // component: () => import('@/views/financial/otherDetail.vue'),
                            component: () => import('@/views/financial/others.vue'),
                            // redirect: '/Insight/PlaceMarket',
                            meta: {
                                pageId: 27,
                                title: '中思拓-其他融资',
                                authId: [27],
                                function: '金融市场',
                                iconOrImg: 'icon',
                                icon: '',
                                img:''
                            }
                        },
                         {
                            // path: '/Insight/financialMarket/financialProduct/others',
                            path: '/Insight/financialMarket/financialProduct/others/otherDetail',
                            name: '其他详情',
                            component: () => import('@/views/financial/otherDetail.vue'),
                            // component: () => import('@/views/financial/others.vue'),
                            // redirect: '/Insight/PlaceMarket',
                            meta: {
                                pageId: 27,
                                title: '中思拓-其他融资',
                                authId: [27],
                                function: '金融市场',
                                iconOrImg: 'icon',
                                hidden: false,
                                icon: '',
                                img:''
                            }
                        }
                    ]
                },

            ]
        }
    ]
}
export default financialMarket;