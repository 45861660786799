const personal = {
	state: {
		isShowAccountCreateModal: false,
		isShowAuthModal: false,
		isShowRoleCreateModal: false,
		isShowEditAuthModal: false,
		isShowAccountEditModal: false,
		isShowResetPassModal: false,
		selectedAuth: [],
		canShowAuthModal: false,
	},
}
export default personal;
