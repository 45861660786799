<template>
	<div id="app" :style="_isMobile ? 'min-width:0;overflow:hidden' : ''">
		<div class="navBox" v-if="isLogin && !_isMobile">
			<div class="nav" :class="{ fullScreen: !showTop }">
				<img class="logo" src="./assets/image/logo.png" />
				<div class="tabList">
					<div class="tabItem" tag="div" :class="routerActive(item) ? 'active' : ''" v-for="(item, index) in navList"
						:key="index" @mouseover="showChildrenHandle(item, index)" @click="routePush(item)"
						@mouseleave="showChildren = false">
						<div class="name">{{ item.pathName }}</div>
						<div class="tabItem-box" v-if="showChildren && showChildrenIndex == index" @mouseleave="showChildren = false">
							<div class="tabItem-box-item" @click="tRoutePush(item)" :key="index" v-for="(item, index) in childrenRoute">
								{{ item.pathName }}
							</div>
						</div>
					</div>
				</div>
				<div class="rightContent" ref="rightContent">
					<div class="line"></div>
					<!-- <div class="officialWebsite"><a href="https://www.zhongsituo.com" target="_blank">官网</a></div> -->
					<!-- <div class="officialWebsite"><a href="http://www.zhongdihui.com/" target="_blank">中地会</a></div> -->

					<!-- <div class="officialWebsite" @click="goDataIframe"> </div> -->
					<div class="officialWebsite"><a href="https://address.zhongsituo.com:10444/shareScreen/eyJzY3JlZW5JZCI6OX0=" target="_blank">数据确权可视化</a></div>
					<div class="officialWebsite"><a href="https://www.zhongsituo.com" target="_blank">官网</a></div>
					<div class="infoTips" @click="$router.push('/PersonalCenter/InfoManage')">
						<img src="./assets/image/infoTips1.png" alt />
						<div class="infoNumber" v-show="infoNum > 0 && infoNum < 10">{{ isInfoNum }}</div>
						<div class="infoNumber bigNum" v-show="infoNum >= 10">{{ isInfoNum }}</div>
					</div>

					<div class="bottom">
						<div class="manage">


							<Dropdown transfer @on-click="userAction">

								<div class="manage" style="max-width: 120px;overflow: hidden;" href="javascript:void(0)">
									<img class="manageIcon" v-if="user.userLogo" :src="user.userLogo" alt />
									<img class="manageIcon" v-else src="./assets/image/userLogo.png" alt />
									{{ user.nickname }}
									<Icon type="ios-arrow-down"></Icon>
								</div>
								<DropdownMenu slot="list">
									<DropdownItem name="center">个人中心</DropdownItem>
									<!-- <DropdownItem name="reset">修改密码</DropdownItem> -->
									<DropdownItem name="loginout">退出登录</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</div>

					</div>
				</div>
			</div>

		</div>
		<!-- <div class="navChildren" v-if="showChildren" @mouseleave="showChildren = false">
			<div class="childrenBox">
				<div class="childrenRoute" @click="tRoutePush(item)" :key="index" v-for="(item, index) in childrenRoute">
					<img :src="item.iconB" class="iconB" alt />
					<img :src="item.icon" class="icon" alt />
					<div class="name">{{ item.pathName }}</div>
				</div>
			</div>
		</div> -->
		<!-- <div class="navChildren">
			<div class="childrenBox">
				<div class="childrenRoute" @click="tRoutePush(item)" :key="index" v-for="(item, index) in childrenRoute">
					<img :src="item.iconB" class="iconB" alt />
					<img :src="item.icon" class="icon" alt />
					<div class="name">{{ item.pathName }}</div>
				</div>
			</div>
		</div> -->


		<div class="content" :style="_isMobile
			? 'width: 100vw;height: 100vh;'
			: showTop && isLogin
				? 'height:calc(100% - 74px)'
				: 'height:100%'
			" @mousemove="mousePoint">
			<router-view />
		</div>
		<tool></tool>
		<timeModal :time="12"></timeModal>
		<adviceModal></adviceModal>
		<reAdviceModal></reAdviceModal>
		<!-- <showYijianModal></showYijianModal> -->
		<!-- <showYapinModal></showYapinModal> -->
	</div>
</template>
<script>
import tool from "@/components/Tools.vue";
import timeModal from "@/components/modal/timeModal.vue";
import adviceModal from "@/components/modal/adviceModal.vue";
import reAdviceModal from "@/components/modal/reAdviceModal.vue";
// import showYijianModal from "@/components/modal/showYijianModal.vue";
// import showYapinModal from "@/components/modal/showYapinModal.vue";
import store from '@/store';

// import {getSingleSelectProvince} from './api/dataQuery';
import {
	getObj,
	loginout,
	resetPwd
} from "@/api/user/user";
import { getAuthCityNew } from '@/api/city';

import {
	get_model_tree
} from "@/api/router";
import {
	getUnreadInfo,
	getSysUnreadInfo
} from "@/api/infoManage";
import {
	mapMutations,
	mapState
} from "vuex";
import dataReports from "@/mixins/dataReports";
export default {
	name: "app",
	components: {
		tool,
		timeModal,
		adviceModal,
		// showYijianModal,
		// showYapinModal,
		reAdviceModal
	},
	mixins: [dataReports],
	computed: {
		...mapState([
			"userId",
			"user",
			"fixedLeft",
			// "showYijianModal",
			// "showYapinModal",
			"resourceAuthIds"
		]),
		isLogin() {
			return this.$store.state.token && this.$store.state.userId;
		},
		_isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			return !!flag;
		},
		isInfoNum() {
			let str = '99+'
			return this.infoNum < 100 ? this.infoNum : str
		},
	},
	data() {
		return {
			showTop: true,
			infoNum: 0,
			navList: [{
				icon: require("./assets/image/homeW.png"),
				iconB: require("./assets/image/homeB.png"),
				path: "/",
				pathName: "首页",
			},
						{
				icon: require("./assets/image/DepthStudyW.png"),
				iconB: require("./assets/image/DepthStudyB.png"),
				path: "/Empowerment/pledge/houseProperty/rapidValuation",
				pathName: "房屋智能估价",
			},
			// {
			// 	icon: require("./assets/image/EmpowermentW.png"),
			// 	iconB: require("./assets/image/EmpowermentB.png"),
			// 	path: "/Empowerment",
			// 	pathName: "大数据赋能",
			// 	childrenRoute: [
			// 		{
			// 			icon: require("./assets/image/cIcon2.png"),
			// 			iconB: require("./assets/image/cIcon2B.png"),

			// 			path: "/Empowerment/pledge/houseProperty/rapidValuation",
			// 			pathName: "房屋智能估价",
			// 		},
			// 		{
			// 			icon: require("./assets/image/cIcon1.png"),
			// 			iconB: require("./assets/image/cIcon1B.png"),
			// 			path: "/Empowerment/Analysis/Home",
			// 			pathName: "一键看项目",
			// 		},

			// 	],
			// },
			{
				icon: require("./assets/image/InsightW.png"),
				iconB: require("./assets/image/InsightB.png"),
				path: "/Insight",
				pathName: "数据洞察",
				childrenRoute: [
					{
						icon: require("./assets/image/cIcon4.png"),
						iconB: require("./assets/image/cIcon4B.png"),
						path: "/Insight/City",
						pathName: "城市系统",
						modalName: "城市模块",
					},
					{
						icon: require("./assets/image/cIcon5.png"),
						iconB: require("./assets/image/cIcon5B.png"),
						path: "/Insight/enterprise",
						pathName: "企业监测",
						modalName: "企业模块",
					},
					{
						icon: require("./assets/image/cIcon2.png"),
						iconB: require("./assets/image/cIcon2B.png"),
						// path: _this.resourceAuthIds.includes(9)? "/Insight/PlaceMarket" :"/Insight/PlaceSearch",
						path: "/Insight/land",
						pathName: "土地市场",
						modalName: "土地模块",
					},
					{
						icon: require("./assets/image/cIcon3.png"),
						iconB: require("./assets/image/cIcon3B.png"),
						path: "/Insight/financialMarket",
						pathName: "金融市场",
						modalName: "金融模块",
					},
					{
						icon: require("./assets/image/cIcon1.png"),
						iconB: require("./assets/image/cIcon1B.png"),
						path: "/Insight/MacroEconomy",
						pathName: "宏观经济",
						modalName: "宏观模块",
					},




					// {
					// 	icon: require("./assets/image/cIcon6.png"),
					// 	iconB: require("./assets/image/cIcon6B.png"),
					// 	path: "/Insight/publicSentiment",
					// 	pathName: "舆情监测",
					// },
				],
			},

			// {
			//   icon: require("./assets/image/ExcavateW.png"),
			//   iconB: require("./assets/image/ExcavateB.png"),
			//   //path: "/Excavate",
			//   path: "",
			//   pathName: "数据挖掘",
			// },
			{
				icon: require("./assets/image/CustomizedServiceW.png"),
				iconB: require("./assets/image/CustomizedServiceB.png"),
				path: "/customerorder",
				pathName: "定制化",
			},
			{
				icon: require("./assets/image/DepthStudyW.png"),
				iconB: require("./assets/image/DepthStudyB.png"),
				path: "/Research",
				pathName: "深度研究",
			},
			{
				icon: require("./assets/image/DepthStudyW.png"),
				iconB: require("./assets/image/DepthStudyB.png"),
				path: "/WarnResearch",
				pathName: "预警报告",
			},

				// {
				//   icon: require("./assets/image/IntelligenceW.png"),
				//   iconB: require("./assets/image/IntelligenceB.png"),
				//   //path: "/Intelligence",
				//   path: "",
				//   pathName: "商务智能",
				// },
			],
			userLogoSrc: './assets/image/userLogo.png',
			childrenRoute: [],
			showChildren: false,
			showChildrenIndex: -1,
			showModal: false,
			formItem: {
				psw: "",
			},
			loading: false,
			ele: "",
			screenWidth: null,
		};
	},


	mounted() {
		this.init()




		if (this.$router.history.current.path !== "/login") {
			setInterval(() => {
				this.getNumber()
			}, 60000)
		}
		if (this.$route.path != '/login') {
			this.getUserInfo()
		}
		this.getNumber()
		this.screenWidth = document.body.clientWidth
		window.onresize = () => {
			//屏幕尺寸变化
			return (() => {
				this.screenWidth = document.body.clientWidth
			})()
		}

		this.$nextTick(() => {
			var that = this
			// var ele = document.removeEventListener('click',(e)=>{this.btnSysLog(e)})
			setTimeout(function () {
				var ele = window.addEventListener('click', (e) => { that.btnSysLog(e) })
				// that.ele = ele
			}, 500)
		})

	},
	destroyed() {
		window.removeEventListener('click', (e) => { this.btnSysLog(e) })
	},
	watch: {
		$route() {
			if ((store.state.token && store.state.userId)) {//当用户登陆后
				this.getNumber()
			}
			//
		},
		screenWidth: function (n, o) {
			// if (n <= 1400) {
			// 	this.changeState({
			// 		prop: "showLeft",
			// 		value: false,
			// 	});
			// } else {
			// 	this.changeState({
			// 		prop: "showLeft",
			// 		value: true,
			// 	});
			// }
		}

		//获取页面宽度


	},

	methods: {
		...mapMutations(["changeState"]),
		//鼠标移入的时候，显示左侧导航栏

	async	init(){
			await	this.$store.dispatch("getcityList");//获取城市列表

		this.getAuthCityList();//获取权限城市列表
		},
		goDataIframe() {
			this.$router.push({
				path: "/dataIframe",
			})
		},
		getAuthCityList() {
			let params = {
				origin: 1,//1数据平添
				// userId: this.$store.state.userId
			}
			getAuthCityNew(params).then((res) => {
				this.$store.dispatch("getUserCityList", res);//获取用户城市列表
				//存入本地缓存
			});
		},
		getNumber() {
			let infoNum = 0;
			getUnreadInfo().then((res) => {
				infoNum = res;
				getSysUnreadInfo().then((data) => {
					infoNum += data;
					this.infoNum = infoNum;
				});
			})
		},
		mousePoint(e) {
			if (this.fixedLeft) return;
			if (e.clientX < 20) {
				this.changeState({
					prop: "showLeft",
					value: true,
				});
			} else if (e.clientX > 240) {
				this.changeState({
					prop: "showLeft",
					value: false,
				});
			}
		},

		routerActive(item) {
			if (this.$route.path === "/") {
				return item.path === this.$route.path;
			}
			return (
				item.path !== "/" && item.path && this.$route.path.includes(item.path)
			);
		},

		showChildrenHandle(item, index) {
			if (!item.childrenRoute) {
				this.showChildren = false;
				this.showChildrenIndex = -1;
				return;
			}
			this.showChildrenIndex = index
			this.childrenRoute = item.childrenRoute;
			this.showChildren = true;
		},
		getUserInfo() {
			if (localStorage.getItem("userId")) {
				getObj({
					id: localStorage.getItem("userId"),
				}).then((res) => {
					this.changeState({
						prop: "user",
						value: res,
					});
				});
			} else {
				// this.$router.push({
				// 	path: "/login",
				// });
			}
		},
		//点击菜单栏时使一键以及押品的无权限页面消失
		closeModal() {
			this.changeState({
				prop: "showYijianModal",
				value: false,
			});
			this.changeState({
				prop: "showYapinModal",
				value: false,
			});
		},
		routePush(item) {
			if (item.childrenRoute) return;
			this.$router.push(item.path);
			this.showChildren = false;
			this.closeModal();
			if (!item.path) {
				this.$msg.error({
					type: "noAuthority",
					code: 1007,
				});
			}
		},
		userAction(name) {
			const self = this;
			const action = {
				reset: () => {
					this.showModal = true;
				},
				loginout: () => {
					// self.leavePageLog().then((res) => {
					// });
					this.changeState({
						prop: "token",
						value: "",
					});
					localStorage.setItem("token", "");
					localStorage.setItem("isLogin", false);
					localStorage.setItem("userId", "");
					localStorage.setItem("customerInfo", "");
					window.location.reload();
				},
				center: () => {
					this.$router.push("/PersonalCenter");
				},
			};
			action[name]();
		},
		handleSetPsw() {
			if (!this.formItem.psw) return this.$msg.error({
				text: "请输入新密码"
			});
			resetPwd({
				id: this.userId,
				pwd: this.formItem.psw.trim(),
			}).then((res) => {
				this.$msg.success({
					text: "修改成功"
				});
				// this.$msg.success("修改成功");
				this.changeState({
					prop: "token",
					value: "",
				});
				localStorage.setItem("token", "");
				localStorage.setItem("isLogin", false);
				localStorage.setItem("userId", "");
				window.location.reload();
			});
		},
		cancelModalHandle() {
			this.formItem.psw = "";
		},
		tRoutePush(item) {
			this.closeModal();
			if (item.modalName) {
				this.changeState({
					prop: "actionRouter",
					value: [],
				});
				get_model_tree({
					modelName: item.modalName,
				}).then((res) => {
					let actionRouter = [];
					res.children.map((item) => {
						let i = {
							url: location.protocol + "//" + location.host + item.fineReportUrl,
							title: item.indexName,
							children: [],
							icon: "ios-eye",
						};
						if (item.children) {
							item.children.map((item) => {
								i.children.push({
									url: location.protocol +
										"//" +
										location.host +
										item.fineReportUrl,
									title: item.indexName,
									icon: "ios-eye",
								});
							});
						}
						actionRouter.push(i);
					});
					this.changeState({
						prop: "actionRouter",
						value: actionRouter,
					});
					localStorage.setItem("actionRouter", JSON.stringify(actionRouter));
					this.showChildren = false;
					if (res.children[0].fineReportUrl) {
					let prodsrc = 'data.zhongsituo.com'
						this.changeState({
							prop: "iframeUrl",
							value: location.protocol +
								"//" +
							prodsrc +
								res.children[0].fineReportUrl,
						});
						localStorage.setItem(
							"iframeUrl",
							location.protocol +
							"//" +
							prodsrc +
							res.children[0].fineReportUrl
						);
					} else {
						this.changeState({
							prop: "iframeUrl",
							value: "",
						});
						localStorage.setItem("iframeUrl", "");
					}
					this.$router.push(item.path);
				});
			} else {
				this.changeState({
					prop: "actionRouter",
					value: "",
				});
				localStorage.setItem("actionRouter", "");
				this.changeState({
					prop: "iframeUrl",
					value: "",
				});
				localStorage.setItem("iframeUrl", "");
				this.showChildren = false;
				this.$router.push({ path: item.path, replace: true });
			}
		},

		//btnSysLog
		btnSysLog(e) {
			let parentList = document.getElementsByClassName('searchBtnLog')
			parentList = [...parentList]
			parentList.forEach(item => {
				if (item.contains(e.target)) {
					let id = item.getAttribute('data-id')
					this.$btnSysLog(id, 0)
				}
			});
		}
	},
};
</script>

<style lang="scss">
@import "@/style/common.scss";

// /deep/.ivu-select-dropdown{
// 	background: red;
// 	}
body,
html {
	margin: 0;
	padding: 0;
	height: 100vh;
	width: 100vw;
	user-select: none;
	background: #fff;
}

::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 15px;
	/*高宽分别对应横竖滚动条的尺寸*/
	height: 15px;
	cursor: pointer;
}

::-webkit-scrollbar-thumb {
	/*滚动条里面小方块*/
	width: 15px;
	// border-radius: 2px;
	background: rgba(0, 0, 0, 0.2);
	cursor: pointer;
}

::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	// border-radius: 10px;
	background: transparent;
	cursor: pointer;
}

.scroll-wrapper {
	width: 100%;
	overflow-x: auto;
	height: 22px;
	white-space: nowrap;
	display: flex;
	-webkit-overflow-scrolling: touch;
}

.scroll-wrapper span {
	display: block;
	padding: 0 4px;
	margin: 0 10px;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	border: 1px solid #3884ff;
	border-radius: 20px;
	color: #3884ff;
}

#app {
	font-family: "Avenir", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-width: 1400px;
	margin: 0;
	color: #333333;
	padding: 0;
	height: 100%;
}

.ivu-notice {
	top: 7% !important;
	left: 50%;
	transform: translate(-50%) !important;
	width: 500px !important;
	z-index: 9999 !important;

	.ivu-notice-notice {
		// background: transparent;
		padding: 23px !important;
		background-color: #00b6ff !important;

		.ivu-notice-icon {
			color: #ffd130 !important;
			font-size: 0px !important;
		}

		.ivu-icon-ios-close {
			color: white;
			font-size: 30px !important;
			font-weight: 900 !important;
		}

		.ivu-notice-title {
			margin-left: 16px !important;
			color: white !important;
		}
	}

	.ivu-notice-desc {
		color: white !important;

		letter-spacing: 1px !important;
		line-height: 22px !important;
		font-size: 14px !important;
	}

	.ivu-notice-content-with-icon {
		letter-spacing: 1px !important;
		line-height: 22px !important;
		font-size: 14px !important;
		color: white !important;
		margin-left: 16px !important;
	}

	.ivu-notice-title {
		letter-spacing: 1px !important;
		line-height: 20px !important;
		font-size: 16px !important;
		font-weight: 900 !important;
	}
}

.navBox {
	position: relative;
	width: 100%;
	height: auto;
	z-index: 1024;

	.openNav,
	.closeNav {
		position: absolute;
		width: 42px;
		height: 16px;
		right: 17px;
		bottom: -1px;
		z-index: 2048;
		cursor: pointer;
	}

	.openNav {
		bottom: -15px;
		transform: rotate(180deg);
	}
}

.nav {
	width: 100%;
	height: 74px;
	// background: rgba(30, 46, 84, 1);
	background: #fff;
	box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.05);
	@include flex-center;
	// overflow: hidden;
	transition: 0.5s;

	&.fullScreen {
		height: 0px;
	}

	.logo {
		width: 220px;
		margin-left: 25px;
	}

	.tabList {
		width: calc(100% - 722px);
		display: flex;
		height: 100%;
		// margin: 0 15px;

		.tabItem {
			// flex: 1;
			height: 100%;
			text-align: center;
			// overflow: hidden;
			position: relative;
			// color: #fff;
			// transition: 0.3s;
			margin-left: 70px;
			cursor: pointer;
			font-size: 18px;
			font-family: PingFang SC-Regular, PingFang SC;
			font-weight: 400;
			color: #262626;

			.icon,
			.iconB {
				display: block;
				width: 20px;
				top: calc(50% - 24px);
				left: calc(50% - 10px);
				position: absolute;
			}

			.iconB {
				display: none;
			}

			.name {
				width: 100%;
				// top: calc(50% + 5px);
				// position: absolute;

				line-height: 74px;
				white-space: nowrap;
			}

			&:hover,
			&.active {
				border-bottom: 2px solid #0077FF;
				// background: #e7f0fc;
				font-weight: 800;
				color: #0073FA;

				.iconB {
					display: block;
				}

				.icon {
					display: none;
				}
			}

		}
	}

	.tabItem-box {
		position: absolute;
		font-size: 14px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		width: 155px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 1px rgba(0, 119, 255, 0.45);
		// opacity: 0.64;
		padding: 4px 0;
		text-align: left;

		.tabItem-box-item {
			height: 32px;
			width: 100%;
			line-height: 32px;
			padding-left: 14px;
			color: #262626;

			&:hover {
				background: #0073FA;
				color: #FFFFFF;
			}

		}

	}

	.rightContent {
		width: 425px;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		white-space: norwap;
		font-size: 18px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #262626;

		.line {
			width: 1px;
			height: 40px;
			background: rgba(0, 0, 0, 0.15);
		}

		.officialWebsite {
			// @include flex-center;
			margin-left: 40px;
			// margin: 0 40px;
			white-space: nowrap;
			cursor: pointer;

			a {
				color: #262626;
			}

			&:hover {
				color: #262626;
			}
		}

		.top,
		.bottom {
			height: 45%;
			display: flex;
			align-items: center;
			white-space: norwap;
			width: 100%;
		}


		.tradingPlatform {
			@include flex-center;
			color: #fff;
			margin-right: 40px;
			white-space: nowrap;
			cursor: pointer;

			&:hover {
				color: #eee;
			}

			img {
				margin-right: 6px;
			}
		}

		.infoTips {
			position: relative;
			cursor: pointer;
			margin-left: 43px;
			margin-right: 26px;

			img {
				display: block;
				width: 14px;
				height: 16px;
			}

			.infoNumber {
				position: absolute;
				width: 14px;
				height: 14px;
				background: rgba(245, 44, 43, 1);
				border-radius: 50%;
				font-size: 12px;
				font-family: PingFangSC-Regular-, PingFangSC-Regular;
				font-weight: normal;
				color: #FFFFFF;
				// color: #ffffff;
				top: -9px;
				right: -9px;
				@include flex-center;
			}

			.bigNum {
				height: 16px;
				width: 28px;
				border-radius: 25px;
				top: -9px;
				right: -24px;
			}
		}

		.manage {
			@include flex-center;
			font-size: 14px;
			// color: #ffffff;
			font-family: ArialMT-, ArialMT;
			justify-content: left;
			cursor: pointer;
			white-space: nowrap;
			width: 140px;

			.manageIcon {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				margin-right: 8px;
				// background: #000;
			}

			.downIcon {
				margin-left: 6px;
			}
		}

		.fullScreen {
			cursor: pointer;
			position: absolute;
			right: 27px;
			bottom: 18px;
		}

		.logoA {
			white-space: nowrap;
			transform: translate(0px, 0px);
			display: flex;
			align-items: center;
			// color: #fff;
		}

		.logo2 {
			width: 20px;
			height: 20px;
			margin-right: 3px;
		}
	}
}

.navChildren {
	position: absolute;
	top: 80px;
	left: 0;
	width: 100%;
	height: 220px;
	background: rgba(231, 240, 252, 1);
	box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
	z-index: 9999;

	.childrenBox {
		margin: 20px auto 0;
		width: 740px;
		display: grid;
		grid-template-columns: repeat(3, 160px);
		grid-template-rows: repeat(2, 80px);
		grid-row-gap: 20px;
		grid-column-gap: 130px;

		.childrenRoute {
			width: 180px;
			height: 80px;
			@include flex;
			cursor: pointer;

			.icon,
			.iconB {
				display: block;
				width: 24px;
				height: 24px;
				margin-right: 10px;
			}

			.iconB {
				display: none;
			}

			color: #1e2e54;

			&:hover {
				color: #1562d6;

				.iconB {
					display: block;
				}

				.icon {
					display: none;
				}
			}
		}
	}
}

.content {
	width: 100%;
	display: flex;
	transition: 0.5s;
}

@media screen and (max-width: 1600px) {
	.nav .tabList .tabItem {
		font-size: 14px;
	}
}

.ivu-table-fixed {
	margin-bottom: 10px !important;
	height: auto !important;
}
</style>
