import { post, get } from '@/utils/http';

// 经济发展指标
export function jjfzzb(params) {
    return get({ url: '/service-insight/xmfx_dim/jjfzzb', params });
}

// 土地用途维度
export function landUse(params) {
    return get({ url: '/service-insight/xmfx_dim/landUse', params });
}

//  土地市场-市场分析-物业类型
export function landMarketAnalysisWYType(params) {
    return get({ url: '/service-insight/dim_city/statPropertyType', params });
}

// 小区-物业类型
export function LpWYType(params) {
    return get({ url: '/service-insight/dim_city/propertyType', params });
}

// 土地市场-时间周期维度
export function landMarketTimeType(params) {
    return get({ url: '/service-insight/xmfx_dim/tdsc_time_type', params });
}

// 项目分析-交易状态维度
export function dealState(params) {
    return get({ url: '/service-insight/xmfx_dim/deal_state', params });
}

// 项目分析-销售状态维度
export function saleState(params) {
    return get({ url: '/service-insight/xmfx_dim/sale_status', params });
}

// 根据区域Id获取城市
export function singleArea(params) {
    return get({ url: '/service-insight/xmfx_dim/xmfxArea', params });
}

// 项目分析-城市接口
export function city(params) {
    return get({ url: '/service-insight/xmfx_dim/xmfxCity', params });
}

// 项目分析-省接口
export function province(params) {
    return get({ url: '/service-insight/xmfx_dim/xmfxProvince', params });
}

// 项目分析-区域接口
export function region(params) {
    return get({ url: '/service-insight/xmfx_dim/xmfxRegion', params });
}

// 获取项目列表
export function getProjectList(params) {
    return post({ url: '/service-insight/xmfx/item_list', params });
}

// 获取项目详情
export function getProjectDetail(params) {
    return get({ url: '/service-insight/xmfx/item_detail', params });
}

// 获取政策法规
export function getPolicyList(params) {
    return post({ url: '/service-insight/xmfx/zcfg_list', params });
}

// 新增项目
export function addProject(params) {
    return post({ url: '/service-insight/xmfx/add_item', params });
}

// 删除项目
export function deleteProject(params) {
    return get({ url: '/service-insight/xmfx/delete_item', params });
}

// 新增楼盘项目
export function addCityProject(params) {
    return post({ url: '/service-insight/xmfx/add_item_lp', params });
}

// 新增土地项目
export function addPlaceProject(params) {
    return post({ url: '/service-insight/xmfx/add_item_td', params });
}

// 获取经济发展总值
export function economicData(params) {
    return get({ url: '/service-insight/xmfx/jjfz_sczz', params });
}

// 经济发展主要经济指标（城市分析）
export function zyjjzb(params) {
    return post({ url: '/service-insight/xmfx/jjfz_zyjjzb', params });
}

// 土地市场成交统计
export function tdscCjcr(params) {
    return post({ url: '/service-insight/xmfx/tdsc_cjcr', params });
}

// 土地市场-主要经济指标（楼面价/出让金）
export function tdscZyjjzb(params) {
    return post({ url: '/service-insight/xmfx/tdsc_zyjjzb', params });
}

// 市场分析-楼盘统计
export function scfxLptj(params) {
    return get({ url: '/service-insight/xmfx/scfx_lptj', params });
}

// 市场分析主要经济指标
export function scfxZyjjzb(params) {
    return get({ url: '/service-insight/xmfx/scfx_zyjjzb', params });
}

// 市场分析二手房价/租金
export function scfxEsfjzl(params) {
    return get({ url: '/service-insight/xmfx/scfx_esfjzl', params });
}

// 周边楼盘
export function zblp(params) {
    return post({ url: '/service-insight/xmfx/zblp', params });
}

// 周边土地
export function zbtd(params) {
    return post({ url: '/service-insight/xmfx/zbdk', params });
}

// 项目规划及实景
export function xmgh(params) {
    return get({ url: '/service-insight/xmfx/xmfx_xmgh', params });
}

// 政策法规重要
export function zcfgzy(params) {
    return get({ url: '/service-insight/xmfx/zcfgzy', params });
}

// 土地楼盘id获取项目id
export function getXmid(params) {
    return post({ url: '/service-insight/xmfx/get_xmid', params });
}

// 查询项目
export function selectProject(params) {
    return post({ url: '/service-insight/xmfx/select_item', params });
}

// 修改项目经纬度
export function setXmjwd(params) {
    return get({ url: '/service-insight/xmfx/xmfx_xmjwd', params });
}

// 政策法规分类维度（包括一级二级三级）
export function zcfgfl(params) {
    return get({ url: '/service-insight/yuqing/zcfgfl', params });
}
