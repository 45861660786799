//文字前标*代表文字字体加粗，文字前带^表示为跳转连接，需要加红且跳转,带~表示换行
export default {
    noAuthority: {
        '1001': ['您尚无此权限'],
        '1002': ['您尚无此权限'],
        '1003':['您尚无此权限'],
        '1004': ['您尚无此权限'],
        '10041': '您尚无此权限',//不用
        '10042': '您尚无此权限',//不用
        '1005': ['您尚无此权限'],
        '1006': ['您尚无此权限'],
        '1007': ['思思正在努力成长中，请等等。'],


    },
    dataDesc: {
     '2023': ['【行政区】筛选功能和数据正努力建设中，请等等。如有需要，可提交定制化需求，思思将在1个工作日内联系您，', '^去定制...'],
        '2024': ['按集团统计功能正努力建设中请等等。如有需要，可以提交定制化需求，思思将在1个工作日内联系您，', '^去定制...'],//不用
        '20342': ['按集团统计功能正努力建设中，请等等。如有需要，可提交定制化需求，思思将在1个工作日内联系您，', '^去定制...'],//不用


    },
    tips: {
        '3033': '亲爱的用户，您可以点击“重新定位”控件对项目进行更加精准的定位！！',//不用
        '30332': '亲爱的用户，您可以点击“卫星地图”切换地图模式！',//不用
        '3034': '亲爱的用户，您可以点击“画多边形”控件在地图上画出项目的四至范围！',//不用
        '30423': ['上传待估价房屋数据模板中填入“贷款抵押额度”后，您还可在管理界面根据贷款抵押额度设置预警值和抵质押率。'],//不用
        '3044': ['确认估价结果后，将不能再对当次估价结果进行【用户调整】，是否继续？'],
        '3045': ['如果小区名称或小区详情无数据，说明系统无法将证载地址与中思拓楼盘字典进行精确匹配！'],//不用
        '3047': ['点击【^估价查询】，可以精确搜索、下载和查看已确认的估价结果。'],//不用
        '3065': ['数据来源于战略合作伙伴'],
    },
    remind: {
        '4011':[ '【开始时间】不可留空，请您选择起始时间。'],
        '4012': ['【结束时间】不可留空，请您选择结束时间。'],
        '4013': ['【区域】不可留空，请您选择区域。'],
        '4014':[ '【指标】不可留空，请您选择指标。'],
        '4015':[ '请选择完整的周期范围'],
        '4016':[ '【土地用途】不可留空，请您选择土地用途。'],
        '4017': ['【企业】不可留空，请您选择企业。'],
        '4018':[ '【物业类型】不可留空，请您选择物业类型。'],
        '4019': ['【开发商】不可留空，请您选择开发商。'],
        '4020': ['【指标】不可留空，请您至少选择一种指标。'],
        '4021': ['【周期】不可留空，请您选择起始或终止时间。'],
        '4022': ['【日期选择】不可留空，请您选择日期。'],
        '4023': ['【关键词】不可留空，请您选择关键词。'],
        '4024':['请输入项目名称'],
        '4025':['请先点击【^我的项目】选择或创建一个项目。'],//我的项目-这里改成调转到该页面
        '4026': ['【城市】不可留空，请您选择城市。'],
        '4027':['【手机号码】格式错误，请您输入正确的手机号码。'],
        '4028':['提交成功，审批通过后，短信通知。'],//2023.1.4修改
        '4029':['验证码发送成功，请留意手机短信。'],
        '4030':['【手机号码】不可留空，请您输入手机号码。'],
        '4031':['【密码】不可留空，请您输入登录密码。'],
        '4032':['登录失败，您输入的账号或密码错误。'],
        '4033':['标“*”为必填项，请填写完整。'],
        '40341':['提交成功，思思将在1个工作日内与您联系，请保持电话畅通。'],
        '40342':['修改成功。'],
        '4035':['已取消关注。'],
        '4036':['关注成功。'],
        '4037':['留言提交成功。'],
        '4038':['请按照“待估价对象数据模板”上传数据。'],
        '4039':['搜索结果过多，请缩小【半径搜索】范围再试。'],
        '4040':['起始时间、截止时间不可留空，请您选择起始时间和截止时间。'],
        '4041':['输入【项目地址】后，才能点击【定位】'],
        '4042':['请【确认估价结果】后再点击查看。'],
        '4043':['设置不成功，未上传贷款抵押额度数据。'],
        '4044':['设置无效，估价结果未出或未勾选需要设置的估价对象。'],
        '4045':['任务提交成功，请耐心等待系统数据校验结果。'],
        '4046':['格式错误，请输入数值。'],
        '4047':['请输入搜索半径，输入值范围是0-5公里。'],
        '4048':['超出最大搜索范围，最大搜索范围是5公里。'],
        '4051':[ '恭喜，项目创建成功。'],
        '4052':[ '估价基准日只能选择当日及半年以内的日期'],
        '4053':['输入【项目地址】后，才能点击【框选项目位置】'],
        '4054': ['【省份】不可留空，请您选择省份。'],


    },
    fromEnd:{
        '700000':['上传失败，上传的数据文件为空'],
        '700001':['上传失败，【必填项】为空。'],
        '700002':['上传失败，请按照模板规范和要求上传'],
        '100003':['格式不对，请下载模板上传'],
        '100004':['单次上传房屋数量不超过20万套'],
        '2021081001':['校验未通过，地址重复或字段输入有误，请核对无误后再上传'],
        '100005':['面积格式有误，请按模板格式上传'],

    }
}