import * as api from '@/api/projectAnalysis';

const project = {
    namespaced: true,
    state: {
        option: {
            landUse: [],
            landMarketAnalysisWYType: [],
            LpWYType: [],
            landMarketTimeType: [],
            province: [],
            dealState: [],
            saleState: [],
            zcfgfl: null,
        },
        projectDetail: null,

        projectTypeList: [
            {
                id: 1,
                name: '土地'
            },
            {
                id: 2,
                name: '土地'
            },
            {
                id: 3,
                name: '土地'
            },
        ],
        projectType: '',

        landList: null,
        isLoadingLand: false,
        lpList: null,
        nearbyList: [],

        landListOrigin: null,
        lpListOrigin: null,
        nearbyListOrigin: [],

        selectedTab: -1,

        map: null
    },
    getters: {
        shi(state) {
            return state.projectDetail.shi;
        }
    },
    actions: {
        initProjectData({ state }) {
            for (let key in state.option) {
                api[key]().then(data => {
                    state.option[key] = data;
                });
            }
        },
    },
    mutations: {
        setState(state, payload) {
            state[payload.prop] = payload.value;
        },

        getProjectType(state, value) {
            const result = state.projectTypeList.filter(item => {
                return item.id === value;
            })

            state.projectType = result[0].name;
        },
    }
};
export default project;
