import { post, get } from '@/utils/http'

export function getCityQuery(params) {
    return get({ url: '/service-insight/index_data/cityQuery', params })
}
export function getProvinceQuery(params) {
    return get({ url: '/service-insight/index_data/provinceQuery', params })
}

export function select(params) {
    return post({ url: '/service-insight/index_data/select', params })
}
export function selectCity(params) {
    return get({ url: '/service-insight/index_data/selectCity', params })
}
// 成交搜索
export function publicitySearch(params) {
    return post({ url: '/service-insight/query_land/publicitySearch', params })
}
// 推送成交搜索
export function pushPublicitySearch(params) {
    return post({ url: '/service-insight/query_land/pushPublicitySearch', params })
}
// 出让搜索
export function transferSearch(params) {
    return post({ url: '/service-insight/query_land/transferSearch', params })
}
// 推送出让搜索
export function pushTransferSearch(params) {
    return post({ url: '/service-insight/query_land/pushTransferSearch', params })
}
// 我的关注列表
export function followList(params) {
    return post({ url: '/service-insight/land_follow/list', params })
}
// 关注
export function followPlace(params) {
    return post({ url: '/service-insight/land_follow/save', params })
}

// 土地市场搜索
export function getData(params) {
    return post({ url: '/service-insight/land_statistics_master/get_data', params })
}
// 实时获取企业统计数据
export function get_enterprise_data(params) {
    return post({ url: '/service-insight/land_statistics_master/get_enterprise_data', params })
}
// 土地详情
export function placeDetail(params) {
    return get({ url: '/service-insight/query_land/getById', params })
}
// 出让 成交 获取土地详情
export function getByIdAndType(params) {
    return get({ url: '/service-insight/query_land/getByIdAndType', params })
}

export function newTjDk(params) {
    return get({ url: '/service-insight/query_land/newTjDk', params })
}

export function newTjLp(params) {
    return get({ url: '/service-insight/query_land/newTjLp', params })
}

