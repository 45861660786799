import { post, get } from '@/utils/http'


// 添加修改定制化需求
export function add_custom_order(params) {
    return post({ url: '/service-insight/custom_order/add_custom_order', params })
}

// 需求确认
export function custom_good(params) {
    return get({ url: '/service-insight/custom_order/custom_good', params })
}

// 用户需求确定
export function custom_affirm(params) {
    return get({ url: '/service-insight/custom_order/custom_affirm', params })
}

// 需求单列表
export function custom_order_page(params) {
    return post({ url: '/service-insight/custom_order/custom_order_page', params })
}

// 定制化需求类型维度
export function custom_order_type(params) {
    return get({ url: '/service-insight/custom_order/custom_order_type', params })
}

// 查询需求单
export function get_custom_order(params) {
    return get({ url: '/service-insight/custom_order/get_custom_order', params })
}

// 上传需求表
export function upload_custom_excel(params) {
    return post({ url: '/service-insight/custom_order/upload_custom_excel', params })
}

// 用户反馈
export function user_feedback(params) {
    return post({ url: '/service-insight/custom_order/user_feedback', params })
}

// 获取内部提交成果
export function get_result(params) {
    return get({ url: '/service-insight/custom_order/get_result', params })
}

// 内部获取用户反馈
export function in_get_feedback(params) {
    return get({ url: '/service-insight/custom_order/in_get_feedback', params })
}


