import _this from '../main.js'
import store from '@/store/index.js';
const citypermission = {
    inserted: function(el, binding) {
        // let hasPermission =
        //hasPermission  的值取决于  cityList 里面的authority，如果有一个为true，就为true
        let cityList = JSON.parse(localStorage.getItem('userAutoCityList'))
        let hasPermission = cityList.length > 0 ? true : false
        if (!hasPermission) {
            // 没有权限 阻止点击事件
            el.addEventListener('click', e => {
                e.stopPropagation()
                e.preventDefault()
                _this.$msg.error({
                    text: '未开通城市权限'
                })
            }, true)
        }

    },
}

export default citypermission