const placeMarketOld = {
    path: '/Insight/PlaceMarket',
    name: '土地市场(旧)',
    component: () => import('@/views/place/index.vue'),
    meta: {
        pageId: 9,
        authId: [9],
        function: '土地统计(旧)',
        iconOrImg: 'img',
        icon: '',
        img: require('../../../assets/image/tdscj.svg')

    },
    children: [{
            path: '/Insight/PlaceMarket',
            name: '土地统计',
            component: () => import('@/views/place/placemarket/PlaceMarket.vue'),
            meta: {
                title: '中思拓-土地统计',
                pageId: 9,
                authId: [9],
                function: '土地统计',
                iconOrImg: 'icon',
                icon: '',
                img: ''
            }
        },
        {
            path: '/Insight/PlaceMarket/place',
            name: '土地搜索',
            component: () => import('@/views/place/placesearch/'),
            meta: {
                pageId: 11,
                title: '中思拓-地块搜索',
                authId: [10, 11],
                function: '土地统计',
                iconOrImg: 'icon',
                icon: '',
                img: ''

            },
            children: [{
                    path: '/Insight/PlaceSearch',
                    name: '地块搜索',
                    component: () => import('@/views/place/placesearch/PlaceSearch.vue'),
                    meta: {
                        pageId: 11,
                        title: '中思拓-地块搜索',
                        authId: [10, 11],
                        function: '土地统计',
                        iconOrImg: 'icon',
                        icon: '',
                        img: ''
                    }
                },
                {
                    path: '/Insight/PlaceOptions',
                    name: '条件搜索',
                    component: () => import('@/views/place/placesearch/placeoptions/PlaceOptions.vue'),
                    meta: {
                        pageId: 12,
                        title: '中思拓-条件搜索',
                        authId: [12],
                        function: '土地统计',
                        iconOrImg: 'icon',
                        icon: '',
                        img: ''
                    }
                },
                {
                    path: '/Insight/PlaceMap',
                    name: '地图搜索',
                    component: () => import('@/views/place/placesearch/mapsearch/MapSearch.vue'),
                    // redirect: '/Insight/PlaceMarket',
                    meta: {
                        pageId: 13,
                        title: '中思拓-地图搜索',
                        authId: [13],
                        function: '土地统计',
                        iconOrImg: 'icon',
                        icon: '',
                        img: ''
                    }
                },
                {
                    path: '/Insight/PlaceDetail',
                    name: '土地详情',
                    component: () => import('@/views/place/placedetail/PlaceDetail.vue'),
                    // redirect: '/Insight/PlaceMarket',
                    meta: {
                        title: '中思拓-土地详情',
                        authId: [11, 12, 13, 14],
                        hidden: false,

                        function: '土地统计'
                    }
                },

            ]
        }


    ]
}
export default placeMarketOld;