import { post, get } from '@/utils/http';

// 登陆
export function login(params) {
    return post({ url: '/service-user/user_info/login', params });
}
export function getObj(params) {
    return get({ url: '/service-user/user_info/getObj', params });
}

// 短信验证码
export function getCode(params) {
    return get({ url: '/service-user/user_info/get_verify_user', params });
}
export function getPhoneCode(params) {
    return get({ url: '/service-user/user_info/get_verify_code', params });
}
//验证手机密码和验证码
export function verifyPwdAndCode(params) {
    return post({ url: '/service-user/user_info/verifyPwdAndCode', params });
}
//验证手机密码
export function verifyPwd(params) {
    return post({ url: '/service-user/user_info/verifyPwd', params });
}

// 申请试用
export function apply(params) {
    return post({ url: '/service-user/user_info/apply', params });
}

// 申请试用
export function apply2(params) {
    return post({ url: '/service-user/data_sys_customers/data_put_in', params });
}
// 申请试用
export function apply3(params) {
    return post({ url: '/service-user/data_sys_customers/data_put_in_verify', params });
}
// 企业
export function industry(params) {
    return get({ url: '/service-user/user_dim/industry', params });
}
// 登出
export function loginout(params) {
    return post({ url: '/service-user/user_info/logout', params });
}
// 修改密码
export function resetPwd(params) {
    return get({ url: '/service-user/user_info/resetPwd', params });
}

export function getSingleSelectCity(params) {
    return get({ url: '/service-insight/index_data/singleSelectCity', params });
}
export function getSingleSelectProvince(params) {
    return get({ url: '/service-insight/index_data/singleSelectProvince', params });
}
export function getSingleSelectRegion(params) {
    return get({ url: '/service-insight/index_data/singleSelectRegion', params });
}

// 获取所有iframe
export function getIframeData(params) {
    return get({ url: '/service-insight/index_open/getAllIframe', params });
}

//申请转平台用户
export function apply_customer(params) {
    return post({ url: '/service-insight/my_info/pc_apply_customer', params });
}



