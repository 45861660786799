import {upCityConfigList} from '@/api/pledge';

// let arrlist = [
// 	{
// provinceCode:"44",
// cityCode:"440100",
// provinceShortName:"广东",
// cityShortName:"广州",
// provinceFullName:"广东省",
// cityFullName:"广州市",
// authority:true,
// },{
// provinceCode:"44",
// cityCode:"440600",
// provinceShortName:"广东",
// cityShortName:"佛山",
// provinceFullName:"广东省",
// cityFullName:"佛山市",
// authority:true,

// },{
// provinceCode:"440000000000",
// cityCode:"441900000000",
// provinceShortName:"广东",
// cityShortName:"东莞",
// provinceFullName:"广东省",
// cityFullName:"东莞市",
// authority:false,

// }
// 	]

const cityList = {
  state: {
    cityList: []
  },
  mutations: {
    getcityList (state,cityList) {
      state.cityList = cityList
    }
  },
  actions: {
    getcityList ({ commit },cityList) {
      let arr  = []
       upCityConfigList().then(res => {//获取城市列表
          arr = res.filter(item => item.upFlag == 1)
           commit('getcityList',arr)
            })
    }
  }
}
export default cityList;
