import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'view-design/dist/styles/iview.css';
import * as echarts from 'echarts';
import './utils/verify';
import DateRange from '@/components/DateRange';
import DateRangeYM from '@/components/DateRangeYM';
import CheckboxGroupAll from '@/components/CheckboxGroupAll';
import svgIcon from './icons'; // icon
import * as filters from './filters';
import { pagelog, btnlog, btnSysLog } from './utils/dataReport';
import '@/assets/font/font.css'
import dayjs from 'dayjs'
import dataReports from './mixins/dataReports'
import errorCode from './data/errro.js'

import MessageNew from './components/MessageNew/index'
Vue.use(MessageNew )

// import "default-passive-events"; //添加事件管理者'passive'，来阻止'touchstart'事件，让页面更加流畅。 解决chrome下的warning问题


import {
  Spin,
  Row,
  Col,
  Poptip,
  List,
  ListItem,
  ListItemMeta,
  Card,
  Timeline,
  TimelineItem,
  Carousel,
  CarouselItem,
  Tag,
  Modal,
  Dropdown,
  DropdownMenu,
  Tooltip,
  DropdownItem,
  Icon,
  Input,
  Button,
  Message,
  Table,
  Notice,
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  Radio,
  FormItem,
  Form,
  Select,
  Slider,
  Option,
  Tree,
  DatePicker,
  Submenu,
  MenuGroup,
  MenuItem,
  Menu,
  Page,
  Tabs,
  TabPane,
  Steps,
  Step,
  Upload,
  Progress,
  Switch,
  Alert,
  Collapse,
  Panel,
  InputNumber,
  Breadcrumb
} from 'view-design';
import TagGroup from '@/components/TagGroup.vue';
Vue.component('InputNumber', InputNumber);
Vue.component('Collapse', Collapse);
Vue.component('Panel', Panel);

Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Icon', Icon);
Vue.component('Input', Input);
Vue.component('Upload', Upload);
Vue.component('Button', Button);
Vue.component('Table', Table);
Vue.component('Tooltip', Tooltip);
Vue.component('RadioGroup', RadioGroup);
Vue.component('Radio', Radio);
Vue.component('FormItem', FormItem);
Vue.component('Checkbox', Checkbox);
Vue.component('CheckboxGroup', CheckboxGroup);
Vue.component('Form', Form);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Tree', Tree);
Vue.component('DatePicker', DatePicker);
Vue.component('Submenu', Submenu);
Vue.component('MenuGroup', MenuGroup);
Vue.component('MenuItem', MenuItem);
Vue.component('Menu', Menu);
Vue.component('Tag', Tag);
Vue.component('Modal', Modal);
Vue.component('Page', Page);
Vue.component('Tabs', Tabs);
Vue.component('TabPane', TabPane);
Vue.component('DateRange', DateRange);
Vue.component('DateRangeYM', DateRangeYM);
Vue.component('CheckboxGroupAll', CheckboxGroupAll);
Vue.component('Card', Card);
Vue.component('Carousel', Carousel);
Vue.component('CarouselItem', CarouselItem);
Vue.component('Timeline', Timeline);
Vue.component('TimelineItem', TimelineItem);
Vue.component('List', List);
Vue.component('ListItem', ListItem);
Vue.component('ListItemMeta', ListItemMeta);
Vue.component('Steps', Steps);
Vue.component('Step', Step);
Vue.component('Poptip', Poptip);
Vue.component('TagGroup', TagGroup);
Vue.component('Row', Row);
Vue.component('Col', Col);
Vue.component('Spin', Spin);
Vue.component('Slider', Slider);
Vue.component('Progress', Progress);
Vue.component('i-switch', Switch);
Vue.component('Alert', Alert);
Vue.component('Breadcrumb', Breadcrumb);


// Vue.mixin(dataReports);
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
 import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import '@/assets/css/element-variables.scss'
 Vue.use(ElementUI)
Vue.config.productionTip = false;
Vue.prototype.$Message = Message;
Vue.prototype.$Notice = Notice;
Vue.prototype.$Modal = Modal;
Vue.prototype.$Spin = Spin;
Vue.prototype.$echarts = echarts;
Vue.prototype.$pagelog = pagelog;
Vue.prototype.$btnlog = btnlog;
Vue.prototype.$btnSysLog = btnSysLog;
Vue.prototype.$errorCode = errorCode;
Vue.prototype.$dayjs = dayjs

Vue.prototype.$Message.config({
  duration: 2
});

Array.prototype.remove = function (val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};
// 防止因为时区变化而日期改变
Date.prototype.toJSON = function () { return this.toLocaleString(); }

//引入自定义指令
import Directives from './directive/index.js'
Vue.use(Directives)



//引入本地的提醒信息到vue中
import noticeInfo from './data/noticeInfo'
Vue.prototype.noticeInfo = noticeInfo

//引入二开的弹框组件
import toase from './utils/toase'
Vue.prototype.$toase = toase

import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  //IrxtF2IWoZUU2w0S99T3AFEbIZZ9Ki7L
  ak: 'IrxtF2IWoZUU2w0S99T3AFEbIZZ9Ki7L'
})

let vue =  new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
export default vue
