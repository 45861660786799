<!--
 * 方法说明
 * author: 时雨
 * description: 发送短信验证码弹框
 * @param showAbnormalAccount 是否展示弹框
 * @param phone 手机号码
  * @param password 密码
 * @return loginSuccess 登录成功
 * @createTime: 2/17/2022, 8:44:05 AM
  -->
<template>
	<Modal v-model="showModal" :closable='true' :mask-closable='false' :width='500' transfer :lock-scroll='true'>
		<div slot="header" class="changeHeader">
			请输入验证码
		</div>
		<div class="changeInfo">
			<Form ref="Form" style="padding-right: 40px;" :model="formItem" :label-width="100">
				<FormItem label="验证码" :label-width="85" prop="validCode">
					<Input maxlength="4" show-word-limit style="width: 250px;" name="verifyCode" type="text"
						size="large" @on-change="enterClick" v-model="formItem.verifyCode" placeholder="短信验证码" />
					<Button @click.native.prevent="handleMessage" type="info" size="large" class="messageBtn"
						:disabled="checkoutTime" circle>{{ checkoutTime ? `${timeOut}s` : '发送' }}</Button>
				</FormItem>
			</Form>
		</div>
		<div slot="footer" class="changeFooter">
			<Button :loading="loading" class="btn" type="info" @click="sure">确定</Button>
		</div>
	</Modal>
</template>

<script>
	import {
		getPhoneCode,
	} from "@/api/user/user";
	import {
		mapActions,
	} from "vuex";
	export default {
		name: 'AbnormalAccount',
		props: {
			showAbnormalAccount: {
				type: Boolean,
				default: false
			},
			phone: {
				type: String,
				default: ''
			},
			password: {
				type: String,
				default: ''
			}
		},
		computed: {
			showModal: {
				get() {
					return this.showAbnormalAccount
				},
				set(val) {
					this.$parent.showAbnormalAccount = val
				}

			}
		},
		data() {
			return {
				formItem: {
					verifyCode: '',
				},
				checkoutTime: false,
				timeOut: 60,
				timer: "",
				loading:false,
			}
		},
		methods: {
			...mapActions({
				loginHandle: "loginByUsername",
			}),
			enterClick(e){
				if(this.formItem.verifyCode.length == 4){
					this.sure()
				}
			},
			handleMessage() {
				this.checkoutTime = true;
				let params = this.phone;
				let f = (res) => {
					this.$msg.error({
						type: "remind",
						code: 4029,
					});
					this.timer = setInterval(() => {
						if (this.timeOut == 0) {
							clearInterval(this.timer);
							this.checkoutTime = false;
							this.timeOut = 60;
							return;
						}
						this.timeOut--;
					}, 1000);
				};
				let c = (e) => {
					this.$msg.error({
						text: e.data,
					});
					this.timer = setInterval(() => {
						if (this.timeOut == 0) {
							clearInterval(this.timer);
							this.checkoutTime = false;
							this.timeOut = 60;
							return;
						}
						this.timeOut--;
					}, 1000);
				};
				getPhoneCode({
						phone: params,
					})
					.then((res) => f(res))
					.catch((e) => c(e));
			},
			sure() {
				this.loading = true
				let params = {
					phone: this.phone.trim(),
					password: this.password.trim(),
					ifVerify: true,
					ifDataPlatform: 1,
					verifyCode: this.formItem.verifyCode.trim(),
				}
				//把成功登录后的结果返回
				this.loginHandle(params).then(res => {
					this.$emit('loginSuccess', res)
				}).catch(err => {
					this.$msg.error({
						text: err.data,
					})
				}).finally(()=>{
					this.loading = false
				})
			}
		},
	}
</script>

<style scoped lang="scss">
	/deep/.ivu-modal-header {
		border-bottom: none
	}

	/deep/.ivu-modal-footer {
		border-top: none
	}

	.changeHeader {
		height: 80px;
		text-align: center;
		line-height: 80px;
		font-size: 16px;
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 500;
		color: #333333;
	}

	.changeFooter {
		padding-bottom: 50px;
		text-align: center;

		.btn {
			width: 146px;
			height: 46px;
		}
	}

	.messageBtn {
		margin-left: 20px;
	}
</style>
