<template>
	<div v-if="showAnnounce" class="announcement">
		<div :class="['title',openAnnounce?'':'close']" @click="change">
			<div class="text">
				<div :class="['icon',openAnnounce?'':'close']"></div>
				<div :class="['info',openAnnounce?'':'close']">
					{{title}}
				</div>
				<div :class="['switch',openAnnounce?'open':'close']"></div>
			</div>
		</div>
		<div v-if="openAnnounce" class="contentInfo">
			<div class="header">
				尊敬的用户：
			</div>
			<div class="detail">
				<div class="show" ref="showDetail" :style="styleObj">{{formText}}</div>
			</div>
			<div class="gs">
				中思拓研究院
			</div>
			<div class="time">
				{{time}}
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getOneByPlatform
	} from "../api/home.js";
	import util from '../utils/utils2.js'
	export default {
		name: 'Announcement',
		data() {
			return {
				title: '',
				formText: '',
				isClose: false,
				time: '',
				timer: null,
				styleObj: {
					bottom: '0px',
					whiteSpace: 'pre-wrap',
					wordBreak: 'break-all',
					wordWrap: ' break-word'
				},
				num: 0,
				showAnnounce: false
			}
		},
		computed: {
			openAnnounce: {
				get() {
					return this.$store.state.openAnnounce
				},
				set(newVal) {
					this.$store.state.openAnnounce = newVal
				}

			}
		},
		mounted() {
			let _this = this
			this.getAnnouncement()

		},
		methods: {
			async getAnnouncement() {
				let data = await getOneByPlatform({
					platform: 1
				})
				if (data.hasOwnProperty('formText')) {
					this.showAnnounce = true
					this.title = data.title
					this.formText = data.formText
					this.time = util.formatDate(data.gmtUpdated)
					this.$nextTick(() => {
						this.rolling()
					})
				} else {
					this.showAnnounce = false
				}
				setTimeout(() => {
					this.openAnnounce = false
				}, 30000)

			},
			rolling() {
				let el = this.$refs.showDetail
				if (el) {
					let height = el.offsetHeight - 170
					let _this = this
					if (height > 0) {
						_this.timer = setInterval(() => {
							_this.num += 1
							_this.styleObj.bottom = `${this.num}px`
							if (_this.num > height + 20) {
								_this.num = 0
							}
						}, 80);
					}

				}

			},
			change() {
				this.openAnnounce = !this.openAnnounce
			}

		},
	}
</script>

<style lang="scss" scoped>
	.announcement {
		width: 350px;
		position: fixed;
		bottom: 10px;
		right: 10px;
		border-radius: 10px;
		overflow: hidden;
		background-color: #F8F8F8;
		transition: all 1s;
		z-index: 1000;

		.title {
			text-align: center;
			color: white;
			background-color: #216df4;
			height: 40px;

			font-size: 0;
			position: relative;
			cursor: pointer;
			&.close{
				background-color: white;
			}



			.text {
				position: relative;

				.icon {
					width: 40px;
					height: 40px;
					background-position: center center;
					background-size: 60% 60%;
					background-image: url(../assets/image/announce.png);
					background-repeat: no-repeat;
					position: absolute;
					left: 0;
					top: 0;
					&.close{
						background-color: #216df4;
					}
				}

				.switch {
					width: 40px;
					height: 40px;
					background-position: center center;
					background-size: 80% 80%;
					background-image: url(../assets/image/a_open.png);
					background-repeat: no-repeat;
					position: absolute;
					right: 0;
					top: 0;
					cursor: pointer;

					&.close {
						background-image: url(../assets/image/a_close.png);
						transform: rotate(180deg);
						
					}
				}

				.info {
					overflow: hidden;
					width: 280px;
					margin-left: 35px;
					height: 40px;
					color: white;
					line-height: 40px;
					font-size: 16px;
					height: 40px;
					text-align: center;
					text-overflow: ellipsis;
					white-space: nowrap;

					&.close {
						color: gray;
					}
				}

			}

		}

		.contentInfo {
			height: 260px;
			font-size: 14px;
			padding: 16px;

			.detail {
				margin: 5px 0px;
				height: 170px;
				overflow: hidden;

				.show {
					position: relative;
				}
			}

			.gs {
				text-indent: 2em;
				font-size: 12px;
				color: gray;
			}

			.time {
				text-indent: 2em;
				font-size: 12px;
				color: gray;
			}

		}
	}
</style>
