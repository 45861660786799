import  asyncRouters  from '@/router/asyncRouters';
import { constantRouters } from '@/router/constantRouters';

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param roles
 */
function filterAsyncRouter(asyncRouters, menu) {
    let accessedRouters = asyncRouters.filter((route) => {
        const result = hasPermission(menu, route);
        if (result) {
            if (route.children && route.children.length > 1) {
                route.children = filterAsyncRouter(route.children, menu);
            }

            return true;
        }

        return false;
    });

    return accessedRouters;
}

/**
 * 判断是否与当前用户权限匹配
 * @param menu
 * @param route
 */
function hasPermission(menu, route) {
    const routeArr = route.meta.authId;

    if (routeArr === 'common') {
        return true;
    }

    return menu.find((item) => {
        return routeArr.findIndex((authId) => authId == item) >= 0;
    });
}

const permission = {
    namespaced: true,

    state: {
        routers: constantRouters,
        addRouters: [],
    },

    mutations: {
        SET_ROUTERS: (state, routers) => {
            state.addRouters = routers;
            state.routers = constantRouters.concat(routers);
        },
    },

    actions: {
        generateRoutes({ commit }, data) {
            return new Promise((resolve) => {
                let accessedRouters = filterAsyncRouter(asyncRouters, data);

                // 判断是否拥有浏览首页的权限，没有则插入空白的首页

                commit('SET_ROUTERS', accessedRouters);
                resolve();
            });
        },
    },
};

export default permission;
