const research = {
    path: '/Research',
    name: '深度研究',
    component: () => import('@/views/research/Research.vue'),
    redirect: '/Research/ResearchReport',
    meta: {
        authId: [77],
		function:'深度研究'
    },
    children: [
        {
            path: '/Research/ResearchReport',
            name: '研究报告',
            meta: {
                pageId: 79,
                authId: [79],
				function:'深度研究'
            },
            component: () => import('@/views/research/ResearchReport.vue'),
        },
        // 先注释这两个路由，到时候出来了记得加上authId
        // {
        //     path: '/Research/ResearchTopicReport',
        //     name: '专题报告',
        //     component: () => import('@/views/research/ResearchTopicReport.vue'),
        // },
        // {
        //     path: '/Research/ResearchReportPro',
        //     name: '深度报告',
        //     component: () => import('@/views/research/ResearchReportPro.vue'),
        // },
        {
            path: '/Research/ResearchReportStar',
            name: '我的关注',
            meta: {
                pageId: 82,
                authId: [82],
				function:'深度研究'
            },
            component: () => import('@/views/research/ResearchReportStar.vue'),
        },
        {
            path: '/Research/ResearchReportDetail',
            name: '浏览',
            meta: {
                pageId:77,
                authId: [77, 79, 82],
				function:'深度研究'
            },
            component: () => import('@/views/research/ResearchReportDetail.vue'),
        },
        {
            path: '/WarnResearch/WarnReportDetail',
            name: '浏览1',
            meta: {
                pageId:77,
                authId: [77, 79, 82],
				function:'深度研究1'
            },
            component: () => import('@/views/research/ResearchReportDetail.vue'),
        },
            {
        path: '/WarnResearch',
        name: 'WarnResearch',
     component: () => import('@/views/research/WarnResearch.vue'),
        meta: {
          pageId:131,
                authId: [131],
            title: '预警报告',
			function:'预警报告'
        },
    },
    ]
};
  export default research;