const customerOrder = {
	path: '/customerorder',
	name: '定制化(新)',
	component: () => import('@/views/customOrder'),
	redirect:'customerorder/customerorder',
	meta: {
		authId: [83],
		function:'定制化'
	},
	children: [{
			path: '/customerorder/customerorder',
			name: '定制化',
			component: () => import('@/views/customOrder/customerOrder.vue'),
			meta: {
				pageId: 91,
				authId: [83],
				function:'定制化'
			},
		},
		{
			path: '/customerorder/interestspackage',
			name: '我的权益',
			component: () => import('@/views/customOrder/interestspackage.vue'),
			meta: {
				authId: [83],
				function:'定制化'
			},
		},
		{
			path: '/customerorder/customerorderassessment',
			name: '需求评估',
			component: () => import('@/views/customOrder/customerOrderAssessment.vue'),
			meta: {
				authId: [83],
				function:'定制化'
			},
		},
		{
			path: '/customerorder/customerorderassessmentread',
			name: '查看评估',
			component: () => import('@/views/customOrder/customerOrderAssessmentRead.vue'),
			meta: {
				authId: [83],
				function:'定制化'
			},
		},
		{
			path: '/customerorder/customerorderevaluation',
			name: '用户评价',
			component: () => import('@/views/customOrder/customerOrderEvaluation.vue'),
			meta: {
				authId: [83],
				function:'定制化'
			},
		},
		{
			path: '/customerorder/customerorderread',
			name: '查看需求',
			component: () => import('@/views/customOrder/customerOrderRead.vue'),
			meta: {
				authId: [83],
				function:'定制化'
			},
		},
		{
			path: '/customerorder/customerordersupplement',
			name: '补充需求',
			component: () => import('@/views/customOrder/customerOrderSupplement.vue'),
			meta: {
				authId: [83],
				function:'定制化'
			},
		},
		// {
		//     path: '/Customization/CustomizationPrint',
		//     name: '查看打印需求',
		//     component: () => import('@/views/customization/CustomizationPrint.vue'),
		//     meta: {
		//         from: '/Customization/CustomizationServe',
		//         authId: [83],
		//     },
		// },
		// {
		//     path: '/Customization/CustomizationSummit',
		//     name: '提交需求',
		//     component: () => import('@/views/customization/CustomizationSummit.vue'),
		//     meta: {
		//         from: '/Customization/CustomizationServe',
		//         authId: [83],
		//     },
		// },
		// {
		//     path: '/Customization/CustomizationEvaluate',
		//     name: '需求评估确认',
		//     component: () => import('@/views/customization/CustomizationEvaluate.vue'),
		//     meta: {
		//         from: '/Customization/CustomizationServe',
		//         authId: [83],
		//     },
		// },
		// {
		//     path: '/Customization/CustomizationFeedback',
		//     name: '反馈意见',
		//     component: () => import('@/views/customization/CustomizationFeedback.vue'),
		//     meta: {
		//         from: '/Customization/CustomizationServe',
		//         authId: [83],
		//     },
		// },
	],
};
export default customerOrder;