const publicSentiment = {
    path: '/Insight/publicSentiment',
    name: '舆情监测',
    component: () => import('@/views/publicSentiment/Hotspot.vue'),
    meta: {
        authId: [64],
		function:'舆情监测',
        iconOrImg: 'icon',
        icon: '',
        img: ''
    },
    children: [
        {
            path: '/Insight/publicSentiment/hotspot',
            name: '舆情热点',
            meta: {
                pageId: 79,
                authId: [64,79],
				function:'舆情监测',
                iconOrImg: 'icon',
                icon: '',
                img: ''
            },
            component: () => import('@/views/publicSentiment/Hotspot.vue')
        }
    ]
}
export default publicSentiment;