const reHouseEnterprise ={
	path: '/Insight/enterprise/enterpriseHome/reHouseEnterprise',
	name: '房企',
	component: () => import('@/views/reHouseEnterprise/index.vue'),
	meta: {
		authId: [41],
		function: '企业监测',
		iconOrImg: 'icon',
		icon: '',
		img:''
	},
	children: [
		{
			path: '/Insight/enterprise/enterpriseHome/reHouseEnterprise/information',
			name: '企业信息',
			component: () => import('@/views/reHouseEnterprise/information/index.vue'),
			meta: {
				pageId: 43,
				authId: [43],
				function: '企业监测',
				iconOrImg: 'icon',
				icon: '',
				img:''
			},
		},
		{
			path: '/Insight/enterprise/enterpriseHome/reHouseEnterprise/finance',
			name: '财务',
			component: () => import('@/views/reHouseEnterprise/finance/index.vue'),
			meta: {
				pageId: 46,
				authId: [46],
				function: '企业监测',
				iconOrImg: 'icon',
				icon: '',
				img:''
			},
		},
		{
			path: '/Insight/enterprise/enterpriseHome/reHouseEnterprise/financing/index.vue',
			name: '融资',
			meta: {
				pageId: 47,
				authId: [47],
				function: '企业监测',
				iconOrImg: 'icon',
				icon: '',
				img:''
			},
			component: () => import('@/views/reHouseEnterprise/financing'),
		},
		{
			path: '/Insight/enterprise/enterpriseHome/reHouseEnterprise/sale',
			name: '销售',
			component: () => import('@/views/reHouseEnterprise/saleHouse/index.vue'),
			meta: {
				pageId:44,
				authId: [44],
				function: '企业监测',
				iconOrImg: 'icon',
				icon: '',
				img:''
			},
		},
		{
			path: '/Insight/enterprise/enterpriseHome/reHouseEnterprise/landReserve',
			name: '土地储备',
			component: () => import('@/views/reHouseEnterprise/LandReserve/index.vue'),
			meta: {
				pageId:45,
				authId: [45],
				function: '企业监测',
				iconOrImg: 'icon',
				icon: '',
				img:''
			},
		},
		{
			path: '/Insight/enterprise/enterpriseHome/reHouseEnterprise/MergerAndAcquisition',
			name: '兼并收购',
			component: () => import('@/views/reHouseEnterprise/mergerAndAcquisition/index.vue'),
			meta: {
				pageId:49,
				authId: [49],
				function: '企业监测',
				iconOrImg: 'icon',
				icon: '',
				hidden: false,
				img:''
			},
		},
		{
			path: '/Insight/enterprise/enterpriseHome/reHouseEnterprise/mergerAcquisitionDetails',
			name: '兼并收购详情',
			meta: {
				authId: [49],
				function: '企业监测',
				iconOrImg: 'icon',
				icon: '',
				img:'',
				hidden:true
			},
			component: () => import('@/views/reHouseEnterprise/mergerAndAcquisition/detail'),

		}, {
			path: '/Insight/enterprise/enterpriseHome/reHouseEnterprise/notice',
			name: '企业公告',
			meta: {
				pageId:50,
				authId: [50],
				function: '企业监测',
				iconOrImg: 'icon',
				icon: '',
				hidden:false,
				img:''
			},
			component: () => import('@/views/reHouseEnterprise/notice/index.vue'),
		},
	]

}
export default reHouseEnterprise;
