const modal = {
	namespaced: true,
	state: {
		showModalManage: false,
		showCityManage: false,
		showPushManage: false,
		showDiaryManage: false,
		diaryId: 0,
		showDevManage: false,
		showProjectModal: false,
		showMapModal: false,
		showAddProjectModal: false,
		showTimeModal: false,
		showAdviceModal: false,
		adviceData: null,
		addAdviceData: null,
		showNewMapModal: false,
		diaryId2: 0,
		showDiaryManage2: false,
		showReAdviceModal: false,
	},
	mutations: {
		changeState(state, {
			prop,
			value
		}) {
			state[prop] = value;
		},
	}
}
export default modal;
