<!--
 * 方法说明
 * author: 时雨
 * description: 申请转平台用户弹框
 * @param changeAccountType 是否展示弹框
 * @createTime: 2/17/2022, 8:46:30 AM
  -->
<template>
	<Modal v-model="showModal" :closable='true' :mask-closable='false' :width='500' transfer :lock-scroll='true'>
		<div slot="header" class="changeHeader">
			非数据平台账号，您可以直接申请成为机构用户
		</div>
		<div class="changeInfo">
			<Form ref="Form" style="padding-right: 40px;" :model="formItem" :label-width="100" :rules="rules">
				<FormItem style='width: 100%;' label="单位" prop="customerName">
					<Input maxlength="30" show-word-limit v-model="formItem.customerName" placeholder="单位"></Input>
				</FormItem>
				<FormItem style='width: 100%;' label="姓名" prop="nickName">
					<Input maxlength="10" show-word-limit v-model="formItem.nickName" placeholder="姓名"></Input>
				</FormItem>
				<FormItem label="性别" prop="sex">
					<RadioGroup v-model="formItem.sex">
						<Radio label="男">男</Radio>
						<Radio label="女">女</Radio>
					</RadioGroup>
				</FormItem>
				<FormItem style='width: 100%;' label="职务/部门" prop="department">
					<Input maxlength="30" show-word-limit v-model="formItem.department" placeholder="职务/部门"></Input>
				</FormItem>
				<FormItem style='width: 100%;' label="备注" prop="label">
					<Input maxlength="100" show-word-limit v-model="formItem.label" placeholder="备注"></Input>
				</FormItem>
			</Form>
		</div>
		<div slot="footer" class="changeFooter">
			<Button class="btn" type="info" @click="sure">确定</Button>
			<Button class="btn" style="margin-left: 20px;" @click="showModal =false">取消</Button>
		</div>
	</Modal>
</template>

<script>
	import {
		apply_customer
	} from '../../api/user/user.js'
	export default {
		data() {
			return {
				formItem: {
					customerName: '',
					nickName: '',
					sex: '男',
					department: '',
					label: '',
				},
				rules: {
					customerName: [{
						required: true,
						trigger: "blur",
						message: "请输入单位",
					}, ],
					nickName: [{
						required: true,
						trigger: "blur",
						message: "请输入姓名",
					}, ],
					sex: [{
						required: true,
						trigger: "blur",
						message: "请输入性别",
					}, ],
					department: [{
						required: true,
						trigger: "blur",
						message: "请输入职务/部门",
					}, ],
				}
			}
		},
		props: {
			changeAccountType: {
				type: Boolean,
				default: false
			},
			phone: {
				type: String,
				default: ''
			},
		},
		computed: {
			showModal: {
				get() {
					return this.changeAccountType
				},
				set(val) {
					this.$parent.changeAccountType = val
				}

			}
		},
		methods: {
			async sure() {
				this.$refs["Form"].validate((valid) => {
					if (valid) {
						let params = {
							phone:this.phone,
							customerName: this.formItem.customerName,
							nickName: this.formItem.nickName,
							title: '申请机构用户',
						}
						let str =
							`性别：${this.formItem.sex}\r\n职位/部门：${this.formItem.department}\r\n备注：${this.formItem.label}\r\n`
						params.infoDetail = str
						apply_customer(params).then(res => {
							if (res.flag) {
								this.$msg.success({
									text: "提交成功",
								})
							}
						}).catch(err => {
							this.$msg.error({
								text: '注册失败',
							})
						}).finally(()=>{
							this.showModal = false
						})
					} else {
						this.$msg.error({
							text: ['请输入必填项信息（*为必填）'],
						})
					}
				})
			}
		},
	}
</script>

<style scoped lang="scss">
	/deep/.ivu-modal-header {
		border-bottom: none
	}

	/deep/.ivu-modal-footer {
		border-top: none
	}
	.changeHeader{
		height: 80px;
		text-align: center;
		line-height: 80px;
		font-size: 16px;
		font-family: Source Han Sans CN, Source Han Sans CN-Medium;
		font-weight: 500;
		color: #333333;
	}
	.changeFooter{
		padding-bottom: 50px;
		text-align: center;
		.btn{
			width: 146px;
			height: 46px;
		}
	}
</style>
