import * as api from '@/api/city'
const city = {
    state: {
        option : {
            cx : [],
            age: [],
            apartment: [],
            allCity : [],
            cityArea: [],
            // cityDistrict: [],
            cityPlate: [],
            detailType: [],
            floor: [],
            cityDistrictOrChildren:[],
            jzlx: [],
            leasingMode: [],
            propertyType: [],
            renvation: [],
            saleStatus: [],
            statAcreage: [],
            statPrice: [],
            statPropertyType: [],
            statTime: [],
            statTimeNoDay: [],
            zj:[],
            rent:[]
        },
        orderList : [],
    },
    actions: {
        initCityData({ state }) {
            for (let key in state.option) {
                api[key]().then(data => {
                    if(key == 'statTimeNoDay') {
                        state.option[key] = data.filter(item => {
                            return item.id != 2;
                        });
                    } else {
                        state.option[key] = data;
                    }
                })
            }
        }
    }
}
export default city;
