import reLand from "@/router/dataInsight/land/reLand";
import enterprise from "@/router/dataInsight/enterprise/enterprise";
import publicSentiment from "@/router/dataInsight/sentiment/publicSentiment";
import macroEconomy from "@/router/dataInsight/marcro/macroEconomy";
import financialMarket from "@/router/dataInsight/financial/financialMarket";
import city from "@/router/dataInsight/city/city";

export const dataInsight = {
    path: '/Insight',
    children: [
        // 宏观经济
        macroEconomy,
        //土地重构
        reLand,
        // 金融市场
        financialMarket,
        // 城市系统
        city,
        //房企
        enterprise,
        // 舆情
        publicSentiment,
    ],
    name: '数据洞察',
    component: () => import('@/views/NewInsight.vue'),
    meta: {
        id: 3,
        title: '中思拓-数据洞察',
        authId: [2],
        function: '数据洞察',
    }
}
export default dataInsight;