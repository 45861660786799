<template>
	<Modal class="advice-modal" footer-hide :mask-closable='false' :width="780" v-model="showReAdviceModal"
		class-name="vertical-center-modal">
		<div class="modal-title">用户建议</div>
		<div class="option-box">
			<div class="editForm">
				<Form class="adviceForm" :model="adviceForm" :rules="adviceFormRules" ref="adviceForm" label-colon
					:label-width="60">
					<FormItem label="事项" prop="title">
						<Input v-model="adviceForm.title" maxlength="30" show-word-limit style="width: 500px;" />
					</FormItem>
					<FormItem label="类型" prop="adviseTypeName">
						<RadioGroup v-model="adviceForm.adviseTypeName" style="width: 500px;"
							@on-change='getContentType'>
							<Radio v-for="(item,index) in optionList" :key='index' :label="item.className">
								{{item.className}}
							</Radio>
						</RadioGroup>
					</FormItem>
					<FormItem label="内容" prop="contentName">
						<CheckboxGroup v-model="adviceForm.contentName" style="width: 500px;">
							<Checkbox style='width: 47%;' v-for="(item,index) in contentList" :key='index'
								:label="item.tagName">
								{{item.tagName}}
							</Checkbox>
						</CheckboxGroup>
					</FormItem>
					<FormItem label="补充" prop="infoDetail">
						<Input  type="textarea" :rows="10" maxlength="1000" show-word-limit
							v-model="adviceForm.infoDetail" style="width: 500px;" />
					</FormItem>
				</Form>
			</div>
			<div class="btn-box">
				<Button :loading='loading' class="btn" type="info" @click="submit()">确定</Button>
				<Button style="margin-left: 20px;" class="btn" type="default"
					@click="cancle()">取消</Button>
			</div>
		</div>
	</Modal>
</template>

<script>
	import {
		info_options,
		add_info
	} from '@/api/public'
	export default {
		name: 'reAdviceModal',
		computed: {
			showReAdviceModal: {
				get() {
					return this.$store.state.modal.showReAdviceModal;
				},
				set(newValue) {
					this.$store.state.modal.showReAdviceModal = newValue;
				}
			},
			reAdviceInfo: {
				get() {
					return this.$store.state.reAdviceInfo;
				},
				set(newValue) {
					this.$store.state.reAdviceInfo = newValue;
				}
			},
		},
		watch: {
			showReAdviceModal(newValue, oldValue) {
				if (newValue) {
					this.getOption()

				} else {
					this.leavePageTime = Date.now()
					this.loading = false
					this.reSet()
					this.reAdviceInfo = {}
					this.pagelog()
				}
			},
			async reAdviceInfo(val) {
				if (val) {
					this.adviceForm.title = val.title
					this.adviceForm.adviseTypeName = val.adviseTypeName
					this.adviceForm.contentName = val.contentName
					this.adviceForm.infoDetail = val.infoDetail

				} else {
					this.adviceForm.title = ''
					this.adviceForm.adviseTypeName = ''
					this.adviceForm.contentName = []
					this.adviceForm.infoDetail = ''
				}
			},
		},
		data() {
			return {
				loading: false,
				adviceForm: {
					title: '',
					adviseTypeName: '',
					contentName: [],
					infoDetail: '',
				},
				adviceFormRules: {
					title: [{
						required: true,
						message: '此项必填',
						trigger: 'blur'
					}, ],
					adviseTypeName: [{
						required: true,
						message: '此项必填',
					}, ],
					contentName: [{
						required: true,
						message: '此项必填',
					}, ],
				},
				optionList: [],
				contentList: [],
				pageEnterTime:'',
				leavePageTime:''
			}
		},
		methods: {
			async getOption() {
				this.pageEnterTime = Date.now()
				let data = await info_options()
				this.optionList = data.infoClass
				this.adviceForm.adviseTypeName = this.adviceForm.adviseTypeName ? this.adviceForm.adviseTypeName : this
					.optionList[0].className
				this.contentList = this.optionList[0].infoTags
			},
			getContentType(className) {
				this.adviceForm.contentName = []
				this.optionList.forEach(item => {
					if (item.className == className) {
						this.contentList = item.infoTags
					}
				})
			},
			submit() {
					this.$refs.adviceForm.validate((valid) => {
						if (valid) {
							this.loading = true
							let params = JSON.parse(JSON.stringify(this.adviceForm))
							let str = params.contentName.join(',')
							params.contentName = str
							params.pageUrl = this.$route.path
							params.userId = this.$store.state.userId
							add_info(params).then(res => {
								this.$msg.success({
									text: "提交成功，感谢您宝贵的意见"
								})
								this.loading = false
								this.cancle()
							}).catch(err => {
								this.$msg.error({
									text: JSON.stringify(err.data)
								})
							})
						} else {
							this.$msg.error({
								text: "请输入必填项"
							})
						}
					})


			},
			cancle() {
				this.showReAdviceModal = false
			},
			reSet() {
				this.adviceForm = {
					title: '',
					adviseTypeName: '',
					contentName: [],
					infoDetail: '',
				}
			},
			pagelog(){
				this.$pagelog(116,'customer',this)
			}
		}
	}
</script>
<style lang="scss" scoped>
	@import "@/style/common.scss";

	/deep/ .vertical-center-modal {
		@include flex;

		.ivu-modal {
			top: 0;
			max-height: 780px;
			overflow: auto;
		}

		.ivu-modal-body {
			padding: 0 20px 30px;
			overflow: hidden;
		}
	}

	.advice-modal {

		.modal-title {
			margin: 20px 0 30px;
			font: 18px bold;
		}

		.option-box {
			padding: 20px 90px;
		}

		.btn-box {
			margin-top: 40px;
			text-align: center;

			.btn {
				width: 140px;
				height: 40px;
			}
		}
	}

	/deep/.ivu-input[disabled],
	fieldset[disabled] .ivu-input {
		color: #333333;
	}

	.contentType {
		font-size: 14px;
		font-weight: 400;
		padding: 4px 10px;
		border-radius: 8px;
		color: #333333;
		background-color: rgba($color: #999999, $alpha: 0.05);
		width: auto;
		text-align: left;
		display: inline-block;
		margin: 3px;
		width: 48%;
	}
</style>
