import { post, get } from '@/utils/http'

// 新闻
export function queryNews(params) {
    return post({ url: '/service-website/news_info/queryNewsByConType', params })
}

// 
// export const getOneByPlatform =(params)=>ajax(wrapper('/sysAnnouncement/getOneByPlatform',webModule),params,'POST')

//系统公告
export function getOneByPlatform(params) {
    return post({ url: '/service-website/sysAnnouncement/getOneByPlatform', params })
}

//横幅公告
export function getBanner(params) {
    return get({ url:'/service-insight/announcement/announcement_config', params })
}