
import Home from '../views/Home.vue';
import Login from '../views/login.vue';
// import ResearchWarn from '../views/research/ResearchWarn.vue';

export const constantRouters = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            id: 1,
            pageId: 1,
            title: '中思拓-首页',
			function:'首页'
        },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: '中思拓-登录',
			function:'登录'
        },
    },


    // 移动端首页
    {
        path: '/m_index',
        name: 'm_index',
        component: () => import('../views/m_index.vue'),
        meta: {
            title: '中思拓-主页',
        },
    },

    // 404页面
    {
        // 会匹配所有路径
        path: '*',
        component: () => import('../views/error.vue'),
        meta: {
            title: '页面还未开放',
			function:'页面暂无开放'
        },
    },
];
