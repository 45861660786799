import { post, get } from '@/utils/http'

export function getDetail (id) {
  return post({ url: '/service-user/data_user_info/detail?id=' + id })
}
export function repetition (params) {
  return get({ url: '/service-user/data_user_info/user_repetition', params })
}
export function update (params) {
  return post({ url: '/service-user/data_user_info/update', params })
}
export function updatepsw (params) {
  return post({ url: '/service-user/data_user_info/update_password', params })
}
export function getverify (params) {
  return get({ url: '/service-user/user_info/get_verify_user', params })
}
